.selectors {
  display: flex;
  flex-direction: column;
}

@media (min-width: $screen-lg-min) {
  .selectors {
    flex-direction: row;
  }
}

.row-selectors {
  display: contents;
}

.selector-title {
  display: inline-block;
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 15px;

  h1, div.mrx {
    display: inline;
  }

  .selector {
    display: inline;
    padding-right: 10px;

    .select2-container {
      width: auto;

      .select2-choice,
      .select2-arrow {
        background: inherit;
        border-color: transparent;
        color: $colour-tertiary;

        > .select2-chosen {
          margin-right: 10px;
        }

        .select2-arrow {
          color: #ccc;
        }
      }

      .select2-result-label {
        white-space: nowrap;
      }
    }

    .select2-dropdown-open {
      background-color: white;
    }

    .select2-container-active,
    .select2-container:hover {
      > .select2-chosen {
        margin-right: 26px;
      }

      .select2-choice {
        border-color: $colour-input-border !important;
      }
    }
  }
}

.select2-drop-active {
  box-shadow: none;
}
