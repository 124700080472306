@import 'node_modules/bootstrap-slider/src/sass/variables';

$slider-border-radius: 2px;

@import 'node_modules/bootstrap-slider/src/sass/mixins';
//@import 'node_modules/bootstrap-slider/src/sass/rules';
@import 'resources/assets/sass/bootstrap-slider-rules';

// AF specifc style overrides start here

.slider {
  &.slider-horizontal {
    .tooltip {
      transform: translateX(-50%);
    }
  }

  .slider-track {
    @include slider_background-image(#fff, #fff, #fff);
    border: 1px solid $colour-border;
  }

  .slider-selection {
    @include slider_background-image(#fff, #fff, #fff);
    @include slider_box-shadow(none);
    border: none;

    &.tick-slider-selection {
      @include slider_background-image(#fff, #fff, #fff);
      border: 1px solid $colour-border;
    }
  }

  .slider-handle {
    cursor: pointer;
  }

  &.slider-disabled {
    .slider-handle {
      cursor: not-allowed;
    }
  }
}
