// Cloak
[v-cloak] {
  display: none;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  @include transition(opacity .5s);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.push-enter-active,
.push-leave-active {
  transition: all .2s;
}

.push-enter,
.push-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

// Multicheckbox
body.app {
  .vue-multicheckbox {
    .multicheckbox-options {
      border: 1px solid $colour-input-border;
    }
  }
}

// Multiselect
body.app {
  .vue-multiselect {
    .multiselect-options {
      border: 1px solid $colour-input-border;
    }

    .multiselect-counter {
      background: $font-primary-colour;
    }
  }
}

// Tag Input
.tags.form-control input[type='text'] {
  @include placeholder;
}

// Search field
body.app {
  .search-field .search-panel {
    border-color: $colour-input-border;
    border-bottom-left-radius: $default-radius;
    border-bottom-right-radius: $default-radius;
  }

  .search-field .search-icon {
    top: 22px;
    right: 22px;
  }
}

// Datepicker
$datetimepicker-active-bg: $btn-primary-bg;
$datetimepicker-active-color: $btn-primary-color;
$datetimepicker-border-radius: $border-radius-base;
$datetimepicker-btn-hover-bg: $gray-lighter;

.vue-datetimepicker-widget {
  td.day {
    border-radius: $border-radius-base;

    &:hover {
      background: $datetimepicker-btn-hover-bg;
    }
  }

  td.selected,
  td.selected:hover {
    color: $datetimepicker-active-color;
    background: $datetimepicker-active-bg;
  }
}

// Vue Draggable Resizable
// https://github.com/mauricius/vue-draggable-resizable/blob/master/src/components/vue-draggable-resizable.css
.vdr {
  touch-action: none;
  position: absolute;
  box-sizing: border-box;
}

.handle {
  box-sizing: border-box;
  position: absolute;
  width: 30px;
  height: 2px;
  background: $colour-tertiary;
  border-radius: $default-radius;
  @include box-shadow(0 0 3px 3px rgba(255, 255, 255, 0.5));
}

.handle-tl {
  width: 2px;
  height: 2px;
  top: -10px;
  left: -10px;
  cursor: nw-resize;
}

.handle-tm {
  top: -10px;
  left: 50%;
  margin-left: -15px;
  cursor: n-resize;
}

.handle-tr {
  width: 2px;
  height: 2px;
  top: -10px;
  right: -10px;
  cursor: ne-resize;
}

.handle-ml {
  width: 2px;
  height: 30px;
  top: 50%;
  margin-top: -15px;
  left: -10px;
  cursor: w-resize;
}

.handle-mr {
  width: 2px;
  height: 30px;
  top: 50%;
  margin-top: -15px;
  right: -10px;
  cursor: e-resize;
}

.handle-bl {
  width: 2px;
  height: 2px;
  bottom: -10px;
  left: -10px;
  cursor: sw-resize;
}

.handle-bm {
  bottom: -10px;
  left: 50%;
  margin-left: -15px;
  cursor: s-resize;
}

.handle-br {
  width: 2px;
  height: 2px;
  bottom: -10px;
  right: -10px;
  cursor: se-resize;
}

@media only screen and (max-width: 768px) {
  [class*="handle-"]:before {
    content: '';
    left: -10px;
    right: -10px;
    bottom: -10px;
    top: -10px;
    position: absolute;
  }
}
