.info-box {
  background-color: white;
  position: relative;

  %no-selection {
    @include user-select(none);
    cursor: pointer;
  }

  .close-buttons {
    @extend %no-selection;
    @include position(absolute, 23px 18px 0 0);
  }

  .heading {
    @extend %no-selection;
    background: url($img + 'info-box-icon.png') 15px center no-repeat;
    color: $colour-tertiary;
    font-size: 24px;
    margin: 0;
    padding: 15px 60px 15px 50px;
  }

  .content {
    padding: 0 20px 20px;

    h4 {
      margin-bottom: 3px;

      + p {
        margin-top: 3px;
      }
    }
  }

  h3.title {
    margin: 0;
    line-height: 1;

    button.open-info-box:hover {
      color: $colour-info-box-heading;
    }

    &:focus {
      outline: none;
    }

    &:has(:focus-visible) {
      color: $colour-link-active;
      outline: 2px solid $colour-link-active;
      outline-offset: -2px;
    }

    .af-icons-md-alert-info {
      padding-right: 5px;
    }
  }

  .body {
    a {
      color: $colour-link;
      text-decoration: underline;

      &:hover {
        color: #fff;
        background-color: $colour-link-hover;
        text-decoration: none;
        outline: 2px solid $colour-link-hover;
      }

      &:focus,
      &:focus-visible,
      &:active {
        color: $colour-link-active;
        outline: 2px solid $colour-link-active;
        outline-offset: 2px;
        text-decoration: underline;
      }
    }

    a.pdf-link {
      text-decoration: none;
    }
  }
}
