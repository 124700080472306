.assignment-form {
  .assign {
    margin: 20px 0;
    white-space: nowrap;

    span {
      vertical-align: middle;
    }

    .af-icons {
      font-size: $af-icons-base-size;
    }
  }
}

@media (min-width: $screen-md-min) {
  .assignment-form {
    .assign {
      margin: 80px 0 0 0;

      span {
        display: none;
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  .assignment-form {
    .assign {
      span {
        display: inline-block;
      }
    }
  }
}

.random-assignments {
  @mixin inlineInput {
    display: inline-block;
    width: auto;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .assign-label {
    font-weight: bold;
  }

  .assign-number {
    max-width: 70px;
    @include inlineInput;
  }

  .assign-context {
    @include inlineInput;
  }

  .assign-inclusion {
    @include inlineInput;
  }

  .random-assignments-summary {
    margin-top: $l;
    margin-bottom: $m;
  }
}
