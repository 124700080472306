@import '../theme-colors.goodgrants.scss';
/*
|--------------------------------------------------------------------------
| Configuration
|--------------------------------------------------------------------------
|
| Store all global variables and font declarations here.
| No styles should be defined here.
|
*/

// Colours
$colour-primary: #5f29c7;
$colour-primary-hover: #4b209d;
$colour-secondary: #008945;
$colour-secondary-hover: #00562b;
$colour-tertiary: #253342;
$colour-tertiary-hover: #131a21;
$colour-quaternary: #1d1d1d;
$colour-gray-sublight: lighten(#000, 65%) !default;
$colour-home-background: $theme-var-home-background;

// Links
$colour-link: #3b4be3;
$colour-link-hover: #253342;
$colour-link-active: #253342;

// Focus box
$colour-focus-box-text: #000;
$colour-focus-box-background: #9ee493;

// Forms
$colour-form-box-text: #333;
$colour-form-box-background: $theme-var-form-box-background;
$colour-breadcrumbs: #edeff2;
$colour-filters: #f9f9f9;
$colour-form: #f4f4f4;
$colour-border: #ddd;

// Header
$colour-header-background: $colour-tertiary;
$colour-header-link: #8e96a2;
$colour-header-link-hover: #fff;

// Alerts
$colour-warning: #ffbe0b;
$colour-warning-text: #000;
$colour-info: #4967EE;
$colour-info-text: #000;
$colour-success: #06d6a0;
$colour-success-text: #000;
$colour-error: #e11b46;
$colour-error-text: #fff;
$colour-neutral: #fff;
$colour-neutral-text: #000;
$colour-info-box-heading: #3b4be3;
$colour-info-box-icon: #3b4be3;
$colour-alert-box-text: #000000;


$colour-warning-dark: #8F6900;
$colour-error-hover: lighten($colour-error, 20);

// Status
$colour-disabled: #767676;
$colour-disabled-dark: #545454;
$colour-disabled-input: #f2f2f2;
$colour-danger: #bb0000;

// Tabs
$colour-tab: #66788f;
$colour-tab-text: #ffffff;
$colour-tab-hover: #5f29c7;
$colour-tab-active: #253342;

// Input
$colour-input-border: #6f6f6f;
$colour-input-border-focus: #000;
$colour-input-border-error: lighten($colour-error, 20%);
$colour-input-border-error-focus: darken($colour-input-border-error, 35%);

// Scoring
$colour-approved: #008441;
$colour-rejected: #bb0000;
$colour-unsure: #ff7500;

// PDF
$colour-pdf: #db4437;

// Navigation
$navigation-background: #e6ebef;
$navigation-text: #000;
$navigation-text-hover: #fff;
$navigation-hover: #253342;
$navigation-text-active: #000;
$navigation-active: #adbbc9;
$secondary-submenu-navigation-text: #000;

// Validation
$validation-error-label-text: $colour-error;
$validation-error-background: #ffffff;
$validation-error-border: $colour-error;

// Brand header/footer
$brand-header-background: #fff;
$brand-footer-background: #fff;

// Fonts
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
$font-primary: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-primary-colour: #000000;
$font-secondary-colour: #989898;

// Directories
$img: '../img/';

// Misc settings
$default-radius: 4px;
$transition-speed: 0.25s;
$box-shadow-focus: 0 0 8px rgba(0, 0, 0, 0.7);

// Modals
$modal-width: 500px;

// Pill buttons
$button-pill-height: 24px;
$button-pill-close: 20px;
$button-pill-close-spacing: 3px;

// When viewed on mobile/tablet, this variable determines how much padding is
// added to the left and right of the element.
$handheld-device-padding: 20px;

// Texts
$colour-primary-hover-text: $navigation-text-hover
