#header {
  min-height: 150px;
  position: relative;
  overflow: hidden;
  z-index: 3;

  .logo {
    display: block;
    margin: 0;
    width: 100%;
    text-indent: -10000em;

    a {
      line-height: 0; /* Used to hide logo anchor text */
      font-size: 0; /* Used to hide logo anchor text */
      color: transparent; /* Used to hide logo anchor text */
    }
  }
}

.resizable-header {
  // Style the bottom middle handle
  .handle-bm {
    cursor: row-resize;
  }
}

.header-controls {
  z-index: 10;
  margin: 0 -30px;
  overflow: hidden;
  padding: 0 30px;
  border-bottom: 2px solid #6f6f6f;
  background-color: $body-bg;
  // make sure box-shadow fades out when js-is-sticky is removed
  box-shadow: none;
  transition: box-shadow 1s;

  > h1:first-child {
    margin-top: 0;
  }

  &.js-is-sticky, &.js-is-stuck {
    @include box-shadow(0 3px 10px 2px RGB(0 0 0 / 25%));
  }
}

.header-controls-fix {
  background-color: white;
}

.header-controls-row-fix {
  z-index: 11;
  position: relative;
  margin: 0 -30px; // Double horizontal negative margins, add -10px to the top
  padding: 10px 15px; // Remove new additional space
  background-color: white;
}

@media (max-width: $screen-sm) {
  .header-controls-row-fix {
    margin: 0 -30px -10px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .js-is-sticky {
    max-width: inherit;
    width: 100%;
  }
}

.control-panel {

  /* @include position( absolute , 3px 20px 0 0 ); */
  border-radius: $default-radius;
  padding: 6px;

  li {
    line-height: 1.5em;
    overflow: visible;
    padding-top: 5px;

    &:first-child {
      color: darken(#fff, 20%);
    }
  }

  li + li {
    background: url($img + 'menu-separator.png') no-repeat;
    margin-left: 10px;
    padding-left: 10px;
  }

  a {
    @include transition( all $transition-speed );
    color: $colour-link;
    display: inline;
    text-decoration: none;

    &:hover {
      color: $colour-link-hover;
    }
  }
}

// Styles for header that are for the main app
.app {
  #header {
    background: $brand-header-background;

    .logo {
      margin-left: 30px;
      background: $brand-header-background no-repeat left;
      height: 170px;

      a {
        line-height: 0; /* Used to hide logo anchor text */
        font-size: 0; /* Used to hide logo anchor text */
        color: transparent; /* Used to hide logo anchor text */
      }
    }
  }
}

// Splash-page specific styles
.splash {
  #header {
    .logo {
      background: transparent no-repeat center;

      a {
        line-height: 0; /* Used to hide logo anchor text */
        font-size: 0; /* Used to hide logo anchor text */
        color: transparent; /* Used to hide logo anchor text */
      }
    }
  }
}
