#allocations-list {
  .pagination-info {
    top: 30px;
  }

  table {
    .amount {
      white-space: nowrap;
    }
  }
}
