.roles {
  .matrix-role {
    width: 100%;
    margin: auto;
    overflow: auto;
    height: 80vh;

    table {
      margin-bottom: 0;
      thead, tbody, tr, th, td {
        border: 0 !important;
      }
    }

    .matrix-table {
      position: relative;

      th.mode-label {
        font-size: 10px;
        text-align: center;
        min-width: 56px;
        position: relative;

        &.inherit {
          //background-color: #f2f2f2;
        }

        &.allow:not(:last-child)::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: $table-border-color;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: $table-border-color;
          z-index: 5;
        }
      }

      td.permission-action {
        min-width: 56px;
        text-align: center;

        input {
          margin: 0 auto;
        }

        &.permission-action {
          & div.radio.styled label {
            padding-left: 0;
          }

          &.deny {
            & div.radio.styled input:not([disabled]) + label::after {
              background-color: $colour-rejected;
            }
          }

          &.inherit {
            background-color: #f2f2f2;
            & div.radio.styled input:not([disabled]) + label::after {
              background-color: $colour-neutral-text;
            }
          }

          &.allow {
            & div.radio.styled input:not([disabled]) + label::after {
              background-color: $colour-approved;
            }

            position: relative;

            &:not(:last-child)::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 1px;
              height: 100%;
              background-color: $table-border-color;
              z-index: 1;
            }
          }
        }
      }

      th.action-label {
        position: relative;
        font-weight: bold;
        text-align: center;
        min-width: 56px;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $table-border-color;
          z-index: 4;
        }

        &:not(:last-child)::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: $table-border-color;
          z-index: 4;
        }
      }

      thead {
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 4;
        height: 100px !important;

        &.sticky-thead tr th.sticky-content {
          z-index: 5;
          background-color: #fff;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background-color: $table-border-color;
            z-index: 5;
          }
        }

        .btn-form-mode {

        }

        .btn-form-mode:focus:not(:hover) {
          color: $link-color;
        }
        .btn-form-mode:hover {
          color: $link-hover-color;
          text-decoration: underline;
        }

        .btn-form-mode:focus,
        .btn-form-mode:focus-within,
        .btn-form-mode:focus-visible {
          color: $link-hover-color;
          text-decoration: underline;
        }

      }

      tr td:first-child,
      tr th:first-child {
        position: sticky;
        left: 0;
        z-index: 3;

        @media (max-width: $screen-sm) {
          position: relative;
        }
      }

      .role-matrix-row {
        position: relative;

        & td::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: $table-border-color;
          z-index: 2;
        }

        .role-matrix-label {
          vertical-align: top !important;
          background-color: #fff;

          .description {
            font-size: 12px;
          }

          .role-matrix-name {
            font-weight: bold;
            position: relative;
          }
        }
      }

      &.simple-form {
        position: relative;
        height: 65.2px;

        & div.role-matrix-label {
          display: flex;
          align-items: center;
        }

        .description-wrapper {
          flex: 1;
          height: 44.2px;
        }

        .af-icons-chevron-down {
          transform: rotate(-90deg);
          font-size: 13px;
          top: -1px;
          position: relative;
        }
      }

      &.advanced-form {
        .role-matrix-label {
          width: 300px;
        }

        .af-icons-chevron-down {
          transform: rotate(90deg);
          font-size: 13px;
          top: -1px;
          position: relative;
        }
      }
    }
  }

  .registration-only {
    padding-left: 26px;
  }

  .role-matrix-wrapper {
    position: relative;
    overflow: hidden;
  }

  .shadow-bottom {
    @include box-shadow(0 0px 10px 0px RGB(0 0 0 / 0.45));
  }

  .shadow-bottom-placeholder {
    position: absolute;
    bottom: -19px;
    width: 100%;
    z-index: 12;
    transition: box-shadow 1s;
  }

  .shadow-right {
    @include box-shadow(0 0 10px 2px RGB(0 0 0 / .45));
  }

  .shadow-right-placeholder {
    position: absolute;
    right: -4px;
    top: 100px;
    bottom: 0;
    z-index: 12;
    transition: box-shadow 1s;
  }

  .shadow-left {
    @include box-shadow(inset 10px 0 10px -11px RGB(0 0 0 / .45));
  }
  .shadow-left-placeholder {
    position: absolute;
    top: 0;
    left: 330px;
    bottom: 0;
    padding-left: 4px;
    z-index: 12;
    transition: box-shadow 1s;
  }

  .shadow-top {
    @include box-shadow(0 0 10px 2px RGB(0 0 0 / .25));
  }

  .shadow-top-placeholder {
    transition: box-shadow 1s;
    background-color: transparent;
  }
}
