// Badges
.award-badge  {
  display: inline-block;

  img {
    max-height: 30px;
  }

  .text {
    color: #ffffff;
    padding: 1px 8px;
    height: 24px;
  }

  img, .text {
    margin-bottom: 3px;
  }
}

a.award-badge {
  text-decoration: none;
  margin-bottom: 4px;
}
