$table-field-icon-size: 16px;
$table-field-base-width: 40px;
$table-field-base-height: 40px;
$table-field-cell-width: 190px;
$table-field-cell-padding: 10px;
$table-field-disabled-colour: rgba(0,0,0,.02);

/* Table field */

.table-field {
  thead {
    th {
      width: $table-field-base-width;
      min-width: $table-field-base-width;
      padding: $table-field-cell-padding;
      height: $table-field-base-height;
      vertical-align: middle;
    }
  }

  tbody {
    border-width: 2px 0;
    border-style: solid;
    border-color: $colour-border;

    &.has-calculations {
      border-bottom-color: black;
    }

    td {
      width: $table-field-base-width;
      min-width: $table-field-base-width;
      height: $table-field-base-height;
      vertical-align: middle;
      border-style: solid;
      border-color: $colour-border;
      border-width: 1px 0;
    }

    .cell {
      width: $table-field-cell-width;
      min-width: $table-field-cell-width;
      padding: $table-field-cell-padding;
      border: 1px solid $colour-input-border;
    }

    .cell-disabled {
      border-style: solid;
      border-color: $colour-border;
      border-width: 1px 0;
    }

    td:last-child {
      text-align: center;
    }

    .delete-row {
      .af-icons-close-circle {
        font-size: $table-field-icon-size;
      }
    }
  }

  tfoot {
    .row-calculation {
      border-bottom: 1px solid $colour-border;
    }

    .cell-calculation {
      padding: 10px 8px;

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }

  // Avoid using solid background to make the scrolling shadow effect nicer
  [disabled] {
    background-color: $table-field-disabled-colour !important;
  }
}

/* Table field configurator */

.table-field-configurator-container {
  .panel {
    .panel-body {
      display: inline-block;
      padding: 0;
      margin: 15px;
    }
  }
}

.table-field-configurator {
  thead {
    th {
      padding: 0;
    }

    .columns {
      display: table-row;

      .column {
        display: table-cell;
        vertical-align: middle;
        width: $table-field-cell-width;
        min-width: $table-field-cell-width;
        padding: $table-field-cell-padding;

        .dropdown > a {
          color: $font-primary-colour;
          text-decoration: none;
          display: inline-block;
          padding: 3px 0;
        }

        .drag-handle:hover {
          cursor: move;
        }
      }
    }

    .add-column {
      text-align: center;

      .af-icons-plus {
        font-size: $af-icons-base-size;
      }
    }
  }

  tbody {
    .cell-editable {
      position: relative;

      // Allow <td> elements with 'position: relative' to have borders
      // https://bugzilla.mozilla.org/show_bug.cgi?id=688556
      background-clip: padding-box !important;

      &:hover {
        cursor: pointer;
      }

      .name {
        width: $table-field-cell-width - 20;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .cell-disabled {
      background: $table-field-disabled-colour;
      border: 1px solid $colour-border;
      border-width: 1px 0 1px 1px;

      &.cell-last {
        border-width: 1px;
      }
    }

    .editor {
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 100;
      padding: 10px 10px 0 10px;
      background: white;
      min-width: 300px;
      border: 1px solid $colour-input-border;
    }

    .rows {
      display: table;

      .row {
        display: table-row;

        a {
          display: table-cell;
          color: $font-primary-colour;
          text-decoration: none;
          width: $table-field-base-width;
          height: $table-field-base-height;
          vertical-align: middle;
          text-align: center;

          &:hover {
            cursor: move;
          }
        }
      }
    }
  }

  tfoot {
    td {
      width: $table-field-base-width;
      min-width: $table-field-base-width;
      height: $table-field-base-height + 10;
      vertical-align: middle;
    }

    .add-row {
      margin: 10px 10px 10px 0;
    }

    .dynamic-rows {
      line-height: 1.4;
    }
  }
}

/* Table field input */

.table-field-input-container {
  .panel {
    background-color: transparent;
    border: none;
    margin: 0;

    .panel-body {
      display: inline-block;
      padding: 0;
      margin: 0 0 10px 0;
    }
  }
}

.table-field-input {
  tbody {
    .cell-editable {
      padding: 0;

      input {
        border: none;
        min-width: $table-field-cell-width - 2;
        height: 100%;
        color: $font-primary-colour;
        background-color: transparent;
        @include placeholder;

        &[type='number'] {
          // Hide the spin-button
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        &:focus {
          outline-width: 3px;
        }
      }
    }

    .cell-integer,
    .cell-decimal,
    .cell-decimal-precise {
      input {
        text-align: right;
      }
    }

    tr:hover {
      .delete-row {
        display: inline-block;
      }
    }
  }

  tfoot {
    .add-row {
      margin: 10px 0 0 0;
    }
  }
}

/* Table field preview */

.table-field-preview-container {
  .panel {
    border: none;
    margin: 0;

    .panel-body {
      padding: 0;
      margin: 0 0 10px 0;
    }
  }
}

.table-field-preview {
  margin-bottom: 0;

  tbody {
    border-bottom: 2px solid $colour-border;

    .index {
      width: $table-field-base-width;
      min-width: $table-field-base-width;
    }

    .cell-integer,
    .cell-decimal,
    .cell-decimal-precise {
      text-align: right;
    }

    &.has-calculations {
      border-bottom-color: black;
    }
  }

  tfoot {
    .row-calculation {
      border-bottom: 1px solid $colour-border;
    }

    .cell-calculation > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
    }
  }
}
