.plagiarism-results {
  max-width: 240px;
  height: 35px;
  margin: 10px 0;

  & > div {
    display: inline-block;
  }

  & > div.report {
    margin-left: 10px;
  }

  .pie {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: lightgrey;
    margin: 0 10px;

    &.good {
      background-image: linear-gradient(to right, transparent 50%, #009349 0);

      &.under-50::before {
        background-color: inherit;
      }

      &::before {
        background-color: #009349;
      }
    }

    &.warning {
      background-image: linear-gradient(to right, transparent 50%, #ff7500 0);

      &.under-50::before {
        background-color: inherit;
      }

      &::before {
        background-color: #ff7500;
      }
    }

    &.danger {
      background-image: linear-gradient(to right, transparent 50%, #bb0000 0);

      &.under-50::before {
        background-color: inherit;
      }

      &::before {
        background-color: #bb0000;
      }
    }

    &.empty-pie::before {
      background-image: none;
    }

    &::before {
      content: '';
      display: block;
      margin-left: 50%;
      height: 100%;
      border-radius: 0 100% 100% 0 / 50%;
      transform-origin: left;
    }
  }

  .good {
    color: #009349;
  }

  .warning {
    color: #ff7500;
  }

  .danger {
    color: #bb0000;
  }

  .percentage {
    display: block;
    font-size: 18px;
  }

  .copied-words {
    display: block;
  }

  .suspect, .copied-words, .report {
    font-size: 12px;
  }

  & + hr {
    margin: 5px 0;
  }
}
