.open-rounds-container {
  .round-selector {
    margin-right: 0;
  }

  .popover {
    width: 400px;
    color: $font-primary-colour;

    h4 {
      margin: 0 0 10px 0;
    }

    .actions {
      margin: 10px 0 0 0;
    }
  }

  .open-rounds-reset {
    padding-left: 10px;
  }
}
