//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed * 0.5);

  small,
  .small {
    font-size: 65%;
  }
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }

// Body text
// -------------------------

p {
  margin: 0 0 ($line-height-computed * 0.5);
}

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
  margin-top: 0;
  margin-bottom: ($line-height-computed * 0.5);
}
