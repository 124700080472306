// Scaffolding ================================================================

@mixin hyperlink-focus() {
  // Default
  outline: 0;
  // WebKit
  outline: 5px auto $link-hover-color;;
  outline-offset: -2px;
}

a {
  cursor: pointer;
  text-decoration: underline;

  // Disable dotted outline on FF/IE after a link is clicked
  outline: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: solid underline $link-hover-color 2px;
  }

  &:focus {
    @include hyperlink-focus()
  }
}

// Buttons ====================================================================
.btn {
  @include transition( background $transition-speed );
  display: inline-flex;
  align-items: center;
  padding: 7px 20px;
  font-weight: 400;
  border: 0;
  border-radius: $border-radius-base;
  white-space: normal;
  text-decoration: none;
}

/* Buttons placed right next to each other need more spacing */
button, a.btn, .btn {
  margin-right: 5px;
}

input.btn {
  width: auto;
}

.btn-lg {
  font-size: 1.285em;
  line-height: 1.2em;
}

.btn-secondary {
  color: #ffffff;
  background-color: $colour-secondary;
  border-color: $colour-secondary;
}

.btn-tertiary {
  $color: #ffffff;
  $background: $colour-tertiary;
  $border: $colour-tertiary;

  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active {
    background-image: none;
  }
}


// Typography =================================================================

p {
  margin: 1em 0px;
}

ul, ol {
  margin-bottom: 1em;
}




// Forms ======================================================================

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 2px solid $color;
  }
}

label {
  font-weight: normal;
  & + .vue-tooltip-icon {
    margin-left: 2px;
  }
}

.form-control,
input {
  border-width: 1px;
  padding: 7px 8px;
}

// Containers =================================================================

.well {
  @include box-shadow(none);
}
