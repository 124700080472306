.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $colour-link;
  border: 1px solid $colour-link;
  color: white;
  font-size: $font-size-h4;
  overflow: hidden;
  margin-right: 0;
}

.chip-inv {
  @extend .chip;
  color: $colour-link;
  background: transparent !important;
  font-size: $font-size-h1;

  &[disabled] {
    background-color: transparent !important;
    color: $colour-disabled;
    border-color: $colour-disabled;
  }
}
