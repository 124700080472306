/**
 * Styling for the dynamic scrollbars used across the application.
 */

.ps-container .ps-scrollbar-x-rail {
  position: absolute; /* please don't change 'position' */
  bottom: 3px; /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -o-transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
}

.ps-container:hover .ps-scrollbar-x-rail,
.ps-container.hover .ps-scrollbar-x-rail {
  opacity: 0.6;
  filter: alpha(opacity = 60);
}

.ps-container .ps-scrollbar-x-rail:hover,
.ps-container .ps-scrollbar-x-rail.hover {
  background-color: #eee;
  opacity: 0.9;
  filter: alpha(opacity = 90);
}

.ps-container .ps-scrollbar-x-rail.in-scrolling {
  opacity: 0.9;
  filter: alpha(opacity = 90);
}

.ps-container .ps-scrollbar-y-rail {
  position: absolute; /* please don't change 'position' */
  right: 3px; /* there must be 'right' for ps-scrollbar-y-rail */
  width: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -o-transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
}

.ps-container:hover .ps-scrollbar-y-rail,
.ps-container.hover .ps-scrollbar-y-rail {
  opacity: 0.6;
  filter: alpha(opacity = 60);
}

.ps-container .ps-scrollbar-y-rail:hover,
.ps-container .ps-scrollbar-y-rail.hover {
  background-color: #eee;
  opacity: 0.9;
  filter: alpha(opacity = 90);
}

.ps-container .ps-scrollbar-y-rail.in-scrolling {
  opacity: 0.9;
  filter: alpha(opacity = 90);
}

.ps-container .ps-scrollbar-x {
  position: absolute; /* please don't change 'position' */
  bottom: 0; /* there must be 'bottom' for ps-scrollbar-x */
  height: 8px;
  background-color: #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -o-transition: background-color .2s linear;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.ps-container.ie6 .ps-scrollbar-x {
  font-size: 0; /* fixed scrollbar height in xp sp3 ie6 */
}

.ps-container .ps-scrollbar-x-rail:hover .ps-scrollbar-x,
.ps-container .ps-scrollbar-x-rail.hover .ps-scrollbar-x {
  background-color: #999;
}

.ps-container .ps-scrollbar-y {
  position: absolute; /* please don't change 'position' */
  right: 0; /* there must be 'right' for ps-scrollbar-y */
  width: 8px;
  background-color: #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -o-transition: background-color .2s linear;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.ps-container.ie .ps-scrollbar-y {
  font-size: 0; /* fixed scrollbar height in xp sp3 ie6 */
}

.ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y,
.ps-container .ps-scrollbar-y-rail.hover .ps-scrollbar-y {
  background-color: #999;
}
