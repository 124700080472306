.account-switcher {
  position: relative;
  display: inline-block;
  text-align: left;
  font-weight: 400;
}

.account-switcher-button {
  padding: 16px 15px 11px 25px;
  margin: -16px -15px 0 -25px;
  min-width: 220px;
  display: inline-flex;
  color: #fff;
  background-color: $colour-header-background;
  text-decoration: none;
  border: 0;
}

@media (max-width: 767px) {
  .account-switcher-button {
    padding-left: 16px;
    margin-left: 0;
  }
}

.account-switcher-button:hover,
.account-switcher-button:focus {
  outline: none;
  outline-offset: 0;
  text-decoration: none !important;
  background-color: $navigation-active;
  color: $navigation-text-active;
}

.account-switcher-button > .af-icons-chevron-down {
  font-size: 0.8em;
  margin-left: 5px;
}

.account-switcher-button-text {
  min-height: 25px;
  white-space: nowrap;
  overflow: hidden;
}

.account-switcher-button-text:focus {
  outline: none !important;
  outline-offset: 0;
}

/* Show max of 16 characters when screen xs */
@media (max-width: $screen-xs) {
  .account-switcher-button-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 18ch;
  }
}

.account-switcher-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  min-width: 300px;
  z-index: 10;
  border: 1px solid $link-color;
  background: #fff;
  border-radius: $default-radius;
  overflow: hidden;
  font-size: $font-size-base;
}

.account-switcher-searcher {
  width: 100%;
  border-bottom: 1px solid $link-color;
}

.account-switcher-searcher input {
  border: 0;
  padding: 15px;
  width: 100%;

  &:focus {
    outline: none;
    outline-offset: 0;
  }
}

.account-switcher-loading, .account-switcher-no-account-found {
  padding: 10px;
  color: $dropdown-link-color;
}
.account-switcher-loading .af-icons-repeat {
  margin-right: 0.5em;
}

.account-switcher-add-account {
  width: 100%;
  border-top: 1px solid $link-color;
}

.account-switcher-add-account button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  width: 100%;
  text-align: left;
  padding: 12px 16px;
}

.account-switcher-add-account .af-icons-add {
  margin-right: 8px;
}

.account-switcher-items {
  max-height: 380px;
  overflow-y: auto;
}

.account-switcher-items-shadow-bottom {
  @include box-shadow(0 3px 10px 2px RGB(0, 0, 0, 0.25));
  transition: box-shadow 1s;
}
.account-switcher-items-shadow-top {
  @include box-shadow(0 -3px 10px 2px RGB(0, 0, 0, 0.25));
  transition: box-shadow 1s;
}

.account-switcher-items-no-shadow {
  @include box-shadow(0 0 0 0 RGB(0, 0, 0, 0));
  transition: box-shadow 1s;
}

.account-switcher-result-item {
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  text-align: left;
  padding: 7px 16px;
  background-color: transparent;
}

.account-switcher-result-item:hover,
.account-switcher-result-item:focus,
.account-switcher-add-account button:hover,
.account-switcher-add-account button:focus {
  background-color: $link-color;
  color: #fff;
  outline: none;
  outline-offset: 0;
}

.account-switcher-favicon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.account-switcher-result-item .loading-icon-wrapper {
  margin: 4px 12px 4px 4px;
}
