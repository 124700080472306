/*
|--------------------------------------------------------------------------
| Miscellaneous styles
|--------------------------------------------------------------------------
|
| Small styles that are related to any page, or misc styles that don't
| properly fit in another file/category.
|
*/

#ie-msg { display: none; }
