// TODO: transfer at the proper .scss
@import 'main_menu';

#navigation {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
  .navbar-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    min-width: $main-menu-width;
    button .icon-bar {
      background: #FFFFFF;
    }
  }
  .navbar-brand {
    display: flex;
    width: 100%;
    padding-left: 0;
    font-weight: 300;
    letter-spacing: -0.01em;
  }

  .navbar-toggle {
    span {
      @include transition(all $transition-speed);
    }

    margin-left: 5px;
    margin-right: 5px;
  }

  .switch-account {
    padding: 10px 20px;
    width: 260px;
  }

  .pull-right {
    .dropdown-menu {
      right: 10px;
      left: auto;
    }
  }

  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }

  #menu-main {
    background-color: $navigation-background;
  }

  .navbar-item {
    display: inline-flex;
    align-items: center;
    color: #fff;
    background-color: $colour-header-background;
    padding: 8px;
    margin: 0;
    line-height: 20px;
    text-decoration: none !important;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 0;
    vertical-align: middle;


    span {
      white-space: nowrap;
      display: inline-flex;
      align-items: center;
    }

    span.af-icons {
      font-size: 20px;
      width: 20px;
      vertical-align: middle;
    }

    .cart-link {
      padding-left: 5px;
      vertical-align: middle;
    }

    .username {
      padding-right: 5px;
      vertical-align: middle;
    }

    &:hover, &:focus, &:focus-visible {
      background-color: $navigation-active;
      color: $navigation-text-active;
      outline: none;
    }

    .badge {
      position: absolute;
      bottom: -8px;
      right: calc(100% - 8px);
      background-color: $brand-danger; /* Background color of the badge */
      color: #fff; /* Text color of the number */
    }
  }

  .navbar-user {
    margin-left: auto;
    display: flex;

    .dropdown-menu {
      min-width: 180px;
    }
  }

  /* Global link styles (for main menu and submenus). */
  .menu {
    .active,
    a.active {
      .af-icons {
        color: white !important;
      }
    }

    a,
    .submenu-title {
      @include transition(all $transition-speed);

      color: $navigation-text;
      cursor: pointer;
      display: block;
      font-size: 100%;
      padding: 25px 0;
      position: relative;
      text-decoration: none;

      &:hover,
      &:focus {
        background: $navigation-hover;
        color: $navigation-text-hover;
      }

      .af-icons {
        font-size: $af-icons-base-size;
      }

      .af-icons-plus {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -12px;
      }
    }

    span {
      display: inline-block;
      margin-left: 23px;
      vertical-align: middle;
      width: 28px;
    }

    span.text {
      width: 70%;
    }

    .children {
      span {
        margin-left: 0;
      }

      span.text {
        width: auto;
      }
    }

    span {
      & + span {
        margin-left: 5px;
        width: auto;
      }
    }

    /* submenus */
    ul {
      @include transition(all $transition-speed);

      max-height: 0;
      overflow: hidden;

      li {
        a {
          padding: 7px 15px 7px 40px;
        }
      }
    }

    .parent {
      &.active {
        & > ul {
          max-height: 1000px;
          padding: 20px 0;
        }

        & > .submenu-title {
          background: $colour-link;
          color: #fff;
        }

        .af-icons {
          color: white;
        }
      }
    }

    a.active {
      background: $colour-link;
      color: #fff;
    }
  }
}

@media (min-width: $screen-sm) {
  #navigation {
    .navbar-header {
      width: auto;
    }

    .navbar-brand {
      padding-left: 25px;
    }

    .menu {
      span.text {
        width: 120px;
      }
    }

    .navbar-user {
      right: 15px;
    }

    .navbar-toggle {
      margin-left: 0;
      margin-right: 15px;
    }

    .sidebar {
      z-index: 1;
      position: absolute;
      width: 220px;
    }
  }
}

#menu-user {
  max-width: 180px;
  margin-right: 8px;

  .dropdown-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 7px 10px;
  }
}
