@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.social-auth {
  h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .btn {
    margin-bottom: 5px;
  }

  .btn-google {
    @include button-variant(#ffffff, #4285F4, #e5e5e5);
    padding: 0;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;

    .btn-google-container {
      display: flex;
      align-items: center;

      .google-logo {
        height: 36px;
        padding: 2px;
      }

      .google-text {
        margin-left: 15px;
        padding-right: 15px;
      }
    }

    &:hover {
      -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
      box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
    }
  }

  .btn-facebook {
    @include button-variant(#ffffff, #0866FF, #0866FF);
    min-height: 36px;
    padding: 8px 15px;
  }

  .btn-twitter {
    @include button-variant(#ffffff, #4c4d4d, #4c4d4d);
    min-height: 36px;
    padding: 8px 15px;
  }

  .btn-linkedin {
    @include button-variant(#ffffff, #0A66C2, #0A66C2);
    min-height: 36px;
    padding: 8px 15px;
  }
}

h3.or {
  color: $font-primary-colour;
  margin: 10px 0;
}

@media all and (max-width: 961px) {
  .social-auth-login {
    margin-bottom: 50px;
  }
}

#six-digit-code {
  .input-wrapper {
    display: flex;
    justify-content: center;
    border: 1px solid #000000;
    border-radius: 4px;
    overflow: hidden;

    &.has-errors {
      border-color: $colour-error;
    }
  }

  input {
    font-size: 32px;
    font-weight: 300;
    border-right: 1px solid #dddddd;
    border-left: none;
    border-top: none;
    border-bottom: none;
    padding: 10px;
    width: 100%;
    text-align: center;
    margin: 0;
    &:last-child {
      border-right: none;
    }
    &::placeholder {
      color: #dddddd;
    }
  }

  .has-errors input {
    border-color: $colour-error;
  }

  .error {
    a {
      color: $colour-error;
    }
  }

  .has-errors {
    label,
    span,
    a {
      color: $colour-error;
    }
  }
}
