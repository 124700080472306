.domain {
  float: left;

  input {
    width: 100px;
    text-align: right;
  }
}

.http {
  float: left;
  padding: 10px 5px 0 0;
}

.platform-suffix {
  float: left;
  padding: 8px 0 0 5px;
}

.btn-approve {
  color: #fff;
  border-color: $colour-approved;
  background-color: $colour-approved;
}

.btn-reject {
  color: #fff;
  border-color: $colour-rejected;
  background-color: $colour-rejected;
}
