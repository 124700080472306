#broadcast-preview {
  margin: 20px 0 0 0;

  & > div {
    width: 582px;
  }

  & > #email {
    background-color: white;
    border: 1px solid #dddddd;
    margin: 0 0 20px 0;
    padding: 0;

    #email-header > img {
      max-width: 100%;
      max-height: 400px;
    }

    #email-content {
      padding: 0 15px;
    }

    #email-footer-img > img {
      max-width: 100%;
      max-height: 400px;
    }
  }

  #email-footer,
  #unsubscribe-link {
    font-size: 0.9em;
    padding: 15px;
    padding-top: 0;

    p {
      margin: 0;
    }
  }

  #copy-to-clipboard {
    text-align: right;
  }
}
