.loader {
  background: #fff;
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1001;
}
