@font-face {
  font-family: 'af-icons';
  src: url('../fonts/af-icons.eot');
  src: url('../fonts/af-icons.eot?#iefix') format('eot'),
    url('../fonts/af-icons.woff') format('woff'),
    url('../fonts/af-icons.ttf') format('truetype'),
    url('../fonts/af-icons.svg#af-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin af-icons() {
  display: inline-block;
  font-smooth: never;
  font-family: 'af-icons';
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.af-icons {
  @include af-icons();
}

.af-icons-animate-rotate {
  font-size: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  animation-name: rotateAFIcon;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateAFIcon {
  from { transform: scale( 1 ) rotate( 0deg );   }
  to   { transform: scale( 1 ) rotate( 360deg ); }
}




.af-icons-account-setting:before {
  content: "\E001"
}


.af-icons-arrow-down:before {
  content: "\E006"
}


.af-icons-arrow-up:before {
  content: "\E009"
}


.af-icons-attachment:before {
  content: "\E00A"
}


.af-icons-audio-pause:before {
  content: "\E00B"
}


.af-icons-audio-play:before {
  content: "\E00C"
}


.af-icons-chevron-down:before {
  content: "\E00D"
}


.af-icons-chevron-up:before {
  content: "\E00E"
}


.af-icons-close-circle:before {
  content: "\E00F"
}


.af-icons-cross:before {
  content: "\E010"
}


.af-icons-arrow-tail-left:before {
  content: "\E011"
}


.af-icons-arrow-tail-right:before {
  content: "\E012"
}


.af-icons-up-down:before {
  content: "\E013"
}


.af-icons-delete:before {
  content: "\E014"
}


.af-icons-fill:before {
  content: "\E015"
}


.af-icons-download:before {
  content: "\E016"
}


.af-icons-entries:before {
  content: "\E017"
}


.af-icons-flow:before {
  content: "\E018"
}


.af-icons-link:before {
  content: "\E019"
}


.af-icons-home:before {
  content: "\E01A"
}


.af-icons-contracts:before {
  content: "\E01B"
}


.af-icons-feedback:before {
  content: "\E01C"
}


.af-icons-shared:before {
  content: "\E01D"
}


.af-icons-reports:before {
  content: "\E01F"
}


.af-icons-auto:before {
  content: "\E020"
}


.af-icons-help:before {
  content: "\E021"
}


.af-icons-clipboard:before {
  content: "\E023"
}


.af-icons-judging:before {
  content: "\E025"
}


.af-icons-leaderboard:before {
  content: "\E026"
}


.af-icons-document:before {
  content: "\E027"
}


.af-icons-sad:before {
  content: "\E028"
}


.af-icons-lock:before {
  content: "\E029"
}


.af-icons-logout:before {
  content: "\E02A"
}


.af-icons-af:before {
  content: "\E02B"
}


.af-icons-archived:before {
  content: "\E02C"
}


.af-icons-my:before {
  content: "\E02D"
}


.af-icons-orders:before {
  content: "\E02F"
}


.af-icons-add:before {
  content: "\E030"
}


.af-icons-plus:before {
  content: "\E031"
}


.af-icons-preview:before {
  content: "\E033"
}


.af-icons-repeat:before {
  content: "\E034"
}


.af-icons-search:before {
  content: "\E035"
}


.af-icons-settings:before {
  content: "\E036"
}


.af-icons-tick:before {
  content: "\E038"
}


.af-icons-email:before {
  content: "\E039"
}


.af-icons-user:before {
  content: "\E03A"
}


.af-icons-organisation:before {
  content: "\E03B"
}


.af-icons-matrix:before {
  content: "\E03C"
}


.af-icons-vote:before {
  content: "\E03E"
}


.af-icons-link-open:before {
  content: "\E03F"
}


.af-icons-cart:before {
  content: "\E044"
}


.af-icons-voted:before {
  content: "\E045"
}


.af-icons-new:before {
  content: "\E046"
}


.af-icons-move-left:before {
  content: "\E047"
}


.af-icons-move-right:before {
  content: "\E048"
}


.af-icons-info:before {
  content: "\E049"
}


.af-icons-vote-plus:before {
  content: "\E04A"
}


.af-icons-vote-minus:before {
  content: "\E04B"
}


.af-icons-users:before {
  content: "\E04C"
}


.af-icons-fullscreen:before {
  content: "\E04D"
}


.af-icons-fullscreen-off:before {
  content: "\E04E"
}


.af-icons-pdf:before {
  content: "\E04F"
}


.af-icons-gallery:before {
  content: "\E050"
}


.af-icons-action-overflow:before {
  content: "\E051"
}


.af-icons-dashboard:before {
  content: "\E052"
}


.af-icons-cog:before {
  content: "\E055"
}


.af-icons-certificate:before {
  content: "\E056"
}


.af-icons-read-off:before {
  content: "\E057"
}


.af-icons-write-off:before {
  content: "\E058"
}


.af-icons-comment:before {
  content: "\E059"
}


.af-icons-comments:before {
  content: "\E05A"
}


.af-icons-content:before {
  content: "\E05B"
}


.af-icons-edit:before {
  content: "\E05C"
}


.af-icons-accounts:before {
  content: "\E05D"
}


.af-icons-jedi:before {
  content: "\E05E"
}


.af-icons-round:before {
  content: "\E05F"
}


.af-icons-slideshow:before {
  content: "\E060"
}


.af-icons-invoice:before {
  content: "\E061"
}


.af-icons-audit:before {
  content: "\E062"
}


.af-icons-funding:before {
  content: "\E063"
}


.af-icons-guide:before {
  content: "\E065"
}


.af-icons-packing-slip:before {
  content: "\E066"
}


.af-icons-sun:before {
  content: "\E067"
}


.af-icons-half-moon:before {
  content: "\E068"
}


.af-icons-moon:before {
  content: "\E069"
}


.af-icons-star-filled:before {
  content: "\E06A"
}


.af-icons-star-add:before {
  content: "\E06B"
}


.af-icons-star-remove:before {
  content: "\E06C"
}


.af-icons-star-hollow:before {
  content: "\E06D"
}


.af-icons-drag-n-drop-uploader:before {
  content: "\E070"
}


.af-icons-pin:before {
  content: "\E071"
}


.af-icons-visa:before {
  content: "\E072"
}


.af-icons-mastercard:before {
  content: "\E073"
}


.af-icons-amex:before {
  content: "\E074"
}

