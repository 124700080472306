.add-collaborator {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 34px;
  padding: 0;
  margin-right: 0;
}
