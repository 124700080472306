/*
|--------------------------------------------------------------------------
| Miscellaneous styles
|--------------------------------------------------------------------------
|
| Small styles that are related to any page, or misc styles that don't
| properly fit in another file/category.
|
*/

// Loading indicator.
#loading-indicator {
  @include position( fixed , 170px 0 0 48% );
  @include size( 50 );
  border: 1px solid #333;
  background: rgba(black , 0.7);
  border-radius: $default-radius;
  color: white;
  z-index: 200;

  @include lt-ie9 { background: #333; }

  img { margin: 8px 0 0 8px; }
}

// Elements that are initially hidden until the page is loaded.
.display-on-load {
  display: none !important;
}

// the initial load indicator.
#initial-load-indicator {
  @include position( absolute , 0px 0px 0px 0px );
  background: white;

  #initial-load-content {
    background: rgba(black , 0.7);
    border-radius: 10px;
    color: white;
    font-size: 20px;
    line-height: 32px;
    margin: 100px auto;
    max-width: 400px;
    padding: 40px;
    text-align: center;

    @include lt-ie9 { background: #333; }

    img { margin-top: 20px; }
  }
}

// Validation errors list.
.validation-errors {
  margin: 0;
  list-style: none;
  padding-left: 0;

  li + li { margin-top: 8px; }
}

.export-csv-container {
  padding: 22px 0;
}

.dates, .faq {
  h4 {
    margin-top: 25px;
    margin-bottom: 10px;

    + p { margin-top: 0; }
  }
}

.word-count, .character-count {
  display: block;
  text-align: right;
  margin: 0 0 5px 10px;

  &.invalid {
    color: $colour-quaternary;
  }
}

// Panels update/create page.
.panels-row {
  .checkbox { width: 12%; }

  .label { width: 88%; }
}

// Theme specific styling
.theme-option-field { width: 80%; }

.theme-hint-text { margin-bottom: 4px; }

// Labels
.labels {
  margin-top: 5px;
  max-width: 500px;

  .label-tag {
    background-color: $font-primary-colour;
  }

  .label-approved {
    background-color: $colour-approved;
  }

  .label-rejected {
    background-color: $colour-rejected;
  }

  .label-unsure {
    background-color: $colour-unsure;
  }

  .label-review-status {
    border: 1px solid;
    background-color: transparent;
    color: $font-primary-colour;
  }

  .payment-status {
    line-height: 1;
    display: inline-block;
    padding-bottom: 6px;
  }

  .payment-status-in-cart,
  .payment-status-awaiting-payment {
    color: $colour-warning-dark;
  }
}

span.optional-field {
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 5px;
}

// No reCAPTCHA / FunCaptcha warning (Registration page)
.no-captcha {
  padding: 15px 23px;
  color: #fff;

  &:before {
    font-family: 'af-icons-md';
    font-size: 28px;
    line-height: 28px;
    float: left;
    color: #fff;
    content: '\E002';
  }

  .warning {
    margin-left: 40px;
  }
}

#ie-msg { display: none; }

.lt-ie10 {
  #ie-msg { display: block; }
}

// Boxed URLs for role registration forms and score-sets
.role-url,
.score-set-url {
  padding: 10px;
  overflow-y: hidden;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
}

// Scroll overflowing content horizontally
.scroll-horizontally {
  overflow-x: auto;

  // Pure CSS scrolling shadow effect inspired by:
  // http://lea.verou.me/2012/04/background-attachment-local/
  &.shadows {
    background-image:
      linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      linear-gradient(to right, rgba(0,0,0,.1), rgba(255,255,255,0)),
      linear-gradient(to left, rgba(0,0,0,.1), rgba(255,255,255,0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 10px 100%, 10px 100%, 5px 100%, 5px 100%;

    background-attachment: local, local, scroll, scroll;
  }
}

// Scroll overflowing content vertically
.scroll-vertically {
  overflow-y: auto;
}

// Buttons with left/right arrow
.btn {
  .af-icons-md-arrow-left,
  .af-icons-md-arrow-right {
    font-size: 0.8em;
  }
}

// PDF links
.pdf-link {
  text-decoration: none;

  span {
    vertical-align: middle;
    padding-left: 5px;
    text-decoration: underline;
  }

  .af-icons-pdf {
    color: $colour-pdf;
    font-size: 24px;
    vertical-align: middle;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }
}

// PDF links
.pdf-link-blank-pdf-link {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  .af-icons-pdf {
    color: $colour-pdf;
    font-size: 24px;
    vertical-align: middle;
  }

  &:hover {
    text-decoration: none;
    span {
      text-decoration: underline;
    }
  }
}

.pdf-link-icon-right {
  span {
    padding-left: 0;
    padding-right: 5px;
  }
}

// Override elevio styles
.ctxUnderline[data-elevio-module='support'] {
  cursor: auto;
  border-bottom: 0;
}

._elevio_navbar {
  padding: 19px !important;
}

// Remove underline on hover/focus
.no-underline:hover,
.no-underline:focus {
  text-decoration: none;
}

// Icon customisations
.af-icons.deleted-state,
.af-icons.locked-state {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.breadcrumb-icon {
  font-size: 0.8em;
}

.af-icons.danger {
  color: $colour-danger;

  &:hover {
    color: $colour-danger;
  }
}

// Icons in a list
.icon-list {
  @include list-unstyled;

  li {
    @include shift-icon-position(20px);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text-content {
  @include text-content;
}

.authenticator-adapter.authenticator-codes {
  h2 {
    word-break: break-word;
  }
}

.configuring {
	@include configuring;
}

.season-form {
  .label-content {
      margin-left: 25px;
  }
}

.cursor-pointer{
  cursor:pointer
}

.override-tray-color {
  .multiselect-options {
    .checkbox {
      color: #333 !important;
    }
  }
}
.markdown-img {
  vertical-align: top;
  max-width: 100%;
}

.pdf-iframe {
  border: none;
  display: block;
  height: 70vh;
  width: 100%;
}
