$widget-padding : 10px 20px;
$widget-round-ended-color: #bb0000;
$widget-round-ending-color: #ff7500;
$widget-round-start-color: #666666;
$widget-round-active-color: #009349;

.widget {
  border: 1px solid $colour-border;
  overflow: auto;
  padding: $widget-padding;
  margin-bottom: 20px;
  background-color: white;
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.3));

  &.widget-full{
    padding: 0;
  }

  &.widget-form {
    border: none;
  }

  &.widget-score-set-judge {
    border-left-width: 0;
    border-right-width: 0;

    &.widget-round-ended {
      border-top-color: $widget-round-ended-color;
    }

    &.widget-round-starting,
    &.widget-round-ending {
      border-top-color: $widget-round-ending-color;
    }

    .widget-round-active {
      border-top-color: $widget-round-active-color;
    }

    .widget-round-label {
      position: absolute;
      top: 0;
      height: 20px;
      margin-top: 3px;
      padding-left: 10px;
      padding-right: 10px;
      color: white;

      &.widget-round-label-ended {
        background-color: $widget-round-ended-color;
      }

      &.widget-round-label-starting,
      &.widget-round-label-ending {
        background-color: $widget-round-ending-color;
        color: $text-color;
      }
    }

    .widget-body{
      h2{
        margin-top: 0;
      }
    }
  }

  &.widget-score-set-gallery {
    border-left-width: 0;
    border-right-width: 0;

    &.widget-round-ended {
      border-width: 3px 0 0 0;
      overflow: visible;
      border-top-color: $widget-round-ended-color;
    }

    .widget-round-label {
      position: absolute;
      top: 0;
      height: 20px;
      margin-top: 3px;
      padding-left: 10px;
      padding-right: 10px;
      color: white;

      &.widget-round-label-ended {
        background-color: $widget-round-ended-color;
      }
    }

    .widget-body{
      h2{
        margin-top: 0;
      }
    }

    .widget-round-label-ending {
      color: $text-color;
    }
  }



  &.widget-score-set-gallery{
    border-width: 0 0 0 0;
    .widget-body{
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
      a{
        &:hover, &:focus, &:active {
          text-decoration: none;
        }
      }
    }
  }

  .scoreset-coverimage{
    height: auto;
    max-width: 100%;
    width: 100%;
    display: block;
  }

  .widget-body{
    padding: $widget-padding;

    .score-set-progress{
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &.widget-chart {
    min-height: 255px;

    header {
      display: block;
      margin-bottom: 10px;
    }

    .chart {
      display: block;
      float: left;
      height: 190px;
      width: 100%;
    }

    .pie-chart {
      height: 150px;
    }

    .legend {
      float: right;
    }

    ul {
      margin: 0 0 0 10px;
      padding: 0;
    }

    li {
      font-size: 0.9em;
      line-height: 1.2em;
      list-style: none;
      margin: 0 0 5px 0;
      padding: 0;

      .line {
        display: inline-block;
        height: 3px;
        margin-right: 5px;
        position: relative;
        top: -2px;
        width: 20px;
      }
    }
  }

  header {
    h2 {
      margin: 0;
    }
  }

  .table {
    margin-bottom: 0;
  }
}

a.widget-score-set-gallery-link {
  text-decoration: none;
  height: fit-content;
  $linkColor: var(--link-color, $colour-link);

  .widget-body {
    .stat {
      color: $linkColor
    }
  }

  &:hover {
    header img {
      outline-offset: -1px;
      border-bottom: none;
      outline: 1px solid $linkColor;
    }

    .widget-body {
      background-color: $linkColor;

      .stat,
      h2 {
        color: white;
      }
    }
  }
}

.widget-body__rounds-details {
  display: flex;
  gap: 15px;
  align-items: flex-start;

  .widget-body__rounds {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;

    .widget-body__rounds--round {
      display: flex;
      flex-direction: column;
    }
  }
}

.widget-scoreset_imageless {
  height: 20px;
  background-color: $navbar-default-bg;
}

.round-settings {
  margin-left: 15px;
}

.widget-round {
  border-top-width: 3px;
  padding: 15px;
  position: relative;

  h2 {
    max-width: 85%;
  }

  .af-icons {
    font-size: $af-icons-base-size;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  p {
    margin: .5em 0 0 0;
  }
}

.widget-round-ended {
  border-top-color: $widget-round-ended-color;
}

.widget-round-ending {
  border-top-color: $widget-round-ending-color;
}

.widget-round-starting {
  border-top-color: $widget-round-start-color;
}

.widget-round-active {
  border-top-color: $widget-round-active-color;
}

.widget-forms {
  border-width: 3px 0 0 0;
}

.widget-form {
  margin: 0 20px 20px 0;
  width: 240px;
  flex-shrink: 0;
  background: none;
}

.widget-form h2 {
  letter-spacing: 0;
}

.no-cover-bar {
  background: $colour-header-background;
}

.cover-bar {
  display: block;
  width: 100%;
  height: 20px;
}

.chart-completion-in-progress,
.chart-completion-submitted {
  padding-top: 10px;
  text-align: center;

  h1 {
    font-size: 3em;
  }
}

.chart-completion-in-progress {
  padding-left: 15px;
}

.chart-completion-in-progress {
  padding-right: 15px;
}

.completion-chart {
  margin-top: 30px;
}

.widget-row {
  margin-top: 20px;
}

.stats td.stat {
  vertical-align: middle;
}

.stat {
  text-align: right;
}

.stat-fraction {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  margin-left: 25px;

  @mixin statFraction($percent: 100) {

    @if $percent > 100 {
      $percent: 100;
    }

    @if $percent < 0 {
      $percent: 100;
    }

    display: block;
    font-size: ((200 * $percent) * 0.01) * 1%;
    font-weight: (300 * $percent) * 0.01;
    line-height: (1.5 * $percent) * 0.01;
  }

  .fraction {
    display: flex;
    align-items: baseline;
  }

  .numerator {
    @include statFraction;
  }

  .denominator {
    @include statFraction(50);

    $margin-space: 2px;
    &::before{
      margin-right: $margin-space;
      margin-left: $margin-space;
      content: '/';
    }
  }
}

.data-volume h1.data-volume-amount {
  margin-bottom: 0;
}

.order-widget {
  .amount {
    h1 {
      margin-bottom: 0;
    }
  }

  .amount + .amount {
    h1 {
      margin-top: 10px;
    }
  }

  p.info {
    margin: 10px 0 5px;
  }
}

// Judging dashboard
.widget-score-set{
  &:not(.widget-score-set-judge)>:not(header) {
    a {
      color: $font-primary-colour;
      text-decoration: none;

      &:hover, &:focus, &:active {
        text-decoration: underline;
        color: $font-primary-colour;
      }
    }
  }
}

.widget-score-set {
  border-width: 3px 0 0 0;
  overflow: visible;

  .score-set-stats {
    display: flex;
  }

  .stat {
    width: 100%;
    text-align: left;
  }

  .stat .count {
    display: block;
    font-size: 200%;
    font-weight: 300;
    line-height: 1.5;
  }

  .details {
    display: flex;
  }

  .score-set-rounds {
    width: 66.66%;
  }

  .score-set-progress {
    width: 33.33%;
  }
}

.btn-nowrap {
  white-space: nowrap;
}

.round-activity-widget-container {
  a {
    &.focusable {
      color: $font-primary-colour;
      text-decoration: none;

      &:focus-visible, &:hover, &:focus {
        display: inherit;
      }
    }
  }
}

.judge-dashboard-page,
.gallery-dashboard-page{
  margin: -10px -15px 50px;

  .judge-dashboard-page-content,
  .gallery-dashboard-page-content {
    padding: 15px 15px 0;
    position: relative;
  }
}

#judge-dashboard,
#gallery-dashboard{
  display: grid;
  grid-template-columns: auto;

  &.judge-dashboard-empty,
  &.gallerydashboard-empty{
    display: block;
  }
  .score-set-stats .stat {
    width: inherit;
  }
}

@media(min-width: $screen-sm) {
  .judge-dashboard-page,
  .gallery-dashboard-page {
    margin: -10px -30px 50px;

    .judge-dashboard-page-content,
    .gallery-dashboard-page-content {
      padding: 30px 30px 0;
    }
  }
}

@media (min-width: $screen-md) {
  #judge-dashboard,
  #gallery-dashboard {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: $screen-lg) {
  #judge-dashboard
  ,#gallery-dashboard {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.judging-shortcut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: $line-height-base;
  padding: 6px 20px;

  &:hover {
    background-color: $navigation-hover;

    a.shortcut-link {
      color: $navigation-text-hover;
    }
  }

  &:focus, &:active {
    background-color: $dropdown-link-hover-bg;

    a.shortcut-link  {
      color: $dropdown-link-hover-color;
    }
  }

  a.shortcut-link  {
    color: $dropdown-link-color;
    text-decoration: none;
  }
}
