/*
Version: 3.5.2 Timestamp: Sat Nov  1 14:43:36 EDT 2014
*/
.select2-container {
  display: inline-block;

  /* inline-block for ie7 */
  *display: inline;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100%;
  zoom: 1;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {

  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.
    More Info : http://www.quirksmode.org/css/box.html
    */
  -webkit-box-sizing: border-box; /* webkit */
  -moz-box-sizing: border-box; /* firefox */
  box-sizing: border-box; /* css3 */

  color: $font-primary-colour;

  &:focus {
    border-color: $colour-input-border-focus;
  }
}

.select2-container .select2-choice {
  background: #fff;
  border-radius: $default-radius;
  border: 1px solid $colour-input-border;
  color: $font-primary-colour;
  display: block;
  overflow: hidden;
  padding: 7px;
  position: relative;
  width: 100%;
  min-height: 37px;

  white-space: nowrap;
  text-decoration: none;

  background-clip: padding-box;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html[dir='rtl'] .select2-container .select2-choice {
  padding: 0 8px 0 0;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;
  border-radius: 0 0 $default-radius $default-radius;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;
  float: none;
  width: auto;
}

html[dir='rtl'] .select2-container .select2-choice > .select2-chosen {
  margin-left: 26px;
  margin-right: 0;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 8px;

  font-size: 1px;
  text-decoration: none;

  border: 0;
  background: url('../img/select2.png?v=07122016') right top no-repeat;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;

  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0 0 $default-radius $default-radius;
  border-top: 0;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, .05);
  margin-top: -3px;
  min-width: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9999;
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: $default-radius $default-radius 0 0;
}

.select2-drop-active {
  border-color: $colour-input-border-focus;
  border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid $colour-input-border-focus;
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  border-left: 1px solid $colour-input-border;
  border-radius: 0 $default-radius $default-radius 0;

  background-clip: padding-box;
}

html[dir='rtl'] .select2-container .select2-choice .select2-arrow {
  left: 0;
  right: auto;

  border-left: none;
  border-right: 1px solid #aaa;
  border-radius: $default-radius 0 0 $default-radius;
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: transparent url('../img/arrow-down-select2.png') no-repeat;
  @include opacity(.4);

  &:hover {
    @include opacity(.7);
  }

  &.active {
    @include opacity(1);
  }
}

html[dir='rtl'] .select2-container .select2-choice .select2-arrow b {
  background-position: 2px 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;

  position: relative;
  z-index: 10000;

  white-space: nowrap;
}

.select2-search input {
  width: 100%;
  margin: 0;

  outline: 0;
  font-family: $font-primary;
  font-size: 1em;

  border: 1px solid #aaa;
  border-radius: 0;

  -webkit-box-shadow: none;
  box-shadow: none;

  background: #fff url('../img/select2.png?v=07122016') no-repeat 100% -22px;
  border-radius: $default-radius;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
}

html[dir='rtl'] .select2-search input {
  padding: 4px 5px 4px 20px;
  background: #fff url('../img/select2.png?v=07122016') no-repeat -37px -22px;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
}

.select2-search input.select2-active {
  background: #fff url('../img/select2-spinner.gif?v=07122016') no-repeat 100%;
  background-position: right 10px center;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 1px solid $colour-input-border-focus;
  outline: none;
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid $colour-input-border-focus;
  border-top-color: transparent;
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none;
}

html[dir='rtl'] .select2-dropdown-open .select2-choice .select2-arrow {
  border-right: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  //background-position: -18px 1px;
  @include opacity(1);
}

html[dir='rtl'] .select2-dropdown-open .select2-choice .select2-arrow b {
  //background-position: -16px 1px;
  @include opacity(1);
}

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html[dir='rtl'] .select2-results {
  padding: 0 4px 0 0;
  margin: 4px 0 4px 4px;
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  padding: 4px 8px 4px;
  margin: 0;
  cursor: pointer;

  min-height: 1em;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results-dept-1 .select2-result-label { padding-left: 20px }

.select2-results-dept-2 .select2-result-label { padding-left: 40px }

.select2-results-dept-3 .select2-result-label { padding-left: 60px }

.select2-results-dept-4 .select2-result-label { padding-left: 80px }

.select2-results-dept-5 .select2-result-label { padding-left: 100px }

.select2-results-dept-6 .select2-result-label { padding-left: 110px }

.select2-results-dept-7 .select2-result-label { padding-left: 120px }

.select2-results .select2-highlighted {
  background: none;
  color: $colour-secondary;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
  padding: 4px 8px 4px;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: $font-primary-colour;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url('../img/select2-spinner.gif?v=07122016') no-repeat 100%;
}

.select2-results .select2-ajax-error {
  background: rgba(255, 50, 50, .2);
}

.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}

/* multiselect */

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0 5px 0 0;
  position: relative;

  border: 1px solid #aaa;
  cursor: text;
  overflow: hidden;

  background-color: #fff;
}

html[dir='rtl'] .select2-container-multi .select2-choices {
  padding: 0 0 0 5px;
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid $colour-input-border-focus;
  outline: none;
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}

html[dir='rtl'] .select2-container-multi .select2-choices li
{
  float: right;
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;

  font-family: $font-primary;
  font-size: 100%;
  color: $font-primary-colour;
  outline: 0;
  border: 0;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url('../img/select2-spinner.gif?v=07122016') no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;

  line-height: 13px;
  color: $font-primary-colour;
  cursor: default;
  border: 1px solid #aaaaaa;

  border-radius: 3px;

  background-clip: padding-box;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html[dir='rtl'] .select2-container-multi .select2-choices .select2-search-choice
{
  margin: 3px 5px 3px 0;
  padding: 3px 18px 3px 5px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;

  font-size: 1px;
  outline: none;
  background: url('../img/select2.png?v=07122016') right top no-repeat;
}

html[dir='rtl'] .select2-search-choice-close {
  right: auto;
  left: 3px;
}

.select2-container-multi .select2-search-choice-close {
  left: 3px;
}

html[dir='rtl'] .select2-container-multi .select2-search-choice-close {
  left: auto;
  right: 2px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {    display: none;
  background: none;
}

/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

/* Retina-ize icons */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx)  {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: url('../img/select2@2x.png?v=07122016');
    background-repeat: no-repeat;
    background-size: 60px 60px;
  }

  .select2-search input {
    background-position: 100% -22px;
  }

  .select2-search input.select2-active {
    background: #fff url('../img/select2-spinner@2x.gif?v=07122016') no-repeat 100%;
    background-position: right 10px center;
    background-size: 16px 16px;
  }
}
