.simple-widget {
  padding: 20px;
  margin-bottom: 20px;
  background-color: $colour-form-box-background;
  border-radius: $default-radius;

  h1, h2, h3 {
    margin-top: 0;
  }

  .widget {
    margin-top: 20px;
  }
}

.simple-widget.tips {
  padding: 20px !important;
}

.simple-widget.simple-widget-form {
  color: $colour-form-box-text;

  a:not(.btn):not(.datepicker-control) {
    color: $colour-form-box-text;
  }
}
