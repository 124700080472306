// ContentBlock quick links
.content-block-container {
  position: relative;
  width: 100%;

  a.af-icons-edit {
    position: absolute;
    text-decoration: none;
    font-size: 1em;
    text-align: left;
    z-index: 2;
    line-height: 0;
  }

  &.new {
    height: 20px;
  }

  // Adjust the default top/right position based on the content block location
  &.new a.af-icons-edit { top: 30px; left: 15px; }

  &.edit a.af-icons-edit { top: 20px; left: 15px; }

  &.new.tab-info a.af-icons-edit { top: 25px; left: 0; }

  &.edit.about-page a.af-icons-edit { + div { padding-left: 17px; } top: 15px; left: 0; }

  &.new.judges-confidentiality-agreement a.af-icons-edit { top: 0; left: 0; }

  &.edit.judges-confidentiality-agreement a.af-icons-edit { + div { padding-left: 17px; } top: 10px; left: 0; }

  &.new.judging-comments a.af-icons-edit { top: 15px; left: 0; }

  &.edit.judging-comments a.af-icons-edit { + div { padding-left: 17px; } top: 10px; left: 0; }

  &.new.submission-completed a.af-icons-edit { top: 15px; left: 0; }

  &.edit.submission-completed a.af-icons-edit { + div { padding-left: 17px; } top: 15px; left: 0; }

  &.new.review-flow-page a.af-icons-edit { top: 10px; left: 0; }

  &.edit.review-flow-page a.af-icons-edit { + div { padding-left: 17px; } top: 10px; left: 0; }

  &.new.profile-preferences-info-box a.af-icons-edit { top: 0; left: 0; }

  &.edit.profile-preferences-info-box { margin-top: -25px; }

  &.new.judges-home a.af-icons-edit { top: 30px; left: 30px; }

  &.new.judge-dashboard a.af-icons-edit,
  &.new.gallery-dashboard a.af-icons-edit{ top: 17.5px; left: 15px; }
}

@media screen and (min-width: $screen-sm-min) {
  .content-block-container {
    &.new a.af-icons-edit { top: 30px; left: 30px; }

    &.edit a.af-icons-edit { top: 20px; left: 15px; }

    &.new.tab-info a.af-icons-edit { top: 25px; left: 30px; }

    &.edit.about-page a.af-icons-edit { + div { padding-left: 0; } top: 15px; left: -17px; }

    &.new.judges-confidentiality-agreement a.af-icons-edit { top: 0; left: 30px; }

    &.edit.judges-confidentiality-agreement a.af-icons-edit { + div { padding-left: 0; } top: 10px; left: -17px; }

    &.new.judging-comments a.af-icons-edit { top: 15px; left: 30px; }

    &.edit.judging-comments a.af-icons-edit { + div { padding-left: 0; } top: 10px; left: -17px; }

    &.new.submission-completed a.af-icons-edit { top: 15px; left: 30px; }

    &.edit.submission-completed a.af-icons-edit { + div { padding-left: 0; } top: 15px; left: -17px; }

    &.new.review-flow-page a.af-icons-edit { top: 10px; left: 30px; }

    &.edit.review-flow-page a.af-icons-edit { + div { padding-left: 0; } top: 10px; left: -17px; }

    &.new.judge-dashboard a.af-icons-edit,
    &.new.gallery-dashboard a.af-icons-edit { top: 30px; left: 30px; }
  }
}
