.inserter {
  position: relative;

  .toggle {
    visibility: hidden;
    position: relative;
    height: 24px;
    display: block;
    z-index: 1;

    .line {
      position: absolute;
      top: 12px;
      left: 16px;
      width: calc(100% - 16px);
      background: $colour-tertiary;
      height: 2px;
    }

    .icon {
      position: absolute;
      top: 5px;
      left: 0;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  &:hover .toggle,
  &.active .toggle {
    visibility: visible;

    .af-icons-add {
      color: $colour-tertiary;
    }
  }
}
