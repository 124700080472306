.footer-status-widget {
  text-align: center;
  line-height: 50px;
  padding-bottom: 15px;

  a.basic,
  a.basic:hover,
  a.basic:hover {
    color: inherit;
    text-decoration: none;
  }

  .basic {
    display: inline-block;

    .status-container {
      display: flex;
      align-items: baseline;
      height: 1.5rem;
      padding: 0.25rem;
    }

    .status-indicator {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #aaa;
      line-height: 1rem;
    }

    .none {
      background-color: #2ecc71;
    }

    .operational {
      background-color: #2ecc71;
    }

    .minor {
      background-color: #f1c40f;
    }

    .major {
      background-color: #e67e22;
    }

    .critical {
      background-color: #e74c3c;
    }

    .maintenance {
      background-color: #3498db;
    }

    .status-text {
      margin-left: 0.5rem;
      line-height: 1rem;
    }
  }
}

@media(min-width: $screen-md) {
  .footer-status-widget {
    padding-bottom: 0;
  }
}
