.comments-container {
  margin: 1em 0;

  .comment-new .af-icons-comment {
    font-size: $af-icons-base-size;
    line-height: 32px;
  }

  .comment-update,
  .comment-new {
    position: relative;
    .btn {
      margin-top: 15px;
    }
  }

  .uploaded-files {
    margin-top: 10px;
    margin-left: 40px;

    .upload-progress-container {
      display: none;
    }
  }

  @media (min-width: $screen-md) {
    .uploaded-files {
      width: 100%;
      max-width: 65vw;
    }
  }
}

.comment-new {
  .uploaded-files {
    margin-left: 0;
  }
}

.comment-container {
  margin: 1em 0;
  clear: both;

  .af-icons-comment {
    font-size: $af-icons-base-size;
    float: left;
    line-height: 18px;
  }

  .comment-counter {
    position: relative;

    .af-icons-comment {
      position: absolute;
      font-size: $af-icons-base-size;
      line-height: 32px;
    }

    .comment-count {
      position: absolute;
      text-align: center;
      width: 24px;
      text-decoration: none;
      line-height: 2.6;
      font-size: .8em;
    }
  }

  // Auto expand comment when it has content (this rule stops being applied having non-empty content)
  .comment-new .CodeMirror-empty {
    height: 50px;
    min-height: 50px;
  }

  .comment-text {
    border: 1px solid $colour-input-border;
    border-radius: $default-radius;
    padding: 8px;
    background-color: white;
    word-wrap: break-word;

    p img {
      max-width: 100%;
    }

    /*
     * Removing top/bottom margin from first/last element,
     * so that the content fits nicer into .comment-text
     * that defines its own padding
     */
    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .comment-meta {
    margin-top: .5em;
    font-size: 80%;
  }

  .comment-name {
    margin-left: 14px;
  }

  .comment-action {
    padding-left: 10px;
  }

  .comment-update,
  .comment-meta {
    form {
      display: inline;
    }
  }

  textarea {
    min-height: 200px;
    height: 200px;

    /*
     * Adding or removing .compact class will trigger a transition effect
     */
    @include transition(all .2s ease);
  }

  textarea.compact {
    min-height: 38px;
    height: 38px;
  }

  .preview > img {
    width: 100%;
  }

  .uploaded-files .card {
    max-width: 250px;
    min-width: auto;
    vertical-align: top;
    margin-right: 15px;
    padding: 0;
  }

  .comment-container {
    .file-upload-container {
      margin-bottom: 15px;

      &.card {
        box-shadow: none;
      }
    }

    .clearfix {
      clear: both;
    }

    .download {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  // End of: Comment uploads
}

.modal-body .uploaded-files {
  min-width: 100%;
  margin-top: 15px;
  margin-left: 0;

  .card {
    float: none;
  }

  .card .preview-container {
    .preview {
      color: inherit;
    }

    .icon {
      position: initial;
      margin-top: initial;
      padding: 20px 10px;
    }
  }
}

.comment-new,
.comment {
  margin-left: 40px;
  margin-top: .5em;
}

.criterion-comments .uploaded-files {
  width: auto;
}

.icon-comment-wrapper {
  position: absolute;
  margin-left: -40px;
}

.editor-with-images .editor-toolbar:before {
  display: none;
}

.editor-with-images .editor-toolbar:after {
  margin-top: 4px;
}

.editor-with-images.disabled span span {
  cursor: not-allowed !important;
}

.editor-preview.editor-preview-active {
  cursor: not-allowed;
}
