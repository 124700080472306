$base-card-width: 240px;

.attachments.cards {
  transition: height 0.3s;
}

.card {
  background-color: white;
  display: inline-block;
  min-width: $base-card-width;
  max-width: 100%;
  width: auto;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);

  input.form-control.date.no-type,
  input.form-control.datetime.no-type,
  select.form-control.timezones {
    width: 100%;
  }

  select.form-control.timezones {
    margin-top: 2px;
  }

  .card-header {
    text-align: right;
    padding: 10px;
    border-bottom: 1px solid $colour-border;
  }

  .preview-container:last-child {
    padding-bottom: 0;
  }

  .preview-container {
    position: relative;

    .preview {
      display: block;
      text-align: center;
      color: white;

      > img {
        display: inline-block;
        @include semitransparent-outline();
      }
    }

    .af-icons-preview {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 1.2em;
    }

    .icon {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      margin-top: -24px;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      .af-icons-md {
        font-size: $af-icons-md-base-size;
      }

      .attachment-type, .transcoding-status {
        font-size: 1.2em;
        line-height: 1.4;
      }
    }

    // cards without a preview need to have a minimum height and a solid background color
    &.no-preview {
      min-height: 140px;

      .preview {
        min-height: 140px;
        background-color: $colour-tertiary;
      }

      .af-icons-animate-rotate {
        width: auto;
        height: auto;
      }
    }
  }

  .transcoding-error {
    padding: 10px;
    background-color: $colour-error;
    color: white;

    .actions {
      width: 100%;
      text-align: right;

      a {
        color: white;
      }
    }
  }

  %card-section-border-bottom {
    border-bottom: 1px solid $colour-border;

    // avoid having a double border at the bottom of the card
    &:last-child {
      border-bottom: none;
    }
  }

  %card-section-anchor-block {
    a {
      display: block;
    }
  }

  %card-section-padding {
    padding: 10px;
  }

  .fields {
    @extend %card-section-border-bottom;

    .field {
      word-wrap: break-word;
      padding: 0 10px 10px 10px;
    }

    .field:first-child {
      padding-top: 10px;
    }

    .form-group {
      margin: 0;
    }
  }

  .metadata {
    @extend %card-section-border-bottom;
    @extend %card-section-anchor-block;
    @extend %card-section-padding;

    .af-icons-info {
      font-size: 1.2em;
      line-height: 1.5;
    }
  }

  .download {
    @extend %card-section-anchor-block;
    @extend %card-section-padding;

    .af-icons-download {
      font-size: 1.2em;
      line-height: 1.5;
    }

    .af-icons-link-open {
      font-size: 1.2em;
      line-height: 1.3;
    }

    .filename {
      position: relative;

      span {
        max-width: 90%;
        display: inline-block;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      i.af-icons {
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        padding: 0 1px;
      }
    }
  }

  .badge-wrapper {
    display: block;
    transform: translateY(-12px);
    margin-bottom: -15px;
  }

  a.award-badge {
    text-decoration: none;
    margin-bottom: 4px;
  }

  .award-badge {
    img {
      max-height: none;
      margin-bottom: 0;
    }

    .text {
      margin-bottom: 0;
    }
  }

  br {
    clear: both;
  }
}

ul.cards {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cards {
  .card {
    width: $base-card-width;
    float: left;

    // give cards right + bottom margins to prevent a jumpy effect while Isotope library rearranges the cards on page load
    margin: 0 20px 20px 0;

    .card-header {
      width: $base-card-width;
    }

    .preview-container {
      width: $base-card-width;
      max-width: 100%;
    }

    .preview {
      > img {
        width: auto;
        height: auto;
        max-width: $base-card-width;
      }
    }
  }
}

.cards.vertical-gallery {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;

  .card {
    box-shadow: none;
    margin: 0 0 20px;
    float: none;
    width: auto;
    max-width: 100%;

    &:has(> iframe) {
      width: 100%;
    }

    .preview-container {
      width: auto;
      height: auto;
      max-height: calc(100vh - 200px);
      max-width: 100%;

      .preview {
        > img {
          width: 100%;
          max-width: 100%;
          max-height: calc(100vh - 200px);
          object-fit: contain;
        }
      }
    }

    > .video-attachment-card {
      margin: 0 0 20px;
      width: 100%;
      box-shadow: none;
    }
  }
}

.action-card {
  width: 240px;
  float: left;
  background-color: transparent;
  text-align: center;
  padding: 30px 0;
  //border: 1px solid $colour-border;
  border: 2px dashed $colour-input-border;
  //border-radius: 2px;
  box-shadow: none;
}

.action-card.error {
  border-color: $colour-error;
}

.download.pdf-preview {
  margin: 10px 0;

  a > img {
    margin-right: 5px;
  }

  .help-text {
    margin-top: 10px;
  }
}

// overrides for single uploader displayed as a card
.cards {
  .upload-single.card {
    margin-bottom: 0;

    .upload-label {
      padding: 10px;
    }
  }
}

.field-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-preview-square {
  @extend .no-preview;
  min-height: 240px !important;
}

.card-separator {
  border-bottom: 1px solid $colour-border;
}

.video-attachment-card {
  width:100%;
  box-shadow: none;
}
