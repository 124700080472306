.columnator {
  width: 250px;
  margin-top: -15px;

  h4 {
    margin: 0 0 10px 0;
  }

  .actions {
    margin: 10px 0 0 0;

    .submit-action {
      margin-bottom: 5px;
    }
  }
}

.columnator-th-icon {
  padding-top: 0;
  text-align: center;

  .af-icons {
    font-size: 20px;
  }
}
