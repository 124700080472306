/*
|--------------------------------------------------------------------------
| Spacing
|--------------------------------------------------------------------------
|
| Simple margin/padding control over elements.
|
*/

// Configurable sizes.
$n: 0;
$s: 5px;
$m: 10px;
$l: 20px;
$x: 40px;

// Margin all.
.man { margin: $n; }

.mas { margin: $s; }

.mam { margin: $m; }

.mal { margin: $l; }

.max { margin: $x; }

// Padding all.
.pan { padding: $n; }

.pas { padding: $s; }

.pam { padding: $m; }

.pal { padding: $l; }

.pax { padding: $x; }

// Padding left-right.
.plrn { padding: 0 0; }

.plrs { padding: 0 $s; }

.plrm { padding: 0 $m; }

.plrl { padding: 0 $l; }

.plrx { padding: 0 $x; }

// Margin top.
.mtn { margin-top: $n; }

.mts { margin-top: $s; }

.mtm { margin-top: $m; }

.mtl { margin-top: $l; }

.mtx { margin-top: $x; }

// Margin bottom.
.mb-m { margin-bottom: -$m !important; }

.mb-15 { margin-bottom: -15px !important; }

.mbn { margin-bottom: $n; }

.mbs { margin-bottom: $s; }

.mbm { margin-bottom: $m; }

.mbl { margin-bottom: $l; }

.mbx { margin-bottom: $x; }

// Margin left.
.mln { margin-left: $n; }

.mls { margin-left: $s; }

.mlm { margin-left: $m; }

.mll { margin-left: $l; }

.mlx { margin-left: $x; }

// Margin right.
.mrn { margin-right: $n; }

.mrs { margin-right: $s; }

.mrm { margin-right: $m; }

.mrl { margin-right: $l; }

.mrx { margin-right: $x; }

// Margin horizontal (left + right)
.mx-30 { margin-left: -30px !important; margin-right: -30px !important; }

// Padding top.
.ptn { padding-top: $n; }

.pts { padding-top: $s; }

.ptm { padding-top: $m; }

.ptl { padding-top: $l; }

.ptx { padding-top: $x; }

// Padding bottom.
.pbn { padding-bottom: $n; }

.pbs { padding-bottom: $s; }

.pbm { padding-bottom: $m; }

.pbl { padding-bottom: $l; }

.pbx { padding-bottom: $x; }

// Padding left.
.pln { padding-left: $n; }

.pls { padding-left: $s; }

.plm { padding-left: $m; }

.pll { padding-left: $l; }

.plx { padding-left: $x; }

// Padding right.
.prn { padding-right: $n; }

.prs { padding-right: $s; }

.prm { padding-right: $m; }

.prl { padding-right: $l; }

.prx { padding-right: $x; }

// Padding horizontal (left + right)
.px30 { padding-left: 30px; padding-right: 30px; }

.t-0{
  top: 0 !important;
}
