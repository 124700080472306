// Scaffolding ================================================================

@mixin hyperlink-focus() {
  // Default
  outline: 0;
  // WebKit
  outline: 5px auto $link-hover-color;;
  outline-offset: -2px;
}

a {
  cursor: pointer;
  text-decoration: underline;

  // Disable dotted outline on FF/IE after a link is clicked
  outline: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: solid underline $link-hover-color 2px;
  }

  &:focus {
    @include hyperlink-focus()
  }
}

// Navbar =====================================================================

.navbar {
  border-width: 0;

  &-default {
    .badge {
      background-color: #fff;
      color: $navbar-default-bg;
    }
  }

  &-inverse {
    .badge {
      background-color: #fff;
      color: $navbar-inverse-bg;
    }
  }

  &-brand {
    line-height: 1;
  }

  .navbar-toggle {
    border: 0;

    .icon-bar {
      background: $navigation-text;
    }

    &:focus {
      @include focus-outline(#ffffff);
      border-radius: 0;
    }

    &:hover {
      .icon-bar {
        background: $navigation-text-hover;
      }
    }
  }
}

.navbar-header {
  @media (min-width: $grid-float-breakpoint) {
    float: none;
  }
}

.navbar-collapse {
  @include box-shadow(none);
  border-top: 0;
}

.navbar-form {
  .form-control {
    border-width: 0;
  }
}

// Buttons ====================================================================
.btn {
  @include transition( background $transition-speed );
  display: inline-flex;
  align-items: center;
  padding: 7px 20px;
  font-weight: 400;
  border: 0;
  border-radius: $border-radius-base;
  white-space: normal;
  text-decoration: none;

  .af-icons {
    vertical-align: middle;
  }

  .af-icons-cross,
  .af-icons-tick {
    vertical-align: baseline;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: $colour-disabled !important;

    // Disabled buttons don't need the 'pointer-events' property - events are blocked anyhow
    pointer-events: auto;
  }
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    // Anchor elements with .disabled class need the 'pointer-events' property for events to be blocked
    pointer-events: none;
  }
}

/* Buttons placed right next to each other need more spacing */
button, a.btn, .btn {
  margin-right: 5px;
}

input.btn {
  width: auto;
}

.btn-lg {
  font-size: 1.285em;
  line-height: 1.2em;
}

.btn-md {
  font-size: 1.1em;
  line-height: 1.2em;
}

.btn-sm {
  font-size: 1em;
  line-height: 1.2em;
}

.btn-xs {
  font-size: .85em;
  line-height: 1.2em;
}

.btn-secondary {
  @include button-variant(#ffffff, $colour-secondary, $colour-secondary);
}

.btn-tertiary {
  @include button-variant(#ffffff, $colour-tertiary, $colour-tertiary);
}

.btn.btn-light {
  background-color: #ffffff;
  color: $colour-link;
  border: 1px solid $colour-border;
  padding: 0 10px;
  white-space: nowrap;
  @include transition(all 0.25s);

  * {
    color: $colour-link;
  }

  .af-icons {
    font-size: 1.65em;
    line-height: 1.4em;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $colour-primary;
    color: #ffffff;
    text-decoration: none;
    border-color: $colour-primary;

    * {
      color: #ffffff;
    }
  }
}

.btn-link {
  &:focus,
  &.focus,
  &:active:focus,
  &:active.focus,
  &.active:focus,

  &[disabled],
  &.disabled {
    background-color: transparent !important;

    &:hover {
      color: inherit;
    }
  }
}

@media (max-width: 1199px) {
  .btn.btn-light {
    max-width: 50vw;
    height: auto;
    padding: 5px 10px;
    white-space: normal;

    .af-icons {
      line-height: 0.4em;
      margin-top: -2px;
    }
  }
}

// Labels =====================================================================

.label {
  padding: .1em .6em .15em;
  font-size: 90%;
  line-height: 1.8;
  font-weight: normal;
  border-radius: $border-radius-small;
}

// Pagination =================================================================

.pagination {
  margin: 0;

  li.disabled {
    opacity: 1;
  }
}

// Typography =================================================================

p {
  margin: 1em 0px;
}

ul, ol {
  margin-bottom: 1em;
}

blockquote {
  margin: 0 0 1em;

  p {
    margin-top: 0;
  }
}

// Tables =====================================================================

// Tabs =======================================================================

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border: none;
}

.nav-tabs > li > a {
  border: none;
  margin-right: 0;
}

// Forms ======================================================================

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 2px solid $color;
  }
}

.form-control {
  @include form-control-focus()
}

label {
  font-weight: normal;
  & + .vue-tooltip-icon {
    margin-left: 2px;
  }
}

.form-control,
input {
  border-width: 1px;
  padding: 7px 8px;
}

.input-sm {
  padding: 5px;
}

.has-warning {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-warning;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-warning;
  }

  .input-group-addon {
    border-color: $brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-danger;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-danger;
  }

  .input-group-addon {
    border-color: $brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-success;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-success;
  }

  .input-group-addon {
    border-color: $brand-success;
  }
}

.input-group {
  margin-bottom: 5px;

  .input-group-addon {
    color: white;
    min-width: 60px;
    font-size: 11px;

    &.input-group-addon-lg {
      font-size: 14px;
    }
  }
}

.form-inline .form-group label {
  display: inline;
}

// Popovers ===================================================================

.popover {
  @include box-shadow(none);

  padding: 0;
}

.popover-content {
  padding: 10px;
}

.popover.right {
  margin-left: 15px;
}

.popover.left {
  margin-left: -15px;
}

.popover.top {
  margin-top: -15px;
}

.popover.bottom {
  margin-top: 15px;
}

// Modals =====================================================================

.modal-footer {
  padding: 15px 20px;
  text-align: left;

  .modal-close {

    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Containers =================================================================

.well {
  @include box-shadow(none);
}

.row-relative {
   position: relative;
   overflow: hidden;
}

// Tables ====================================================================

.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        vertical-align: middle;
      }
    }
  }
}

// Thumbnails ================================================================

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: $thumbnail-border;
}
