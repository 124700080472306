div[class^='alert-'] {
  border-radius: $default-radius;
  border: 0;
  cursor: pointer;
  overflow: hidden;
  padding: 23px;
  display: table;
  width: 100%;

  &.sticky { cursor: default; }

  &.inline {
    margin: 10px 0;
    padding: 5px;
    background: transparent;
  }

  .icon {
    display: table-cell;
    width: $af-icons-md-base-size;
    font-size: $af-icons-md-base-size;
  }

  .message {
    display: table-cell;
    vertical-align: middle;

    @include text-content;
  }

  .icon + .message {
    padding-left: 20px;
  }

  p:last-child, ul:last-child {
    margin-bottom: .5em;
  }
}


// A list of array types followed by their colour so that we can loop through them and
// perform magic.
$alert-types:
  warning $colour-warning $colour-warning-text,
  error   $colour-error $colour-error-text;

@for $i from 1 through length($alert-types) {
  $type-list: nth($alert-types, $i);
  $type: nth($type-list, 1);
  $colour: nth($type-list, 2);
  $text-colour: nth($type-list, 3);

  .alert-#{$type} {
    @include new-alert($colour, $text-colour, $i - 1);
  }
}

