.context-menu-header{
  display: none;
  position: relative;
}
.context-menu {
  color: #FFFFFF;
  ul {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
    margin: 0;
    li {
        height: 100%;
        list-style: none;
        margin:0;
      a.context-menu-link {
          position: relative;
          color: #FFFFFF;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
          background-repeat:no-repeat;
          border: none;
          padding: 0 15px;
          margin: 0;
          text-decoration: none !important;
          .context-menu-text {
              color: #FFFFFF;
          }
          &:hover,&:focus-visible {
              background-color: $navigation-active;
              text-decoration: none;
              .context-menu-text {
                  color: $navigation-text-active;
              }
          }
          &:focus-visible {
              outline: transparent none;
          }
      }
      &:only-child {
        display: none;
      }
      &.selected .highlight {
        position: absolute;
        background-color: $navigation-active;
        top: 0px;
        height: 5px;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        .vertical-line {
          height: 10px;
          width: 1px;
          opacity: 0.5;
          background-color: #FFFFFF;
        }
      }
    }
  }
}


.context-menu-sidebar-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: $navbar-height;
  background-color: $navbar-default-bg;
  .context-menu-sidebar-container {
    position: relative;
    width: 100%;
    height: 100%;
    .context-menu-divider {
      position: relative;
      height: 1px;
      width: 100%;
      background-color: #FFFFFF;
      opacity: 0.5;
    }
    .context-menu {
      ul {
        height: 100%;
        li {
          height: $navbar-height;
        }
        li a.context-menu-link:hover{
          background-color: inherit;
            .context-menu-text{
                color: inherit;
            }
        }
      }
    }
  }

}

@media screen and (min-width: $screen-sm-min) {
  .context-menu-sidebar-wrapper {
    display: none;
  }
  .context-menu-header{
    display: flex;
  }
}
