.judging-content {
  padding: 10px;
  background: white;
  border-radius: $default-radius;
  word-wrap: break-word;
  border-left: 3px solid #ddd;
  margin-bottom: 10px;
  width: 100%;

  .resource-value {
    margin-top: 0;
  }
}

.judging-attachments {
  padding-top: 20px;
}

.judging-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  margin-bottom: 20px;

  .judging-header-info {
    min-width: 300px;
    margin: 10px 20px 0 0;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    button, a.btn, .btn {
      margin-right: 0;
    }
  }
}

@media (max-width: $screen-md-min) {
  .judging-header {
    flex-direction: column;
    align-items: unset;

    .judging-header-info {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: unset;
    }
  }
}

.top-pick-preference {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  line-height: 30px;
  text-align: center;
  background-color: $colour-tertiary;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;

  &.selected {
    background-color: #6f6f6f;
  }

  &:hover {
    background-color: $colour-primary;
    text-decoration: none;
  }

  &.active {
    background-color: $colour-secondary;
  }

  &.saving {
    background-color: $colour-quaternary;
  }
}

.top-pick-wrapper, .judge-wrapper {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.top-pick-large {
  .top-pick-remove {
    padding: 10px 0;
  }

  .top-pick-preference {
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 150%;
  }
}

.gallery-next-button {
  text-align: right;
}

.url-field-container .af-icons-link-open {
  font-size: 12px;
  margin-right: 6px;
}

.url-field-embed {
  max-width: 800px;

  &--is-preview {
    max-width: 640px;
  }

  &__video-iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    display: block;
  }

  iframe.url-field-embed__video-iframe--tiktok {
    height: 738px !important;
    width: 325px !important;
  }

  iframe.url-field-embed__video-iframe--instagram {
    min-height: 600px;
    width: 325px;
  }
}

.gallery-from-file-fields {
  padding-top: 20px;

  img {
    display: block;
    max-height: 140px;
    margin: 0 20px 20px 0;
    float: left;
    @include semitransparent-outline();
  }
}

.duplicates-af-icons-link-open{
  @extend .af-icons-link-open;
  font-size: 120%;
}

.duplicates-panel-title{
  margin-top: 15px;
  position: relative;
  padding-left: 8px;
}

.duplicates-title{
  @extend .title;
  position: relative;
  top: 8px;
}

.duplicate-panel{
  @extend .panel;
  @extend .panel-default;
}

.judging-comments {
  display: inline-flex;
  align-items: center;

  .judging-comments-button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: $link-color;

    &:focus, &:active, &:hover {
      color: $link-hover-color
    }

    .af-icons-comments {
      font-size: 20px;
    }
  }
}
