$action-dropdown-icon-size: 16px;
$action-dropdown-v-padding: 7px;
$action-dropdown-h-padding: 10px;

.dropdown-light {
  display: inline-block;

  .link-selector {
    border-top: 3px solid $colour-link;
    border-radius: 0;
    color: $colour-link;
    background-color: transparent;
    padding: 7px 10px;
    @include transition(all $transition-speed);

    &:hover {
      color: #FFF;
      background-color: $colour-link;
      border-radius: $default-radius;
    }

    &:focus, &:focus-visible, &:focus-within {
      color: #FFF;
      background-color: $colour-link;
      border-radius: $default-radius;
      outline: 3px solid $colour-link;
      outline-offset: 2px;
      text-decoration: none;
    }
  }

    .dropdown-menu {
      left: 10px;
      margin-top: 8px;
      color: $colour-link;
  }
}

.dropdown-light.open {
  .dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
  }

  .link-selector {
    color: #FFF;
    background-color: $colour-link;
    border-radius: $default-radius;
    outline: 3px solid $colour-link;
    outline-offset: 2px;
    text-decoration: none;
  }
}

.selector-title {
  .link-selector {
    display: inline-block;
    margin: 0 10px;
  }
}
