div.pagination-nav {
  overflow: hidden;
  margin: 20px 0;
  width: 100%;

  .select2-container {
    float: right;
    margin-left: 25px;
    width: 70px;
  }

  ul {
    float: right;
    position: relative;
  }
}

.per-page {
  width: auto;
  float: right;
  margin-left: 70px;
}
