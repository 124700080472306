/* Field labels need to be displayed inline to align properly
    with help icons */

.field-label {
  margin-bottom: 5px;
  vertical-align: middle;

  /* The labels have a boosted font-size / line-height */
  > label {
    display: inline;

    br {
      display: block;
      margin-top: 10px;
      content: '';
    }
  }

  > .vue-tooltip-icon {
    top: 3px;
  }

  .af-icons {
    font-size: $font-size-base;
  }

  /* These icons appear at the beginning of a line - let's keep some space between the icon
        and the text that follows */
  .af-icons-read-off,
  .af-icons-write-off {
    padding-right: 3px;
  }

  span.optional-field {
    vertical-align: baseline;
  }
}

/* This class is used together with '.field-label' to cover the special case
    of checkbox labels */

.field-label-checkbox {
  margin-bottom: 0;
  padding-left: 20px;

  > label {
    padding-left: 0;
  }

  .af-icons-lock,
  .af-icons-read-off,
  .af-icons-write-off {
    padding-left: 3px;
  }
}

.card .field-label label {
  font-size: 1em;
}

.app {
  .field-label {
    label:first-child {
      font-size: 16px;
      line-height: 1.4;
    }

    display: flex;
    align-items: flex-start;
    flex-direction: row;

    .counters {
      margin-left: auto;
    }
  }

  .field-container {
    .field-content {
      background: white;
      border: 1px solid #ddd;
      border-radius: $border-radius-base;
      width: 100%;
      padding: 15px 15px 0 15px;

      &.configuring {
        @include configuring;
      }
    }

    .field-hint-text {
      width: 100%;
    }
    iframe.url-field-embed__video-iframe--tiktok {
      height: 738px;
      width: 325px;
    }
  }

  .field-container-has-hint-text {
    .field-content {
      border-radius: $border-radius-base $border-radius-base 0 0;
    }

    .field-hint-text {
      border-radius: 0 0 $border-radius-base $border-radius-base;

      background-color: #efefef;
      border: 1px solid #efefef;
      border-top: none;

      .hint-text {
        padding: 15px;

        p {
          margin: 0 0 10px 0;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (min-width: $screen-md) {

    /* Equal-height elements - because of very good browser support
            we are going to use a table layout here that can be replaced
            with flexbox layout in the future. */

    .field-container {
      display: table;
      width: 100%;

      .field-wrapper {
        display: table-row;
        width: 100%;

        .field-content {
          width: 50%;
          display: table-cell;
        }

        .field-hint-text {
          width: 50%;
          display: table-cell;
          vertical-align: top;
        }
      }
    }

    .field-container-has-hint-text:not(.field-container-full-width) {
      .field-content {
        border-radius: $border-radius-base 0 0 $border-radius-base;
      }

      .field-hint-text {
        border-radius: 0 $border-radius-base $border-radius-base 0;
      }
    }

    /* On Details tab, use 100% of the space if the field has no hint text */
    #detailsTab {
      .field-content {
        width: 100%;
        display: table-cell;
      }

      .field-container-has-hint-text:not(.field-container-full-width) {
        .field-content {
          width: 50%;
          display: table-cell;
        }

        .field-hint-text {
          width: 50%;
          display: table-cell;
        }
      }
    }
  }

  .field-container-checkbox {
    .checkbox {
      margin-top: 0;
      margin-right: 30px;
    }
  }

  /* Make an exception for 'content' fields - reset styling */

  .field-container-content {
    .field-content {
      background: transparent;
      border: none;
      padding: 0;

      label {
        p {
          display: block;
        }
      }
    }
  }

  /* Special treatment for single file uploads */

  .field-container-file {
    .upload-standalone {
      width: 100%;
      border: none;
      margin: 0;

      .preview-container {
        max-height: none;
        overflow: visible;
        background-color: $colour-tertiary;
        width: 100%;
        padding-bottom: 0;

        .preview img {
          max-width: 100%;
          width: auto;
          height: auto;
        }

        &.audio {
          cursor: auto;
        }
      }

      .upload-details {
        width: 100%;
        border: none;
        padding: 10px 10px;
      }

      .upload-progress-container {
        background-color: #efefef;
        position: relative;
        left: -1px;

        .upload-cancel {
          border: none;
          padding: 10px 10px;
        }
      }

      .download {
        border: none;
      }

      .fields {
        display: none;
      }
    }
  }

  /* Sometimes it is necessary to force the field container to take
        100% width of the parent element (on the login page for example) */

  .field-container-full-width {
    display: block;

    .field-wrapper {
      display: block;

      .field-content, .field-hint-text {
        display: block;
        width: 100%;
      }
    }
  }
}

.form-group-subordinate {
  padding-left: 20px;
}

/* Make the slider fit nicely into the scoring criterion preview box */

@media (min-width: $screen-md-min) {
  .scoring-criteria-form .preview .slider {
    width: 105px;
  }
}

@media (min-width: 1600px) {
  .scoring-criteria-form .preview .slider {
    width: 210px;
  }
}

.role-access-settings {
  border: 1px solid $colour-input-border;
  background-color: #fff;
  border-radius: 2px;
  clear: both;
  height: 200px;
  overflow-x: auto;
  overflow-y: auto;

  .table {
    margin-bottom: 0;

    tbody > tr > td {
      .checkbox {
        margin-bottom: 0;
      }
    }
  }
}

.formula-field-wrapper {
  position: relative;

  .popover-wrap {
    width: 100%;
  }

  .formula-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;

    i {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;
    }
  }

  .formula-field {
    padding-left: 35px;
    border-style: dotted;
  }
}
