// Headings.
%headings-shared {
  color: $font-primary-colour;

  &.first { margin-top: 0; }

  &.collapse { margin: 0 }
}

h1, .h1 {
  @extend %headings-shared;
  font: 300 30px/40px $font-primary;
  letter-spacing: -0.025em;
  margin-top: 20px;
  margin-bottom: 10px;
}

h2, .h2 {
  @extend %headings-shared;
  font: 300 24px/34px $font-primary;
  letter-spacing: -0.04em;
  margin-top: 20px;
  margin-bottom: 10px;
}

h3, .h3 {
  @extend %headings-shared;
  font: 300 22px/1.4 $font-primary;
  letter-spacing: -0.01em;
  margin-top: 20px;
  margin-bottom: 10px;
}

h4, .h4 {
  @extend %headings-shared;
  font: 600 17px/1.4 $font-primary;
  letter-spacing: -0.01em;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5, .h5 {
  @extend %headings-shared;
  font: 600 15px/1.4 $font-primary;
  margin-top: 10px;
  margin-bottom: 10px;
}

h6, .h6 {
  @extend %headings-shared;
  font: 700 12px/1.4 $font-primary;
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
.h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  @include undecorated();
}

// Paragraphs.
p {}

// Lists.
ul, ol {
  padding-left: 25px;
}

li {}

.strong {
  font-weight: bold;
}
