.multiselect {
  position: relative;

  .multiselect-filter {
    margin-bottom: 4px;
  }

  .multiselect-toggles {
    padding: 0 9px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .multiselect-selectall {
      width: 45%;
      float: left;
    }

    .multiselect-subset {
      width: 45%;
      float: right;
      text-align: right;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .checkbox + .checkbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .multiselect-options {
    border: 1px solid $colour-input-border;
    border-radius: $default-radius;
    clear: both;
    height: 202px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;

    .multiselect-option {
      border-bottom: 1px solid $colour-border;
      padding: 8px;
      margin: 0;

      &.header {
        font-weight: bold;
      }

      .multiselect-subset-control {
        float: right;
      }

      label {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .multiselect-option-active {
      background-color: transparentize($colour-secondary, 0.9);
    }
  }

  .multiselect-counter {
    float: right;
    margin: 0 0 5px 0;
    height: 38px;
    min-width: 40px;
    display: inline-block;
    padding: 5px 10px;
    background: $font-primary-colour;
    border-radius: $default-radius;
    color: #fff;
    font: 400 18px/26px $font-primary;
    text-align: center;
  }

  .randomizer-selector {
    clear: both;
    margin-bottom: 5px;

    input[type='text'] {
      height: 32px;
    }

    .form-control {
      display: inline;
      width: 50px;
    }
  }
}
