//
// Modified from http://flatlogic.github.io/awesome-bootstrap-checkbox/
// --------------------------------------------------

$font-family-icon: 'af-icons';
$check-icon: '\e038';

.checkbox label:after {
  padding-left: 4px;
  padding-top: 2px;
  font-size: 9px;
}

//
// Checkboxes
// --------------------------------------------------

.checkbox.styled, .radio.styled {
  label{
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-base;

    &:empty{
      cursor: default;
    }

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      margin-top: 1px;
      border: 1px solid $input-border;
      border-radius: 2px;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 1px;
      top: 0;
      margin-top: -1px;
      padding-left: 1px;
      padding-top: 0;
      font-size: 14px;
      color: $input-color;
      line-height: 1.4;
    }

    span {
      a {
        position: relative;
        z-index: 1;
      }
    }
  }

  input[type='checkbox'],
  input[type=checkbox]:focus,
  input[type='radio'],
  input[type=radio]:focus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus + label::before{
      border-color: #000;
      outline: 2px solid;
    }

    &:checked + label::after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &.primary-selector:indeterminate:not([checked='checked']) + label::after{
      display: block;
      content: '';
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled{
      cursor: not-allowed;
    }

    &:disabled + label{
      &::before{
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

    &:disabled + label *:not(.solid) {
      opacity: 0.65;
    }
  }

  &.checkbox-circle label::before{
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }
}

td > .checkbox {
  text-align: center;
  margin-top: 0;
}

//
// Radios
// --------------------------------------------------

.radio.styled{
  padding-left: 20px;

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0px;
      top: 2px;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 50%;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      content: ' ';
      width: 10px;
      height: 10px;
      left: 4px;
      top: 8px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $colour-secondary;
      @include scale(0, 0);

      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }

    span {
      a {
        position: relative;
        z-index: 1;
      }
    }
  }

  input[type='radio']{
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:checked + label::after,
    &.primary-selector[checked='checked']:not(form input) + label::after{
      @include scale(1, 1);
    }

    &:disabled{
      cursor: not-allowed;
    }

    &:disabled + label {
      &::before{
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

    &:disabled + label *:not(.solid) {
      opacity: 0.65;
    }
  }

  &.radio-inline{
    margin-top: 0;
  }
}

input[type='checkbox'],
input[type='radio'] {
  &.styled:checked + label:after,
  &.primary-selector[checked='checked']:not(form input) + label:after{
    font-family: $font-family-icon;
    content: $check-icon;
  }

  .styled:checked + label {
    &::before {
      color: #fff;
    }

    &::after {
      color: #fff;
    }
  }
}
input[type='radio']{
  &:checked + label::after,
  &.primary-selector[checked='checked']:not(form input) + label::after{
    content: '' !important;
  }

  &:disabled + label::after{
    background-color: #a3a3a3;
  }
}

.multiselect{

  .styled:not(.multiselect-selectall)  {
    padding-left: 0px;
    margin: 0px;
    input[type='radio'] + label{
      height: 25px;
    }

    input[type='checkbox'], input[type='radio']  {
      margin-left:5px;
      &.hidden + label {
        display: none !important;
      }
    }
    label{
      padding-left: 25px;
      &::before{
        margin-left:0px;
      }
      &::after{
        margin-left:0px;
      }
    }
  }
  .radio.styled{
    label::before{
      left: 3px;
    }
    label::after{
      left: 7px;
    }
  }
}

//Media to support radio button on WHCM
@media (forced-colors: active) {
  .radio.styled label{
    &::after{
      background-color: canvasText !important;
    }
  }
}

// User -> Roles

.matrix .permission-action > .radio input[type='radio'] {
  margin-top: 6px;
  margin-left: -17px;
}
