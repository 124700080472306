@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.social-auth {
  h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .btn {
    margin-bottom: 5px;
  }

  .btn-google {
    color: #ffffff;
    background-color: #4285F4;
    border-color: #e5e5e5;
    padding: 0;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;

    .btn-google-container {
      display: flex;
      align-items: center;

      .google-logo {
        height: 36px;
        padding: 2px;
      }

      .google-text {
        margin-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .btn-facebook {
    color: #ffffff;
    background-color: #0866FF;
    border-color: #0866FF;
    min-height: 36px;
    padding: 8px 15px;
  }

  .btn-twitter {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
    min-height: 36px;
    padding: 8px 15px;
  }

  .btn-linkedin {
    color: #ffffff;
    background-color: #0A66C2;
    border-color: #0A66C2;
    min-height: 36px;
    padding: 8px 15px;
  }
}
