.marker-action-tagger {
  .tags-input {
    width: 300px;
    float: left;
  }
}

.marker-action-reviewer {
  select {
    width: 300px;
    float: left;
  }
}

.marker-action-contract-creator {
  select[name="contentBlocks"] {
    width: 300px;
    float: left;
  }
}

.marker-action-schedule-report {
  select[name="reportForms"] {
    width: 300px;
    float: left;
  }
}

.marker-action-tagger,
.marker-action-reviewer,
.marker-action-contract-creator, .marker-action-schedule-report {
  .btn {
    margin-left: .5em;
  }
}
