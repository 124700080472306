#footer {
  display: block;

  .logo {
    height: 220px;
    display: block;

    a {
      line-height: 0; /* Used to hide logo anchor text */
      font-size: 0; /* Used to hide logo anchor text */
      color: transparent; /* Used to hide logo anchor text */
    }
  }
}

.resizable-footer {
  // Style the bottom middle and top middle handles
  .handle-bm,
  .handle-tm {
    cursor: row-resize;
  }
}
