.cookie-notice {
  display: none;
  background-color: $colour-form-box-background;
  width: 100%;

  &-icon {
    display: block;
    .af-icons-lg-cookie {
      font-size: 96px;
    }
  }

  .container {
    @media screen and (min-width: $screen-md-min) {
      display: flex;
      justify-content: center;
    }
  }

  .cookie-buttons-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    button {
      margin-bottom: 5px;
    }
  }

  .cookie-selection {
    display: none;
    margin-top: 10px;
    outline: none;
    flex-basis: 100%;
  }

  .cookie-types {
    display: flex;
    flex-direction: column;
  }

  .cookie-checkbox {
    outline: 0;
    // prevents :hover issue with popover()
    input:disabled {
      pointer-events: none;
    }
  }

  .cookie-notice-icon {
    margin-top: 20px;
  }

  @media screen and (min-width: $screen-sm-min) {
    .cookie-notice-icon {
      text-align: right;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    .cookie-types {
      flex-direction: row;
      align-items: center;

      .checkbox {
        margin: 10px 20px 10px 0;
      }
    }

    .cookie-buttons-list {
      flex-direction: row;
      button {
        margin-bottom: 0;
      }
    }
  }
}
