$judging-progress-bar-bg: $colour-secondary;

/**
 * Special progress bar with column-split content used in judging views.
 */
.judging-progress-bar {
  overflow: visible;
  height: 30px;
  background-color: #ddd;
  border-radius: 0;
  box-shadow: none;

  .progress-bar {
    padding-top: 3px;
    box-shadow: none;
  }

  .progress-bar-info {
    @include progress-bar-variant($judging-progress-bar-bg);
    color: black;
  }

  .row {
    float: left;
    display: inline-block;
    width: 100%;
    margin-top: -25px;
    margin-left: 0;
    margin-right: 0;

    .col-right {
      text-align: right;
    }
  }
}
