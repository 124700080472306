#header {
  min-height: 150px;
  position: relative;
  overflow: hidden;
  z-index: 3;

  .logo {
    display: block;
    margin: 0;
    width: 100%;
    text-indent: -10000em;
  }
}

// Splash-page specific styles
.splash {
  #header {
    .logo {
      background: transparent no-repeat center;
      height: 220px;
    }
  }
}
