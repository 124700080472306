table {
  a {
    @include undecorated();
  }

  th, td {
    // Specify font-size in pixels so retarded IE can render the font size correctly.
    font: 400 14px/1.2em $font-primary;
    vertical-align: top;
  }

  // Vertical alignment.
  &.middle,
  &.align-middle {
    th, td { vertical-align: middle; }
  }

  &.text-right {
    th, td { text-align: right; }
  }

  thead {
    $header-colour: lighten($font-primary-colour, 6);

    tr {
      color: $header-colour;
    }

    th {
      // Horizontal alignment
      &.center {
        text-align: center;
      }
    }

    a {
      color: $header-colour;

      &:hover { color: #999; }

      // Sorting styles.
      &.sortable,
      &[class^='sort-'] {
        @include transition( all $transition-speed );
        background: url('../img/sort-icons.png') no-repeat;
        background-position: left -100px; // RTL converter support - it'll flip this but not background.
        padding-left: 17px;
      }

      &.sort-desc { background-position: left 4px; }

      &.sort-asc  { background-position: left -47px; }
    }
  }

  .totals { font-weight: bold; }

  tr.totals td {
    font-weight: bold;
    font-size: 17px;
  }

  tr.totals-row td {
    border-width: 2px;
    font-weight: bold;
  }

  .overflow-cell {
    width: 20px;
  }
}

table {
  tbody {
    tr {
      td {
        color: lighten($font-primary-colour, 6);
        &.dragger {
          padding: 18px 0 15px;
        }

        a {
          color: $colour-link;
          text-decoration: none;

          &:hover {
            color: $colour-link-hover;
            text-decoration: underline;
          }

          &:focus,
          &:active {
            color: $colour-link-active;
            outline: 2px solid $colour-link-active;
            outline-offset: 2px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.table {
  .af-icons-comments,
  .af-icons-feedback {
    font-size: 20px;
  }

  .af-icons-slideshow,
  .af-icons-action-overflow {
    font-size: $af-icons-base-size;
  }

  .af-icons-pdf {
    font-size: $af-icons-base-size;
    color: $colour-pdf;

    &:hover {
      color: lighten($colour-pdf, 15%);
    }
  }

  .view-simple-modal {
    .af-icons-comments {
      font-size: 20px;
    }
  }

  span {
    &:focus, &:focus-visible {
      @include focus-outline(#333);
      border-radius: 0;
    }
  }
}

tr.row-inactive td:nth-child(n+2) {
  opacity: 0.4;
}
