/** @CKEditor */
// Assert that the embedded media is playable (https://github.com/ckeditor/ckeditor5/issues/2773).
// It can also be archived by setting `editor.isReadOnly = false`,
// but I can't see that working with the current implementation of the entry fields.
*[contenteditable='true'] .ck-media__wrapper > *:not(.ck-media__placeholder) {
  pointer-events: initial !important;
}

/** @SimpleMde */
// cm-s-paper CodeMirror-wrap
.CodeMirror {
  border: 1px solid $colour-input-border;
  border-radius: $default-radius;
  clear: both;
  color: $text-color;
  min-height: 125px;

  .CodeMirror-scroll {
    min-height: inherit;

    .CodeMirror-sizer {
      border-right: 0;
      margin-bottom: 0 !important;
    }

    .CodeMirror-lines, > div {
      margin-right: 15px;
    }
  }

  .CodeMirror-vscrollbar {
    display: none !important;
  }

  .CodeMirror-code {
    .cm-header {
      font-weight: normal;
      line-height: 160%;

      &.cm-strong {
        font-weight: bold;
      }
    }

    .cm-header-1 {
      font-size: 30px;
    }

    .cm-header-2 {
      font-size: 24px;
    }

    .cm-header-3 {
      font-size: 18px;
    }

    .cm-header-4 {
      font-size: 16px;
    }
  }
}

.CodeMirror[disabled] .CodeMirror-lines {
  cursor: not-allowed;
}

.CodeMirror-wrap pre, .editor-preview p {
  word-break: break-word;
}

.editor-toolbar, .editor-toolbar:hover {
  background-color: #fafafa;
  border-color: $colour-input-border;
  border-top-left-radius: $default-radius;
  border-top-right-radius: $default-radius;
  clear: both;
  opacity: 1;

  a {
    color: $colour-input-border !important;
  }

  a.markdown-guide {
    border: 0;
    display: inline;
    padding-left: 10px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &.disabled-for-preview > a:not(.no-disable) {
    background: #fafafa;
  }
}

.editor-toolbar + .CodeMirror {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.input-group .CodeMirror,
.input-group > .mde ~ .CodeMirror {
  border-bottom-left-radius: 0;
}

.input-group .CodeMirror,
.input-group > .mde ~ .editor-toolbar {
  border-top-left-radius: 0;
}

.error .mde ~ .CodeMirror {
  border-color: $colour-error-hover;
}

.CodeMirror.cm-s-paper:focus, .CodeMirror.cm-s-paper:focus-within, .CodeMirror.cm-s-paper:focus-visible {
  clip-path: none !important;
  outline: 2px solid $colour-input-border-focus;
}

.input-group > div > .editor-toolbar {
  border-top-left-radius: 0;
}

.input-group > div > .editor-toolbar + .CodeMirror {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
}
