$exports-icon-size: 16px;
$exports-v-padding: 7px;
$exports-h-padding: 10px;

.draggable-columns {
  .column {
    .name {
      .remove {
        margin-top: 3px;
      }

      .handle-af,
      .remove {
        visibility: hidden;
        font-size: $exports-icon-size;
        vertical-align: middle;
      }

      &.hover {
        cursor: move;

        .handle-af,
        .remove {
          visibility: visible;
        }
      }
    }
  }
  
  .draggable-item.draggable-option:focus-visible {
    outline: 2px solid #333;
    outline-offset: -2px;
  }

  .draggable-item.dragging,
  .draggable-item.dragging:focus-visible {
    outline: 2px solid $colour-secondary;
    outline-offset: -2px;
    background-color: rgba($colour-secondary, 0.1);
  }
}

.column-selector {
  position: absolute;
  top: -40px;
  left: 20px;
  background: #ffffff;
  border: 1px solid $colour-tertiary;
  border-radius: $default-radius;
  min-width: 250px;
  z-index: 2;

  input[type='text'] {
    border: none;
    border-bottom: 1px solid $colour-tertiary;
    width: 100%;
  }

  .list {
    max-height: 180px;
    overflow-y: auto;
  }

  .list-empty {
    padding: $exports-v-padding $exports-h-padding;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      display: inline-block;
      padding: $exports-v-padding $exports-h-padding;
      width: 100%;
      text-decoration: none;

      &:hover {
        color: $colour-tertiary;
        background: $table-bg-hover;
      }
    }
  }
}
