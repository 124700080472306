.primary-button-color {
  color: #fff;
  background-color: $colour-primary
}

.secondary-button-color {
  color: #fff;
  background-color: $colour-secondary
}

.tertiary-button-color {
  color: #fff;
  background-color: $colour-tertiary
}
