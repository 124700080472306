.breadcrumbs-list {
  display: inline-block;
}

.breadcrumbs-list ul {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.breadcrumbs-list ul > li {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.breadcrumbs-compact ul > li {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 20px;
}
