.btn-api-key-show, .btn-api-key-hide {
  margin-left: 15px;
  text-decoration: underline;
}

.btn-api-key-copy {
  text-decoration: underline;
}

.lbl-api-key-copy .btn-api-key-copy {
  margin-left: 5px;
}

.form-group .btn-api-key-show, .form-group .btn-api-key-hide {
  margin-left: unset;
}

.lbl-api-key-show, .lbl-api-key-hide, .lbl-api-key-copy {
  float: right;
}

.api-key-masked, .api-key-full {
  word-break: break-all;
}
