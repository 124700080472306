.box {
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border: 1px solid #ddd;

  &.selected {
    @include configuring;
  }

  .title {
    margin: 0;
    padding: 15px 0;
    letter-spacing: -0.02em;
  }

  .chevron {
    float: right;
    top: inherit !important;
    font-size: 0.8em;
  }

  .box-content {
    padding: 0 0 20px;
  }

  .copy-link {
    margin: 0 0 0 6px;
    padding: 0 5px;
    background-color: transparent;
    border: none;

    i {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
}
