.steps {
  display: flex;

  .segment {
    display: none;
    margin: 13px -30px 0 -30px;
    height: 2px;
    background-color: $colour-secondary;
    width: 100%;
  }

  .segment.done {
    margin-top: 12px;
    height: 4px;
  }

  // Base button classes
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    border: none;
    margin: 0;
    width: 60px;
    min-width: 60px;
    z-index: 1;

    .bubble {
      border: 4px solid transparent;
      background-color: transparent;
      width: 26px;
      height: 26px;
      border-radius: 100%;
      margin: auto;
    }

    .name {
      flex-grow: 1;
      display: none;
      padding: 10px 0;
    }
  }

  // Completed
  .button.completed {
    .bubble {
      background-color: $colour-secondary;
    }

    .bubble::before {
      display: inline-block;
      font-family: 'af-icons';
      line-height: 1;
      color: #fff;
      content: "\E038"; // Tick icon
    }

    &:hover {
      .bubble::before {
        content: "\E05C"; // Edit icon
      }
    }

    &.locked:hover {
      .bubble::before {
        content: "\E038"; // Tick icon
      }
    }
  }

  // Active
  .button.active {
    .bubble {
      border: 4px solid $colour-secondary;
      background-color: #fff;
    }

    .bubble::before {
      content: "";
      display: block;
      margin: 5px auto 0 auto;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background-color: $colour-secondary;
    }

    &:hover {
      .bubble::before {
        content: "";
      }
    }

    .name {
      font-weight: bold;
    }
  }

  // Remaining
  .button.remaining {
    .bubble::before {
      content: "";
      display: block;
      margin: 5px auto 0 auto;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      border: 3px solid transparent;
      background-color: $colour-secondary;
    }
  }

  // Let the label take as much space as possible
  .step-label {
    font-weight: bold;
    flex-grow: 1;
  }
}

@media (min-width: $screen-sm-min) {
  .steps {
    .segment {
      display: inline-block;
    }

    .button .name {
      display: inline-block;
    }

    .step-label {
      display: none;
    }
  }
}

.steps-progress {
  display: flex;
  height: 4px;
  margin: 10px 0;

  .segment {
    width: 100%;
    height: 2px;
    margin-top: 1px;
    background-color: $colour-secondary;

    &.done {
      height: 4px;
      margin-top: 0;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .steps-progress {
    display: none;
  }
}
