.multiselect {
  &.round-categories {
    .multiselect-toggles {
      .multiselect-selectall {
        width: 40%;
        float: left;
      }

      .multiselect-selectall-comments,
      .multiselect-selectall-scores,
      .multiselect-selectall-average {
        float: right;
        width: 20%;
      }

      .multiselect-selectall-scores, {
        width: 15%;
      }
    }

    .multiselect-options {
      label {
        float: left;
        width: 40%;
      }

      .multiselect-option {
        overflow: hidden;

        .multiselect-subset-control {
          &.comments,
          &.scores,
          &.average {
            float: right;
            width: 20%;

            input[type=checkbox] {
              margin-left: 0;
              position: relative;
            }
          }

          &.scores {
            width: 15%;
          }
        }
      }
    }
  }
}
