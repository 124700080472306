.action-dropdown {
  display: inline-block;
  padding: 0;

  .dropdown-toggle {
    background-color: #fff;
    color: $colour-link;
    border: 1px solid $colour-link;
    border-radius: $default-radius;
    padding: 5.5px 10px;
    margin-right: 5px;
    vertical-align: middle;
    @include transition(all $transition-speed);

    &:hover, &:focus {
      color: #fff;
      background-color: $colour-link;
      border-color: $colour-link;

      .caret {
        color: #fff;
      }
    }

    &:active, &:focus, &:focus-visible, &:focus-within {
      color: #fff;
      background-color: $colour-link;
      border-color: $colour-link;
      outline: 3px solid $colour-link;
      outline-offset: 2px;
      text-decoration: none;

      .caret {
        color: #fff;
      }
    }


    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: #fff;
      background-color: $colour-disabled;
      border-color: $colour-disabled;
    }
  }

  .dropdown-menu {
    margin-top: 8px;

    &.action-dropdown-primary {
      border-color: $colour-primary-hover;
      .dropdown-menu-item {
        &:focus, &:focus-visible, &:focus-within {
          background-color: $colour-primary-hover;
          outline-color: $colour-primary-hover;
          color: #fff;
        }
      }

      .divider {
        background-color: $colour-primary-hover;
      }
    }

    .caption {
      font-weight: bold;
    }
  }

  ul {
    width: max-content;
    min-width: 150px;
    max-width: 300px;
    padding: 0;
    margin-bottom: 0;
    list-style: none;

    button, span {
      .dropdown-menu-item:disabled:hover,
      .dropdown-menu-item[disabled]:hover,
      .dropdown-menu-item.disabled:hover {
        opacity: 1;
        color: $colour-disabled;
        cursor: not-allowed;
      }
    }

    .dropdown-menu-item {
      &:focus, &:focus-visible, &:focus-within {
        outline: 3px solid $colour-link;
        outline-offset: 2px;
        margin-left: -1px;
        width: calc(100% + 2px);
        padding: 7px 11px;
        border-radius: 2px;
      }

      .af-icons {
        line-height: $line-height-base;
      }
    }
  }
}

.action-dropdown.open {
  .dropdown-toggle {
    background-color: $colour-link;
    color: #fff;
    outline: 3px solid $colour-link;
    outline-offset: 2px;

    .caret, span {
      color: #fff;
    }
  }
}

.manager-actions {
  position: relative;
  @media (max-width: $screen-sm) {
    .action-dropdown {
      position: static;

      ul {
        max-width: unset;
      }

      .dropdown-menu {
        width: 100vw !important;
        max-width: unset;
        margin-right: -15px;

        .sub-dropdown {
          max-width: 300px;
        }
      }
    }
  }
}

.action-overflow {
  .dropdown-toggle {
    border: none;
    padding: 0;
    background-color: transparent;
    border-radius: 50%;
    @include transition(all $transition-speed);
    width: 24px;
    display: inline-block;

    &:hover {
      background-color: $colour-link;

      a {
        text-decoration: none;
      }

      .af-icons-action-overflow {
        color: #fff;
      }
    }

    &:focus, &:focus-visible, &:focus-within {
      background-color: $colour-link;
      outline: 3px solid $colour-link;
      outline-offset: 2px;

      a {
        text-decoration: none;
      }

      .af-icons-action-overflow {
        color: #fff;
      }
    }
  }

  li a:first-child {
    width: 100%;
    border-radius: 0;
    border: none;
    font-size: $font-size-base;
    display: inline-block;
    padding: 7px 10px;
    color: #000;
    background: none;
    text-decoration: none;
    cursor: pointer;
    text-align: start;
    @include transition( background $transition-speed );

    &:hover, &:focus {
      text-decoration: none;
      color: #fff;
      background-color: $colour-link;
    }
  }

  .dropdown-menu {
    margin-top: 7px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  hr {
    margin: 0;
    background-color: $colour-link;
    border-color: $colour-link;
  }

  .af-icons-action-overflow {
    font-size: $af-icons-base-size;
    vertical-align: middle;
    color: $colour-link;
  }
}

.action-overflow.open {
  .dropdown-toggle {
    background-color: $colour-link;
    color: #fff !important;
    outline: 3px solid $colour-link;
    outline-offset: 2px;

    .af-icons-action-overflow {
      color: #fff;
    }
  }
}

.action-overflow-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .action-overflow {
    margin-right: 6px;
  }
}

.action-list {
  width: inherit !important;
  list-style: none;

  .action-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    text-decoration: none;
    @include transition(background-color $transition-speed);

    div.actions {
      a {
        color: transparent;

        &:focus, &:focus-visible, &:focus-within {
          outline-color: #fff;
        }
      }
    }

    .action-list-overflow-button {
      &:hover, &:focus, &:focus-visible, &:focus-within {
        outline: transparent none 0;
      }
    }

    &:hover, &:focus, &:focus-visible, &:focus-within {
      background: $colour-link;
      color: #fff;

      div.actions {
        a {
          color: #fff;
          text-decoration: none;
        }
      }

      i.af-icons-action-overflow {
        color: #fff;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;

      .af-icons-shared {
        font-size: 16px;
        margin-left: 3px;
        margin-bottom: 5px;
        vertical-align: middle;
      }

      a {
        .af-icons-action-overflow {
          font-size: 24px;
          margin-right: 5px;
          vertical-align: middle;
          border-radius: 50%;
        }

        &:hover {
          .af-icons-action-overflow {
          background-color: #fff;
          color: $colour-link;
          border-radius: 50%;
          }
        }

        &:focus, &:focus-visible, &:focus-within {
          .af-icons-action-overflow {
          background-color: #fff;
          color: $colour-link;
          outline: 3px solid #fff;
          outline-offset: 2px;
          border-radius: 50%;
        }
      }
      }
    }
  }

  .link {
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    i.af-icons-add {
      margin-right: 3px;
    }
  }

  &.indent-list {
    .dropdown-menu-item {
      padding-left: 25px;
    }
  }
}
