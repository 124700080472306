// All IE versions (except for 11 at the moment).
.ie {
	
}

// IE10 and below.
.lt-ie11 {

}

// IE9 and below.
.lt-ie10 {
	
}

// Style overrides for IE8.
.lt-ie9 {
  // Position of the filter button.
  .filters #filter-toggle { bottom: -35px; }
}

// We do not support IE7 and below. Hide everything
// and display the unsupported browser message.
.lt-ie8 body {
  overflow: hidden;
	
  > * { display: none; }

  #unsupported-browser { display: block; }
}
