// Styles related to the FiltertronTray search component

$tray-border-color: rgba(160, 160, 160, 0.4);

.filtertron-tray {
  margin-top: 5px;

  .keyword-search {
    position: relative;
    margin-left: auto;
    max-width: 300px;

    i.af-icons-search {
      font-size: 20px;
      position: absolute;
      top: 7px;
      left: 9px;
    }
  }

  input.keywords {
    padding-left: 35px;
    padding-right: 85px;
  }

  .popover-wrap {
    width:100%;
  }
}

@media (min-width: $screen-sm-min) {
  .filtertron-tray {
    margin-top: 20px;
  }
}

@media (min-width: $screen-lg-min) {
  .filtertron-tray {
    input.keywords {
      min-width: 300px;
    }
  }

  .entrant-page .filtertron-tray {
    right: 0px;
  }

  .judging-page {
    margin-top: 160px;
  }
}

.advanced-search {
  position: absolute;
  right: 5px;
  top: 6px;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 2px;
  padding: 2px 10px;
  font-size: 80%;
  z-index: 100;
  text-decoration: none;
  @include transition(all 0.25s);
}

.advanced-search:hover,
.advanced-search:focus,
.advanced-search.active {
  border: 1px solid $colour-primary;
  background: $colour-primary;
  color: white;
  text-decoration: none;
}

.advanced-search:focus,
.advanced-search.active {
  outline-color: transparent !important;
}

.tray {
  z-index: 999;
  display: none;
  height: 100%;
  right: 0;
  position: fixed;
  background-color: $colour-form;
  transition-duration: 200ms;
  transform: translate(100%);
  overflow-y: auto;
  @include box-shadow(-6px 0 7px 1px rgba(0, 0, 0, .12));

  .tray-header {
    background-color: $colour-header-background;
    color: white;
    padding: 0 20px;
  }

  .tray-header-actions {
    height: 52px;
    line-height: 52px;
  }

  .tray-content {
    position: relative;

    .tray-overflow {
      width: 100%;
    }

    .search-form {
      padding: 20px 0 10px 0;
    }

    .fields {
      padding: 0 20px;
    }

    .tray-buttons {
      padding: 10px 20px;
      background-color: $colour-form;
      width: 100%;
      white-space: nowrap;
      z-index: 30;

      &.fixed {
        box-shadow: 0 -3px 5px -3px rgba(0,0,0,0.25);
        position: fixed;
        bottom: 0;
      }

      .search-reset {
        display: inline-block;
        left: 10px;
        position: relative;
        top: 6px;
      }

      .search-label {
        padding-left: 4px;
      }
    }

    .saved-searches-form {
      border-top: 1px solid $tray-border-color;
      padding: 20px;

      .field {
        width: 48%;
      }

      .field-name {
        float: left;
      }

      .field-shared {
        float: right;
      }

      .buttons {
        margin-top: 10px;
      }
    }

    .saved-searches {
      border-top: 1px solid $tray-border-color;
      padding: 20px;

      // Make popovers stay in place when scrolling
      position: relative;

      .table > tbody > tr > td {
        border-top: 0;
        padding: 3px 0;
      }

      .table td:first-child {
        width: 16px;
      }

      .dropdown-toggle {
        margin-left: -10px;
      }

      .saved-searches-form {
        border-top: 0;
        padding: 0;
      }

      .redirect {
        a {
          margin-right: 3px;
        }

        .af-icons-shared {
          font-size: 16px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .tray-close {
    .af-icons-cross {
      font-size: 110%;
      color: #fff;
    }
  }

  .tray-section {
    border-top: 1px solid $tray-border-color;

    .tray-section-heading {
      padding: 10px 20px;

      a {
        display: block;
        color: $colour-quaternary;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

.has-tray {
  #wrapper-page {
    float: left;
    background-color: #fff;
    transition-duration: 200ms;
  }
}

.has-tray {
  .tray {
    display: inline;
    width: 100%;
    transform: translate(0);
  }

  #wrapper-page {
    width: 0%;
    overflow: hidden;
  }
}

@media (min-width: $screen-sm) {
  .has-tray {
    .tray {
      width: 32%;
    }

    #wrapper-page {
      width: 68%;
      overflow: auto;
    }
  }
}

@media (min-width: $screen-lg) {
  .has-tray {
    .tray {
      width: 22%;
    }

    #wrapper-page {
      width: 78%;
    }
  }
}

.app.has-tray {
  overflow-y: auto;
}
