.entry-types {
  .entry-types-heading {
    float: left;
    width: 130px;
  }

  .entry-types-list {
    float: left;
  }
}

#active-category {
  margin-top: 0;

  img {
    float: right;
    margin: 0 0 15px 15px;
    max-width: 500px;
  }
}

.entry-content-block {
  @include transition(max-height 0.45s ease-in-out);

  overflow: hidden;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;

  .title {
    padding: 10px 0;

    .af-icons {
      vertical-align: middle;
    }

    .af-icons-chevron-up,
    .af-icons-chevron-down {
      font-size: .8em;
      margin-left: 5px;
      position: relative;
      top: -4px;
      color: $colour-tertiary;
    }

    .af-icons-info {
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }

  .body {

    /* max-height: 360px; // https://trello.com/c/74e7D0Ef/178-wcag-aa-compliance */
    /* overflow-y: auto; // https://trello.com/c/74e7D0Ef/178-wcag-aa-compliance */
    padding: 10px 0 30px 0;

    h2:first-child {
      margin-top: 0;
    }
  }

  &.closed {
    .body {
      display: none;
    }
  }

  &.open {
    .body {
      display: block;
    }
  }
}

.entrant-page,
.judge-page,
.vote-page,
.qualify-page,
.top-pick-page,
.error-page {
  margin: 0px -15px 50px;
}

@media(min-width: $screen-sm) {
  .entrant-page,
  .judge-page,
  .vote-page,
  .qualify-page,
  .top-pick-page,
  .error-page {
    margin: -10px -30px 50px;
  }
}

.entrant-content,
.judge-content,
.vote-content,
.top-pick-content,
.qualify-content,
.error-content {
  padding: 15px 15px 0;
  position: relative;
}

@media(min-width: $screen-sm) {
  .entrant-content,
  .judge-content,
  .vote-content,
  .top-pick-content,
  .qualify-content,
  .error-content {
    padding: 30px 30px 0;
  }
}

#searchResults,
.entrant-results,
.vote-results,
.top-pick-results,
.qualify-results {
  position: relative;

  .filtertron {
    right: 0;
  }
}

.entrant-header {
  background: #fff;
  padding: 15px 30px;
  margin-bottom: -30px;
}

.entrant-content-block {
  background: #fff;
}

.entry-manager-links {
  list-style: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0
}

.island--content-block-wrap {
  @media screen and (min-width: $screen-sm-min) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.entry-selectors {
  margin-top: 15px;
}

.attachment-container,
.links-container,
.contributors-container {
  margin: 0;
  margin-top: 20px;

  input.form-control[readonly].date,
  input.form-control[readonly].datetime,
  input.form-control[readonly].time {
    background-color: #ffffff;
  }
}

.contributors-container {
  h3 {
    margin: 0;

    a {
      font-size: $font-size-base;
    }
  }
}

.links-container,
.contributors-container {
  .link,
  .contributor {
    margin: 20px 0;

    .delete-link,
    .delete-contributor {
      float: right;
      text-decoration: underline;

      &:focus, &:hover {
        text-decoration: none;
      }
    }
  }

  .template {
    display: none;
  }
  .label-with-chip {
    display: flex;
    flex-grow: 1;
    align-items: baseline;
    gap: 0.5em;
    .locker-chip {
      position: relative;
      margin: 0 -28px 0 0;
    }
  }
}

ul.tabs.entry {
  margin-bottom: 0 !important;
}

td.entry-id {
  white-space: nowrap;
}

.error > h3 {
  color: $colour-error;
}

.attachment-count {
  margin-left: 5px;
  white-space: nowrap;
}

.score-matrix {
  .score-in-progress {
    color: #999;
  }

  .score-averages {
    td {
      border-width: 2px;
    }
  }
}

th.checkbox-column {
  width: 30px;
}

th.thumbnail-column {
  width: 80px;
}

.feedback-scores {
  margin-top: 0;
  margin-bottom: 10px;
  display: inline-block;
  border: 1px solid $colour-input-border;
  border-radius: $default-radius;

  h4 {
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .score-box-wrapper {
    float: left;
    position: relative;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .score-box {
    padding: 10px;
    color: #fff;
    border-radius: 2px;
  }

  .score-box-entry {
    background-color: $colour-primary;
  }

  .score-box-average {
    background-color: $colour-tertiary;
  }

  .score {
    margin-top: 10px;
    font-size: 150%;
  }
}

.feedback-scores:after {
  clear: both;
}

.feedback-scores:before, .feedback-scores:after {
  content: ' ';
  display: table;
}

.entry-read-only {
  .upload-button {
    display: none !important;
  }

  .upload-actions {
    display: none !important;
  }
}

.possible-issue-status {
  color: $colour-danger;
  display: block;
}

.review-task-assignees a {
  display: block;
}

.review-task-form .hidden-save-button {
  overflow: visible;
  height: 0;
  width: 0;
  margin: 0;
  border: 0;
  padding: 0;
  display: block ;
}

.review-task-form h3 p {
  display: inline-block;
}

.review-task-form {
  .form-actions {
    button {
      align-items: normal;
      gap: 10px;
    }
  }
}

.recusals {
  table {
    tr:first-child > td {
      border-top: none;
    }
  }
}

.keyword-search-popover {
  p:first-child {
    margin: 0 0 5px 0;
  }

  ul {
    margin-bottom: 5px;
  }

  .arrow {
    left: 15% !important;
  }
}

.entries-closed {
  display: inline-block;
  padding: 0;
  margin: 8px;
}

.preview-entry {
  h2 {
    margin-top: 5px;
  }
}

#duplicates-table {
  tr.not-last {
    border-bottom: 2px solid #ddd;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .radio.styled {
    margin: 0;
    text-align: center;
  }

  .primary-selector {
    left: 12px;
  }
}

.compare-duplicates {
  .entry {
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;

    .title {
      font-weight: bold;
    }
  }

  .field {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;

    .title {
      font-size: 90%;
      margin-bottom: 5px;
    }
  }

  .controls {
    form, .no-wrap {
      display: inline-block;
      white-space: nowrap;
    }

    .radio {
      margin-bottom: 20px;
    }
  }
}

.app .top-pick-page {

  #quick-selector-category {
    margin-top: -7px;
  }
}

.top-pick-content {
  background-color: white;

  .search-info-wrapper {
    margin-bottom: 0;
  }
}

.one-liner {
  padding: 10px 30px;
  margin: 0 -30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.entry-title-links {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 4px;
  }
}


.form-display.entry-manager-actions {
  background-color: white;
}

.entrant-header-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: $screen-md-min) {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .title-wrapper {
    flex: 1 1 0;
  }

  .countdown-wrapper {
    flex: 0 1 0;
    align-self: start;
    text-align: left;
    margin: 10px 0;

    @media screen and (min-width: $screen-md-min) {
      align-self: flex-end;
    }

    .digit {
      font-weight: 300;
    }

    .countdown-title {
      border-top: 1px solid $text-color;

      p {
        margin: 0;
      }
    }
  }
}

.thumbnail-entries {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.thumbnail-entries img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.submittable-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}
