.cover {
  width: 100%;
  height: auto;
  stroke: $colour-gray-sublight;
  stroke-width: 1;
  background-color: $gray-lighter;
  color: $gray-base;
}

.widget-form-link {
  color: $font-primary-colour;
  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: underline;
    color: $font-primary-colour;
  }
}

.cover-image {
    &:focus {
        .cover {
            border: 2px solid $font-primary-colour;
        }
    }
}
