$signature-pad-width: 400px;
$signature-pad-height: 150px;

.signature {
  .signature-pad {
    width: $signature-pad-width;
    height: $signature-pad-height;
    background-color: #fff;
    border: 1px solid $colour-input-border;
    border-radius: 0 4px 4px 4px;

    &.draw {
      position: relative;

      canvas {
        width: $signature-pad-width;
        height: $signature-pad-height;
      }

      .clear {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 15px;
      }
    }

    &.type {
      padding: 20px;

      input[type="text"] {
        width: 100%;
        border: 0;
        font-size: 24px;
        padding: 0;
      }
    }
  }

  .upload-wrapper {
    width: 400px;
    background-color: #fff;
    border: 1px solid #6f6f6f;
    border-radius: 0 4px 4px 4px;

    .cards {

      .card {
        width: 100%;
        float: none;
        margin: 0;

        .card-header {
          width: 100%;
        }

        .preview-container {
          width: 100%;
        }
      }
    }
    .button-holder {
      overflow: hidden;
      display: inline-block;
      position: relative;
      margin-top: 10px;
      z-index: 9;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
