#video-player-modal {
  text-align: center;

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  .modal-dialog {
    width: auto;
    height: auto;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .modal-body,
  .modal-content,
  .modal-dialog,
  .modal-dialog:focus-within {
    padding: 0;
    width: auto;
    height: auto;
    outline: none;
  }

  .modal-content {
    @include box-shadow(none);
    border-radius: 0;
    width: auto;
    height: auto;
  }
}
