@mixin lt-ie8 { .lt-ie8 & { @content; } }
@mixin lt-ie9 { .lt-ie9 & { @content; } }
@mixin lt-ie10 { .lt-ie10 & { @content; } }
@mixin lt-ie11 { .lt-ie11 & { @content; } }
@mixin ie { .ie & { @content; } }

// 
// Opacity mixin, adds support for older IE.
// 
@mixin opacity( $opacity ) {
  filter: alpha(opacity=#{$opacity * 100});
  opacity: $opacity;
}

// 
// Alert types helpers.
//
@mixin new-alert( $colour, $text-colour, $grid-position ) {
  $sprite-grid: 500px;
  $adjustment: 17px;

  color: $text-colour;
  background-color: $colour;
  background-position: 18px ( $sprite-grid * -$grid-position + $adjustment );

  &:hover {
    background-color: darken($colour, 10);
  }

  &.sticky {
    background-color: $colour;
  }

  &.inline {
    color: $colour;
  }

  a,
  h1,
  h2,
  h3 {
    color: $text-colour;
  }

  a:hover,
  a:focus {
    text-decoration: solid underline $text-colour 2px;
  }
}

//
// Triangle mixin, adds a small triangle arrow on top of an element.
//
@mixin triangle( $position: 'left', $background: #fff, $border: $colour-input-border ) {
  &:after, &:before {
    bottom: 100%;
    @if $position == 'right' {
      left: 85%;
    }
    @else if $position == 'center' {
      left: 50%;
    }
    @else if $position == 'left' {
      left: 15%;
    }
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba($background, 0);
    border-bottom-color: $background;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba($border, 0);
    border-bottom-color: $border;
    border-width: 11px;
    margin-left: -11px;
  }
}

//
// Undecorated mixin for links without text decoration.
//
@mixin undecorated() {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

//
// Semitransparent outline.
//
@mixin semitransparent-outline( $transparency: 0.125, $offset: -1px ) {
  outline: 1px solid rgba(0, 0, 0, $transparency);
  outline-offset: $offset;
}

//
// Transform.
//
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

//
// Position.
// http://bourbon.io/docs/#position
//
@mixin position($position: relative, $coordinates: 0 0 0 0) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  position: $position;

  @if $top == auto {
    top: $top;
  }
  @else if not(unitless($top)) {
    top: $top;
  }

  @if $right == auto {
    right: $right;
  }
  @else if not(unitless($right)) {
    right: $right;
  }

  @if $bottom == auto {
    bottom: $bottom;
  }
  @else if not(unitless($bottom)) {
    bottom: $bottom;
  }

  @if $left == auto {
    left: $left;
  }
  @else if not(unitless($left)) {
    left: $left;
  }
}

//
// Size.
// http://bourbon.io/docs/#size
//
@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}

//
// Shift icon position.
// Allows to position icons next to a block of text.
//
@mixin shift-icon-position($by: 20px) {
  padding-left: $by;
  position: relative;

  .af-icons {
    position: absolute;
    margin-left: -$by;
  }
}

//
// Text content.
// Removes top and bottom margins from text elements - useful when embedding text content
// in a container that has its own padding.
//
@mixin text-content() {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6,
  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:only-child {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

//
// Configuring mixin.
//
@mixin configuring() {
  border: 1px solid $colour-secondary;
  background: rgba($colour-secondary, 0.1);
}
