// Slideshow

body.slideshow {
  background-color: #000;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  #vue-root {
    width: 100%;
    height: 100%;
  }

  #pjaxContainer {
    width: 100%;
    height: 100%;
  }

  #loader {
    background-color: #000;

    .spinner {
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -25px;
    }
  }
}

.slideshow-container {
  width: 100%;
  height: 100%;
}

// Slides

.slideshow-slides {
  overflow: hidden;
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.slideshow-slides-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.slideshow-contact-sheet,
.slideshow-slide {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
}

.slideshow-slide {
  img {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    overflow: auto;
  }

  .video-js {
    width: 100%;
    height: 100%;
  }
}

.slideshow-slide-title {
  position: fixed;
  top: 25px;
  left: 25px;
  text-shadow: 1px 1px 3px #000;
}

// Contact sheet

.slideshow-contact-sheet {
  img {
    max-width: 300px;
    max-height: 200px;
  }

  .no-slides-found {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
      margin: 0;
      color: $font-primary-colour;
    }
  }
}

.slideshow-contact-sheet-container {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 40px;
  height: 100%;

  .slideshow-contact-sheet-items {
    width: 100%;
    max-width: 350px;
    margin: 40px auto;

    .contact-sheet-item {
      float: left;
      margin: 15px;
      width: 300px;

      .contact-sheet-item-title {
        color: $colour-tertiary;
        margin: 10px 0;
      }

      .contact-sheet-item-media {
        display: flex;
        background-color: #222;
        width: 300px;
        height: 200px;
      }

      a {
        margin: auto;
        display: block;
        color: #fff;
      }

      .image {
      }

      .video-link,
      .video {
        position: relative;

        .af-icons-md-circle-video {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -23px;
          margin-left: -23px;
          font-size: 3.25em;
          text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

@media(min-width: 740px) {
  .slideshow-contact-sheet-container {
    .slideshow-contact-sheet-items {
      max-width: 670px;
    }
  }
}

@media(min-width: 1060px) {
  .slideshow-contact-sheet-container {
    .slideshow-contact-sheet-items {
      max-width: 990px;
    }
  }
}

// Navigation

.slideshow-nav {
  position: absolute;
  top: 50%;
  width: 54px;
  height: 72px;
  margin: 0 9px;
  margin-top: -36px;
  cursor: pointer;
  @include user-select(none);

  .slideshow-nav-button {
    float: left;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .slideshow-nav-button-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }

    .slideshow-nav-button-icon {
      float: left;
      position: relative;
      height: 100%;
      width: 100%;
      zoom: 1;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      opacity: .6;
    }

    &:hover {
      .slideshow-nav-button-icon {
        opacity: 1;
      }
    }
  }
}

.slideshow-nav-previous {
  left: 0;

  .slideshow-nav-button-icon {
    background-image: url('../img/slideshow/previous.svg');
  }
}

.slideshow-nav-next {
  right: 0;
  left: auto;

  .slideshow-nav-button-icon {
    background-image: url('../img/slideshow/next.svg');
  }
}

.slideshow-nav-fullscreen {
  top: 35px;
  right: 0;

  .slideshow-nav-button-icon {
    @include af-icons();

    color: #fff;
    font-size: $af-icons-base-size;
  }

  .slideshow-nav-button-icon:before {
    content: '\E04D';
    position: relative;
    top: 25px;
    left: 15px;
  }
}

.slideshow-nav-fullscreen.off {
  .slideshow-nav-button-icon:before {
    content: '\E04E';
  }
}
