.cart {
  .discount-code {
    .discount-code-form {
      margin: 15px 0;
    }
  }

  .security {
    img {
      margin: 15px;
    }
  }
}

.table.order {
  .merge-cell {
    border-top: 0;
  }

  .amount {
    white-space: nowrap;
  }

  .quantity {
    .qty {
      display: inline-block;
      right: 20px;
      vertical-align: middle;
    }

    .delete {
      display: inline-block;
      right: 0;
      vertical-align: middle;
    }
  }

  .total {
    font-size: 18px;
  }
}

.member-number {
  padding-left: 20px;

  input.form-control {
    width: 80%;
  }
}

.payment-info-box-title {
  padding: 0 30px;
}
