.print-only {
  display: none;
}

.no-print {
  display: block;
}

@media print {
  header, footer {
    display: none !important;
  }

  .print-only {
    display: block;
  }

  .no-print {
    display: none;
  }
}
