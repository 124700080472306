.form-settings {
  .attachment-filetypes {
    h3 {
      margin-top: 30px;
    }

    .select-all {
      label {
        color: #999;
      }
    }
  }
}

.payment-general-settings .panel {
  margin-bottom: 15px;
}

.payment-general-settings .checkbox.styled {
  margin-bottom: 15px;
}
