.quick-selector-voting {
  .instructions {
    margin-bottom: 5px;
  }
}

.voting-entry {
  h2 {
    margin-top: 5px;
  }
}

.voting-controls {
  display: flex;

  .control {
    width: 100%;
    height: 32px;
    padding: 5px 10px;
    color: white;
    margin: 0;
    border: 1px solid transparent;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;

    @include transition( background $transition-speed );
  }

  .vote {
    background-color: $colour-primary;
    border-radius: 0;

    &:hover {
      background-color: darken($colour-primary, 10%);
    }
  }

  .voting {
    .btn-unvote {
      background-color: $colour-secondary;
    }
  }

  .voted {
    background-color: $colour-secondary;

    &:hover {
      background-color: darken($colour-secondary, 10%);
    }
  }

  .vote-count {
    background-color: $colour-tertiary;
    margin-left: 1px;
  }

  .btn-unvote {
    margin-right: 10px;
  }
}

.quick-selector-vote {
  border: none;
  padding: 0;
  margin: 0;
}

.voting-controls-lg {
  justify-content: flex-end;
  align-items: flex-end;

  .control {
    width: auto;
    min-width: 122px;
    height: 42px;
    padding: 10px 15px;
    font-size: 1.2em;
  }

  &:not(:has(.vote-count)) .control:first-child {
    border-radius: $default-radius;
    margin-right: 10px;
  }

  &:has(.vote-count) {
    .control:first-child {
      border-top-left-radius: $default-radius;
      border-bottom-left-radius: $default-radius;
    }

    .vote-count {
      border-top-right-radius: $default-radius;
      border-bottom-right-radius: $default-radius;
      margin-right: 10px;
    }
  }
}

.voting-wrapper {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

@media (max-width: $screen-lg-min) {
  .voting-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .quick-selector-vote {
    margin-bottom: 10px;
  }
}

// Limit h1 element's width to prevent long score set names from being
// covered by the search box.
@media(min-width: $screen-sm) {
  .vote-results .title {
    max-width: 78%;
  }
}

