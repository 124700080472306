.quick-manager {
  .quick-approver,
  .quick-tagger {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: $screen-lg-min) {
  .quick-manager {
    .quick-approver,
    .quick-tagger {
      margin-bottom: 0;
    }
  }
}

// Quick Approver
.quick-approver {
  .moderation-status {
    border: 1px solid $colour-border;
    margin-bottom: 5px;
    background-color: #fff;

    .radio.styled {
      margin: 3px 10px 5px 5px;
    }

    &.approved.active,
    &.rejected.active {
      color: #fff;

      .radio.styled label::before {
        background-color: transparent;
        border: 1px solid #fff;
      }

      .radio.styled label::after {
        background-color: transparent;
        @include af-icons;
      }
    }

    &.approved.active {
      border-color: $colour-approved;
      background-color: $colour-approved;

      .radio.styled label::after {
        content: '\E038'; // Embed tick icon
        font-size: 12px;
        line-height: .7;
      }
    }

    &.rejected.active {
      border-color: $colour-rejected;
      background-color: $colour-rejected;

      .radio.styled label::after {
        content: '\E010'; // Embed cross icon
        font-size: 14px;
        line-height: .5;
      }
    }
  }

  .moderation-status:last-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-lg-min) {
  .quick-approver {
    .moderation-status {
      margin-right: 5px;
      float: left;
    }
  }
}

// Quick Comments
.quick-comments {
  .comment-container {
    margin: 0;
  }

  .comment-new {
    margin-top: 0;
    .btn {
      margin-top: 15px;
    }
  }
}
