.quick-selector {
  clear: both;
  border-radius: $default-radius;
  padding: 10px 20px;
  margin: 0;
  margin-bottom: 30px;
  color: $colour-form-box-text;
  background-color: white;
  border: 1px solid #ddd;

  h3.quick-selector-title {
    i {
      font-size: 0.8em;
      margin-left: 8px;
    }

    span {
      vertical-align: text-bottom;
    }
  }
}

.quick-selector.closed {
  h3.quick-selector-title {
    margin-bottom: 3px;
  }

  .quick-selector-content {
    display: none;
  }
}

.quick-selector.quick-selector-transparent {
  margin-bottom: 0;
  background-color: transparent;
  border-color: transparent;
}

// Styles related to category quick selector
#quick-selector-category {
  .group-title {
    @include text-overflow;
  }

  .quick-selector-content__tabs-list {
    flex-wrap: wrap;
    border-bottom: none;
    height: auto;
    padding: 0;
    margin: 0;

    li {
      margin: 0 10px 10px 0;
      position: relative;

      .badge {
        position: absolute;
        bottom: -6px;
        left: calc(100% - 10px);
        background-color: $brand-default;
      }
    }

    li.parent {
      padding: 4px 10px;
      border-radius: $default-radius;
      border: 1px solid #ddd;
    }

    a {
      display: block;
      padding: 5px 10px;
      border-radius: $default-radius;
      background-color: $colour-tab;
      color: $colour-tab-text;
      text-decoration: none;
      transition: color $transition-speed ease-in-out, background-color $transition-speed ease-in-out;

      &:hover {
        background-color: $colour-tab-hover;
        color: $colour-tab-text;

        &:focus {
          background: $colour-tab-hover;
        }
      }
    }
  }
}

// Styles related to 'Managers only' quick selector
#quick-selector-managers-only {
  .quick-selector-content {
    margin-bottom: 10px;
  }
}

// Styles related to 'Field access' quick selector
#quick-selector-field-access {
  margin-bottom: 0;
}

@media (min-width: $screen-md-min) {
  #quick-selector-field-access {
    a.btn {
      margin-top: 25px;
    }
  }
}
