// DataTable - Responsive extension

.dataTable {
  & > thead > tr > th {
    padding-bottom: 0;
    vertical-align: bottom;
  }

  & > tbody > tr > td {
    padding-top: 0;
    vertical-align: top;
  }

  &.dtr-inline.collapsed {
    & > tbody > tr > td:first-child,
    & > thead > tr > th:first-child {
      position: relative;
      padding-left: 50px;
      cursor: pointer;
    }

    & > tbody > tr > td:first-child:before {
      @include af-icons();
      content: '\E031';
      font-size: 16px;
      line-height: 16px;
      color: white;
      background-color: $colour-tertiary;
      position: absolute;
      top: 22px;
      left: 22px;
      height: 16px;
      width: 16px;
      margin-top: -4px;
      margin-left: -8px;
      border-radius: $default-radius;
      box-sizing: content-box;
      text-align: center;
    }

    & > tbody > tr.parent > td:first-child:before {
      background-color: $colour-secondary;
    }

    & > tbody > tr.child td:before {
      display: none;
    }
  }

  &.dtr-inline > tbody > tr.child > td.child {
    padding: 15px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 10px;
      }

      .dtr-title {
        font-size: 0.8em;
      }

      .dtr-data {
        display: block;
      }
    }
  }

  ul.dtr-details {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-bottom: 10px;
    }

    .dtr-title {
      font-size: 0.8em;
    }

    .dtr-data {
      display: block;
    }
  }
}

// DataTable - ColOrder extension

.dataTable {
  > thead > tr > th {
    cursor: move;
    padding: 15px 10px;
    @include user-select(none);

    &:hover {
      background-color: #fff;
    }

    &.fixed {
      cursor: auto;

      &:hover {
        background-color: transparent;
      }
    }
  }

  > tbody > tr > td {
    padding: 15px 10px;
  }

  .checkbox.styled {
    margin-right: -15px;
  }

  .cog-icon {
    margin-left: -15px;
    margin-right: -15px;
  }

  .condensed-column {
    padding: 15px 0;
  }

  .title-sorting-icon-only {
    text-align: center;
    position: relative;
    width: 1%;
    .sortable {
      width: 0;
      padding: 0;
      background-position: center -100px;
      color: transparent;
      &.sort-desc {
        background-position: center 4px;
      }
      &.sort-asc {
        background-position: center -47px;
      }
    }
  }

  .hidden-title {
    color: transparent;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .dataTable {
    > thead > tr > th,
    > tbody > tr > td {
      padding: 15px;
    }
  }
}

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: $colour-tertiary;
  z-index: 201;
}

// DataTable - tweaks

.dataTable {
  > tbody > tr > td:first-child {
    outline: none;
  }

  > tfoot > tr > td {
    border-top: 3px double #ddd;
    border-bottom: 1px solid #ddd;
  }
}

.yellow-star {
  color: #FFAB00;
}

.grey-star {
  color: #ccc;
}


.active-star {
  cursor: pointer;
  font-size:1.4em;
}

.inactive-star {
  cursor: default;
  font-size:1.4em;
}
