//** Background color of the whole progress component
$judge-progress-bg:                 #dddddd !default;

/**
 * Special progress bar with column-split content used in judging views.
 */
.judge-progress{
  border-radius: 5px;
  overflow: hidden;
  height: $line-height-computed;
  background-color: $judge-progress-bg;

  &.progress-thin {
    height: $line-height-computed - ($line-height-computed * 50) * 0.01;
  }
}

.judge-progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: $font-size-small;
  line-height: $line-height-computed;
  color: $progress-bar-color;
  text-align: center;
  background-color: $judge-progress-bg;
  @include transition(width .6s ease);
  box-shadow: none;
  cursor: pointer;
}
