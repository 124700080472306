.user-face {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;

  &.user-face-large {
    width: 50px;
    height: 50px;
    font-size: 24px;
    border-radius: 25px;
  }

  &.user-face-medium {
    width: 35px;
    height: 35px;
    font-size: 17px;
    border-radius: 17px;
  }

  &.user-face-small {
    width: 18px;
    height: 18px;
    font-size: 10px;
    border-radius: 9px;
  }
}
