//
// Dropdown menus
// --------------------------------------------------


// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 6px;
  vertical-align: middle;
  border-top:   4px dashed;
  border-right: 4px solid transparent;
  border-left:  4px solid transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-left-width: 6px;
  border-bottom-width: 6px;
  @include transition(all $transition-speed);
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  position: relative;
}

.a-without-underline {
  text-decoration: none;
  outline: 0;
}

.a-without-underline:hover {
  text-decoration: none;
  outline: 0;
}

.dropdown-submenu {
  margin-left: 20px !important;
}

.dropdown-plus {
  margin-top: -20px !important;
  margin-left: 20px !important;
}

.select-dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: $dropdown-bg;
  z-index: $zindex-dropdown;
  display: none;
  border-radius: 0 0 4px 4px;
  list-style: none;
  padding: 5px 0;
  border: 1px solid $dropdown-fallback-border; // IE8 fallback
  border: 1px solid $dropdown-border;
  cursor: pointer;

  > li > a {
    display: block;
    padding: 6px 20px;
    font-weight: normal;
    line-height: $line-height-base;
    color: $colour-link;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    text-decoration: none;
    min-height: 32px;

    &:hover,
    &:focus {
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
  }
}

.select-dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  width: max-content !important;
  min-width: 150px;
  max-width: 300px;
  padding: 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: $font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: $dropdown-bg;
  border: 1px solid $colour-link;
  border-radius: $border-radius-base;
  @include box-shadow(0 6px 12px rgba(0,0,0,.175));
  background-clip: padding-box;

  .divider {
    @include nav-divider($colour-link);
  }

  .caption {
    padding: 7px 10px;
  }
}

.dropdown-menu-item, .dropdown-tags .dropdown-menu > li > a {
  width: 100%;
  border-radius: 0;
  border: none;
  font-size: $font-size-base;
  display: inline-block;
  padding: 7px 10px;
  color: #000;
  background: none;
  text-decoration: none;
  cursor: pointer;
  text-align: start;
  @include transition( background $transition-speed );

  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
    background-color: $colour-link;
  }

  &:focus, &:focus-visible, &:focus-within {
    background-color: $colour-link;
      outline: 0;
  }

  &:disabled {
    border-color: $colour-disabled;
    opacity: 1;
    color: $colour-disabled;
    cursor: not-allowed;

    &:hover, &:focus, &:active, &:focus-visible {
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  .af-icons {
    line-height: $line-height-base;
  }

  &.lg-padding{
    padding: 7px 20px;
  }

  &.danger {
    color: $colour-danger;
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: #fff;
    background-color: $colour-link;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: $colour-link;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray
.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    @include reset-filter;
    cursor: $cursor-disabled;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }
  > .select-dropdown {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  margin-top: 6px;
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: ($zindex-dropdown - 10);
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: 4px solid;
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}
