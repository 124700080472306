.tips {
  background: $colour-focus-box-background;
  border-radius: $default-radius;
  color: $colour-focus-box-text;
  overflow: hidden;
  padding: 0 30px 30px 30px;

  h1, h2, h3 {
    color: $colour-focus-box-text;
  }
}

.tips-title {
  font: 400 28px/28px $font-primary;
  margin-bottom: 20px;

  h1 {
    font-size: 0.8em;
    line-height: 1.2em;
    margin: 0;
  }
}

.tips-body {
  margin-left: 160px;

  a {
    color: $colour-focus-box-text;
  }

  p + p {
    margin-top: 14px;
  }

  ol {
    counter-reset: item;
    margin: 10px 0;

    > li {
      display: block;
      padding-left: 5px;
      position: relative;
      z-index: 1;

      &:before {
        @include position( absolute , 0 0 0 -22px );
        @include size( 20 );

        background: $colour-focus-box-text;
        color: $colour-focus-box-background;
        border-radius: 50%;
        content: counter(item) ' ';
        counter-increment: item;
        line-height: 20px;
        text-align: center;
        z-index: -1;
      }
    }
  }

  li + li {
    margin-top: 8px;
  }
}

.splash {
  .tips-body {
    margin-left: 0;
  }
}
