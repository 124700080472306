.entry-content-block {
  @include transition(max-height 0.45s ease-in-out);

  overflow: hidden;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;
  border-radius: $border-radius-base;

  .title {
    padding: 10px 0;

    .af-icons {
      vertical-align: middle;
    }

    .af-icons-chevron-up,
    .af-icons-chevron-down {
      font-size: .8em;
      margin-left: 5px;
      position: relative;
      top: -4px;
      color: $colour-tertiary;
    }

    .af-icons-info {
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }

  .body {

    /* max-height: 360px; // https://trello.com/c/74e7D0Ef/178-wcag-aa-compliance */
    /* overflow-y: auto; // https://trello.com/c/74e7D0Ef/178-wcag-aa-compliance */
    padding: 10px 0 30px 0;

    h2:first-child {
      margin-top: 0;
    }
  }

  &.closed {
    .body {
      display: none;
    }
  }

  &.open {
    .body {
      display: block;
    }
  }
}
