.tabs {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 0;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  // make sure box-shadow fades out when tabs-legacy-shadow is removed
  box-shadow: none;
  transition: box-shadow 1s;

  button {
    position: relative;
    user-select: none;
    transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
    border: 0;
    margin-right: 0;
    background: $colour-tab;
    color: $colour-tab-text;
    border-bottom: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 24px;
    text-decoration: none;
    border-radius: $default-radius;
    outline: 2px solid transparent;

    &:where(:last-child) {
      border-radius: $default-radius $default-radius 0 0;
    }

    @media (min-width: $screen-sm-min) {
      display: inline-flex;
      width: auto;
      border-radius: $default-radius $default-radius 0 0;
    }

    &.active {
      z-index: 2;
      background: $colour-tab-active;
      color: #fff;
      cursor: default;
      margin-bottom: -2px;

      &:focus-visible {
        background: $colour-tab-active;
      }
      .settings-button {
        outline-color: #fff;
      }
    }

    &.invalid {
      color: $colour-error;
    }

    &:where(:hover) {
      background-color: $colour-tab-hover;
      color: $colour-tab-text;
    }

    &:focus-visible {
      @include focus-outline(#333)
    }

    &.dragging,
    &.dragging:focus-visible {
      outline-color: $colour-secondary;
    }

    &:disabled {
      background: #efefef;
      color: #666;
      border: none;
      cursor: default;
    }
  }

  &--borderless {
    padding-top: 5px;
    border-bottom: none;
    button {
      &.invalid {
        color: $colour-tab-text;
        border-top: 4px solid $colour-error;
        padding-top: 6px;

        &:hover {
          border-top: 4px solid $colour-error;
        }

        &:focus-visible {
          border-top: 4px solid $colour-error;
        }
      }
    }
  }

  &--legacy {
    border-bottom: 2px solid #6f6f6f;
    padding: 0 15px;
    // border
    &::before {
      left: 0;
      right: 0;
    }
    button {
      &.invalid {
        color: $colour-tab-text;
        border-top: 4px solid $colour-error;
        padding-top: 6px;

        &:hover {
          border-top: 4px solid $colour-error;
        }

        &:focus-visible {
          border-top: 4px solid $colour-error;
        }
      }
    }

    @media(min-width: $screen-sm-min) {
      padding: 15px 30px 0;
      margin-left: -15px;
      margin-right: -15px;
      background-color: #fff;

      button {
        margin-bottom: -0.5px;
      }
    }
  }

  &.nav::before, &.nav::after {
    content: none;
  }
}

.tabs-loading {
  button {
    &.disabled {
      min-width: 85px;
      min-height: 40px;
    }
  }
}

.tab-content {
  display: none;

  &.active {
    display: block;
  }
}

.tab-content-flat {
  margin-bottom: 60px;
}

.tabs-panels {
  padding: 0 15px;
}

.tabs-legacy-wrapper {
  position: relative;
  @media(min-width: $screen-sm-min) {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.tabs-legacy-shadow {
  @include box-shadow(0 3px 10px 2px RGB(0 0 0 / 25%));
}
