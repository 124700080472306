@import '../../js/src/vendor/toastr/toastr.scss';

.toast-message a {
  color: $colour-link;
}

.toast-message a:hover {
  color: $colour-link-hover;
}

.toast-message a:active {
  color: $colour-link-active;
}

.toast-top-right {
  top: 62px;
  right: 30px;
}

#toast-container > div {
  width: 400px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  @include box-shadow(0 5px 15px 2px rgba(0, 0, 0, 0.10));
}

#toast-container > :hover {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -ms-filter: none;
  filter: none;
  @include box-shadow(0 5px 15px 2px rgba(0, 0, 0, 0.10));
}

#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: fixed;
  font-family: 'af-icons-md';
  font-size: 28px;
  line-height: 18px;
  float: left;
  color: #fff;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}

#toast-container > .toast-warning:before {
  content: '\E005'
}

#toast-container > .toast-error:before {
  content: '\E002'
}

#toast-container > .toast-info:before {
  content: '\E003'
}

#toast-container > .toast-success:before {
  content: '\E004'
}

.toast {
  background-color: $colour-disabled;
}

.toast-success {
  background-color: $colour-success;
}

.toast-error {
  background-color: $colour-error;
}

.toast-info {
  background-color: $colour-info;
}

.toast-warning {
  background-color: $colour-warning;
}
