/**
 * Vue entry-form custom validation styles.
 */
#entry-form {
  .form-group.error {
    .field-label, .alert-error {
      color: $validation-error-label-text;
    }

    .form-control {
      background-color: $validation-error-background;
      border-color: $validation-error-border;
    }
  }
}
