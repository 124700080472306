// Restrict textarea resizing.
textarea {
  max-height: 264px; /* This is to prevent the autosize library getting too crazy */
  min-height: 60px;
  resize: none;
  word-wrap: break-word;

  &.short {
    min-height: 37px;
  }
}

textarea#content {
  margin-left: 0 !important; /* This rule is to override the styling applied as a result of a WCAG AA compliant id being applied */
}

label {
  p {
    display: inline-block;
    margin: 0;
  }
}

.autocomplete-control {
  overflow: visible !important;
}

.attachment-control {
  position: relative;
}

.control-heading {
  margin-bottom: 16px;

  h2 { line-height: 1.4em; }
}

.inset {
  border: 1px solid #ddd;
  margin-bottom: 15px;
  padding-top: 15px;
}

.form-group {
  label {
    display: inline-block;
    vertical-align: middle;

    > .af-icons-lock {
      padding-right: 3px;
    }

    div {
      display: inline-block;
    }
  }

  .af-icons {
    cursor: pointer;
  }

  .af-icons-lock {
    cursor: default;
  }

  [disabled] {
    background-color: $colour-disabled-input !important;
    opacity: 1;
  }

  button[disabled] {
    background-color: $colour-disabled !important;
  }

  &.dependent {
    margin-left: 23px;
  }
}

// Help text that appears under a field.
.help-text {
  color: #666;
  display: inline-block;
  font-size: 0.923em;
  margin-top: 5px;
}

// Hint text that appears under a field.
.hint-text {
  p {
    margin: 0 0 1em 0;
  }
}

form {
  .header {
    margin-bottom: 0;
  }
}

/* Actions styles */
.form-actions {
  margin-top: 50px;

  &.centered { text-align: center; }

  .control-field { width: 100%; }

  button, input, a, span.btn {
    margin-bottom: 10px;
  }
}

.form-actions-sm {
  margin-top: 30px;
}

.form-actions-message {
  vertical-align: top;
  margin-top: 8px;
  display: inline-block;
}

/* Change the default focus colours */
input:focus,
textarea:focus,
select:focus {
  border-color: $colour-input-border-focus;
  outline: 2px solid $colour-input-border-focus;
}

input.price-field { width: 70px; }

input.two-digit { width: 50px; }

.form-inline {
  .form-control {
    &.date,
    &.datetime,
    &.timezones { width: 45% }
  }
}

.cc-expiry {
  .column-quarter:first-child { width: 50px; }
}

// Specific to the roles checkbox list.
ul.roles li {
  margin-bottom: 15px;
  margin-right: 15px;
}

// This is used on the judging groups page.
ul.groups li {
  + li { margin-top: 5px; }

  input { margin-right: 6px; }
}

// For client-side validation errors.
div.error,
span.error {
  color: $colour-error;

  a {
    color: $colour-error;

    &:focus, &:hover, &:active {
      color: $colour-error;
    }
  }

  input,
  textarea,
  select {
    border-color: $colour-input-border-error;

    &:focus {
      border-color: $colour-input-border-error-focus;
    }
  }

  table thead tr {
    color: $colour-error;
  }
}

// Required fields
span.required { color: $colour-error; }

div.disabled,
label.disabled {
  background-color: transparent !important;
}

// For any element that is disabled.
[disabled],
[disabled=disabled],
.disabled,
.disabled-form input,
.disabled-form select,
.disabled-form textarea {
  @include opacity( 0.5 );

  &:hover { cursor: not-allowed !important; }
}

// Text that is disabled, doesn't look so nice with the background colour.
.disabled-text {
  @include opacity( 0.5 );

  &:hover { cursor: not-allowed; }
}

// Input field placeholders
$colour-placeholder: #757575;

::-webkit-input-placeholder { color: $colour-placeholder; }

:-moz-placeholder { color: $colour-placeholder; }

::-moz-placeholder { color: $colour-placeholder; }

:-ms-input-placeholder { color: $colour-placeholder; }

.ie-placeholder-container {
  position: relative;

  .ie-placeholder {
    @include position( absolute , 10px 0 0 10px );
    color: $colour-placeholder;
  }
}

.form-display {
  .form-label {
    font-weight: bold;
  }
}

.word-count, .character-count {
  float: right;
}

.radio .radio-description {
  margin-top: 7px;
  margin-bottom: 25px;
}

.indent-option {
  margin-left: 20px;
}

input.code,
textarea.code {
  font-family: $font-family-monospace;
}

.form-action-list {
  margin: -6px -20px;
  padding: 0;
  color: inherit;
  background-color: inherit;

  button {
    width: 100%;
    margin: 0;
    padding: 6px 0 6px 20px;
    color: #333;
    background-color: inherit;
    text-align: left;
    border: inherit;
    outline: inherit;

    &:hover {
      color: white;
    }
  }
}

.toggle-container {
  margin-right: 30px;
  float: right;

  .toggle-content-label {
    float: left;
    line-height: 1.3;
    margin-right: 10px;
  }

  .toggle-content-switch {
    float: left;
  }
}
