.toggle-switch-wrapper {
  .toggle-switch-label {
    float: left;
    line-height: 1.3;
    margin-right: 10px;
  }

  .toggle-switch {
    float: left;
  }
}
