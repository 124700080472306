// Prices
.table-row-template {
  display: none;
}

#prices {
  .draggable-item:focus-visible {
    outline: 2px solid #333;
  }

  .draggable-item.dragging,
  .draggable-item.dragging:focus-visible {
    outline: 2px solid $colour-secondary;
    background-color: rgba($colour-secondary, 0.1);
  }
}

// Currencies
.currency-table {
  > tbody > tr > td {
    vertical-align: middle;
  }

  input {
    max-width: 100px;
  }
}

// Pricing rules
.tier-entries {
  .tier-label {
    float: left;
  }

  .tier-input {
    float: left;

    input {
      width: 50px;
      text-align: right;
    }
  }

  .tier-label-prefix {
    padding: 10px 5px 0 0;
  }

  .tier-label-suffix {
    padding: 8px 0 0 5px;
  }
}

.invoice-instructions {
  padding-left: 20px;
}

// Put the the on/off switch on the same line as title
.title-payments {
  h1 {
    display: inline-block;
  }

  .onoffswitch-container {
    display: inline-block;
    margin-left: 5px;

    .onoffswitch-label {
      vertical-align: middle;
      margin: 0;
    }

    form {
      display: inline-block;
    }
  }
}
