// ContentBlock quick links
.content-block-container {
  position: relative;
  width: 100%;

  a.af-icons-edit {
    position: absolute;
    text-decoration: none;
    font-size: 1em;
    text-align: left;
    z-index: 2;
    line-height: 0;
  }

  &.new {
    height: 20px;
  }

  .info-box h3.title {
    padding-left: 20px;
  }

  // Adjust the default top/right position based on the content block location
  &.new a.af-icons-edit { top: 30px; left: 15px; }

  &.edit a.af-icons-edit { top: 20px; left: 30px; }
}

@media screen and (min-width: $screen-sm-min) {
  .content-block-container {
    &.new a.af-icons-edit { top: 30px; left: 30px; }

    &.edit a.af-icons-edit { top: 20px; left: 30px; }
  }
}
