.removable-list {
  .item {
    &:hover {
      .remove {
        visibility: visible;
      }
    }

    &:not(:last-child) {
      margin-bottom: 11px;
    }

    .remove {
      visibility: hidden;
      vertical-align: middle;
      margin-top: 2px;
      font-size: 16px;
    }
  }
}
