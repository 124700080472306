// Force an element to be hidden.
.hidden { display: none !important; }

// CSS Island objects
.island { margin-bottom: 25px; }

.islet { margin-bottom: 15px; }

.well {
  color: $colour-form-box-text;
  background: $colour-form-box-background;
  border: 0;
  padding: 20px;
}

.accessible-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
