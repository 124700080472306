ul, ol {
  &.expanded {
    > li + li { margin-top: 5px; }
  }
  // Vertical and horizontal lists.
  &.vertical, &.horizontal, &.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  &.vertical {
    &.expanded > li {
      line-height: 1.3em;

      + li { margin-top: 5px; }
    }
  }

  &.horizontal {
    > li { float: left; }

    &.expanded > li + li {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  &.horizontal.right-aligned {
    > li { float: right; }

    &.expanded > li {
      margin-left: 10px;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  // Table-styled list.
  &.table {
    display: table;
    width: 100%;

    > li {
      display: table-cell;
      float: none;

      > a { display: block; }
    }
  }
}

// Definition lists.
dl {
  &.tabular {
    overflow: hidden;

    dt, dd {
      float: left;
      margin-bottom: 10px;
    }

    dt {
      font-weight: bold;
      width: 35%;
    }

    dd {
      margin-left: 5%;
      width: 60%;
    }
  }

  &.compact {
    dt, dd { margin-bottom: 0; }
  }
}

hr {
  border: 0;
  border-bottom: 1px solid $colour-border;
  margin: 20px 0;
  
  &.hr-solid {
    border-bottom: 1px solid #000;
  }
  
  &.compact {
    margin: 10px 20px;
  }
}

// Helper classes to set a width on any element.
.zero    { width: 0%;    }

.ten     { width: 10%;   }

.twenty  { width: 20%;   }

.thirty  { width: 30%;   }

.forty   { width: 40%;   }

.fifty   { width: 49%;   }

.sixty   { width: 60%;   }

.seventy { width: 70%;   }

.eighty  { width: 80%;   }

.ninety  { width: 90%;   }

.hundred { width: 100%;  }

.quarter { width: 25%;   }

.third   { width: 33.3%; }

// The 3rd .third element to be a little bigger, for a perfect 100% width.
.third + .third + .third { width: 33.4%; }

.animate-slide {
  @include transition( max-height $transition-speed );
  max-height: 0;

  &.on { max-height: 500px; }
}

.animate-fade {
  @include opacity( 0 );
  @include transition( opacity $transition-speed );

  &.on { @include opacity( 1 ); }
}

// Force an element to be hidden.
.hidden { display: none !important; }

// CSS Island objects
.island { margin-bottom: 25px; }

.islet { margin-bottom: 15px; }

.text-left   { text-align: left !important; }

.text-right  { text-align: right !important; }

.text-center { text-align: center !important; }

.align-top    { vertical-align: top; }

.align-middle { vertical-align: middle; }

.align-bottom { vertical-align: bottom; }

.dollar-sign {
  line-height: 36px;
  margin-right: 5px;
}

.well {
  color: $colour-form-box-text;
  background: $colour-form-box-background;
  border: 0;
  padding: 20px;
}

.accessible-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

#skip-to-content:active,
#skip-to-content:focus,
#skip-to-content:hover {
  display: block;
  text-align: left;
  padding: 25px;
  position: initial;
  left: initial;
  top: initial;
  width: initial;
  height: initial;
  font-weight: bold;
}

.attach-datepicker {
  position: relative;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: $screen-lg-min) {
  .pull-right-lg {
    float: right;
  }
}

@media (min-width: $screen-md-min) {
  .pull-right-md {
    float: right;
  }
}

@media (min-width: $screen-sm-min) {
  .pull-right-sm {
    float: right;
  }
}
