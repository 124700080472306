.feature-intro {
  padding-bottom: 2px;
  padding-left: 0;
  border-bottom: 1px solid #000;
}

.feature-intro .col-show-hide {
  min-width: 50px;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
}

.feature-intro .show-hide {
  display: inline-block;
  margin-top: 13px;
  padding-right: 20px;
}

.feature-intro + .header-controls-row-fix {
  margin-top: -5px;
}

.feature-box-wrapper {
  padding-bottom: 8px;
  background-color: white;
}

.feature-box-wrapper h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.feature-box-video-wrapper {
  overflow: hidden;

  .video-wrapper {
    width: 100%;
    margin-top: 0;
  }

  iframe {
    width: 100%;
    max-width: 100%;
  }
}

.feature-box-image-wrapper {
  img {
    margin-top: 10px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .feature-intro {
    margin-left: -15px;
    margin-right: -15px;
  }

  .feature-intro.collapsed > div:first-child {
    margin-left: 15px;
  }

  .feature-intro.collapsed .col-box-content {
    padding-left: 0;
  }

  .feature-intro .show-hide {
    padding-right: 0;
    text-align: center;
  }

  .feature-intro:not(.collapsed) .show-hide {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .feature-intro {
    margin-top: -10px;
    margin-left: -30px;
    margin-right: -30px;
    padding-top: 5px;
    padding-right: 10px;

    h2 {
      margin-top: 5px;
    }
  }

  .feature-video-or-image-wrapper {
    margin-top: 14px;
    margin-bottom: 5px;
    padding-left: 32px;
    padding-right: 0;
  }

  .feature-intro .col-box-content {
    padding-left: 30px;
  }

  .feature-intro .col-box-content {
    padding-left: 30px;
  }
}

@media(max-width: $screen-sm) {
  .entrant-page,
  .judge-page,
  .vote-page,
  .qualify-page,
  .top-pick-page,
  .error-page {
    .feature-intro {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .entrant-page .feature-intro {
    margin-top: 10px;
  }
}

@media(min-width: $screen-sm) {
  .entrant-page,
  .judge-page,
  .vote-page,
  .qualify-page,
  .top-pick-page,
  .error-page {
    .feature-intro {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .entrant-content, .judge-content, .vote-content, .top-pick-content, .qualify-content, .error-content {
    .feature-intro {
      margin-top: -30px;
    }
  }
}

.feature-intro + .alert-info {
  margin-top: 10px;
}

button.icon.feature-intro-revealer {
  &:hover {
    color: $colour-primary-hover;
  }

  &:focus,
  &:active {
    color: $colour-primary-hover;
    outline: 3px solid $colour-primary-hover;
  }
}
