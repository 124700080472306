.vld-icon {
  text-align:center;

  svg {
    stroke: $colour-info;
    margin: 0 auto;
    width: 400px
  }
}

.after-icon {
  margin: 0 auto;
  width: 400px;
  background-color: #fff;
}
