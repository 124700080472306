.theme-page {
  margin: -10px -15px 50px;
}

@media(min-width: $screen-sm) {
  .theme-page {
    margin: -10px -30px 50px;
  }
}

.theme-content {
  padding: 15px 15px 0;
  position: relative;
}

@media(min-width: $screen-sm) {
  .theme-content {
    padding: 30px 30px 0;
  }
}

.colour {
  background: #fff;
  border: 1px solid $colour-border;
  display: inline-block;
  height: 15px;
  width: 15px;
}

.multiselect-option {
  label.key {
    display: inline-block !important;
    left: 4px;
    position: relative;
    top: 3px;
    padding-left: 0;
  }

  a.reset {
    display: none;
    float: right;
    position: relative;
    top: 2px;
  }

  &:hover {
    a.reset {
      display: block;
    }
  }
}

.reset-all {
  margin: 15px 0 15px;
  text-align: right;
}

.colpick {
  background-color: transparent;
}

.vc-swatches {
  height: auto !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.vc-swatches-box {
  padding: 0 !important;
  margin-right: -10px;
}

.picker {
  width: 100% !important;
  padding: 10px 10px 0 10px;
}
