// Styles related to the Filtertron search component
.filtertron {
  .keyword-search {
    position: relative;

    i.af-icons-search {
      font-size: 20px;
      position: absolute;
      top: 7px;
      left: 9px;
    }

    i.arrow-down {
      cursor: pointer;
      position: absolute;
      right: 0;
      height: 40px;
      width: 40px;
      background: transparent url('../img/arrow-down.png') no-repeat;
      @include opacity(.4);

      &:hover {
        @include opacity(.7);
      }

      &.active {
        @include opacity(1);
      }
    }
  }

  input.keywords {
    padding-left: 35px;
  }

  .extended-container {
    @include transition(max-height $transition-speed);

    color: $colour-form-box-text;
    background: $colour-form-box-background;
    max-height: 0;
    overflow: hidden;

    .fields {
      padding: 25px;
    }

    &.visible {
      max-height: none;
      overflow: visible;
    }

    .buttons {
      margin-top: 20px;
      overflow: hidden;
    }
  }

  .control-label {
    min-height: inherit;
  }
}

@media (min-width: $screen-sm-min) {
  .filtertron {
    padding-left: 10px;
    position: absolute;
    right: 30px;
    top: 20px;
    width: 22%;
    z-index: 20;
  }
}

.filtertron {
  .search-reset {
    display: inline-block;
    left: 10px;
    position: relative;
    top: 6px;
  }

  .search-label {
    padding-left: 4px;
  }
}

#searchResults {
  @include make-xs-column(12);
  @include transition(width $transition-speed ease-in-out);

  // removed padding because #searchResults is not wrapped in a row
  padding: 0;
}

@media (min-width: $screen-sm-min) {
  #searchResults.courteous {
    @include make-xs-column(9);
    padding: 0;
  }
}

.search-info-wrapper {
  min-height: 90px;
}

.search-info {
  text-align: right;
  margin: 10px 0;

  &.clear-buttons {
    margin-top: 65px;
  }

  .pagination-info {
    /*position: absolute;
    top: 40px;*/
    line-height: 2;
  }

  .active-filters {
    margin-right: 10px;
    .button-pill {
      margin: 3px 0;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .search-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .active-filters {
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .search-info {
    margin: 0;
  }
}

.manager-actions {
  float: right;
  margin-top: 8px;

  .btn-sm {
    margin: 0 0 0 8px;
  }
}
