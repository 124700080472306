$colour-fail: $brand-danger;
$colour-pass: $brand-success;
$colour-unsure: $brand-warning;

.qualifying-controls {
  max-width: 560px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  flex: 1;

  .control {
    height: 42px;
    padding: 10px 15px;
    font-size: 1.2em;
    color: white;
    margin: 0;
    white-space: nowrap;
    border: 0;
    display: flex;
    gap: 5px;
    justify-content: center;

    @include transition(background $transition-speed);
  }

  .control-one-half {
    width: 50%;
  }

  .control-one-third {
    width: 33%;
  }

  .decide-button {
    background-color: $colour-tertiary;
    width: 100%;
    min-width: 100px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: darken($colour-tertiary, 10%);
    }

    .af-icons {
      float: right;
    }
  }

  .fail-button {
    border-top-left-radius: $default-radius;
    border-bottom-left-radius: $default-radius;
  }

  .fail-button.fail {
    background-color: $colour-fail;
  }

  .unsure-button {
    border-left: 1px solid white;
  }

  .unsure-button.unsure {
    background-color: $colour-unsure;
  }

  .pass-button {
    border-top-right-radius: $default-radius;
    border-bottom-right-radius: $default-radius;
    border-left: 1px solid white;
  }

  .pass-button.pass {
    background-color: $colour-pass;
  }
}

.decision-fail {
  color: $colour-fail;
}

.decision-pass {
  color: $colour-pass;
}

.labels-qualifying {
  margin-top: 0;
}
