@font-face {
  font-family: 'af-icons-md';
  src: url('../fonts/af-icons-md.eot');
  src: url('../fonts/af-icons-md.eot?#iefix') format('eot'),
    url('../fonts/af-icons-md.woff') format('woff'),
    url('../fonts/af-icons-md.ttf') format('truetype'),
    url('../fonts/af-icons-md.svg#af-icons-md') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin af-icons-md() {
  display: inline-block;
  font-smooth: never;
  font-family: 'af-icons-md';
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.af-icons-md {
  @include af-icons-md();
}

.af-icons-md-animate-rotate {
  font-size: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  animation-name: rotateAFIcon;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateAFIcon {
  from { transform: scale( 1 ) rotate( 0deg );   }
  to   { transform: scale( 1 ) rotate( 360deg ); }
}




.af-icons-md-alert-error:before {
  content: "\E002"
}


.af-icons-md-alert-info:before {
  content: "\E003"
}


.af-icons-md-alert-tick:before {
  content: "\E004"
}


.af-icons-md-alert-warning:before {
  content: "\E005"
}


.af-icons-md-arrow-left:before {
  content: "\E007"
}


.af-icons-md-arrow-right:before {
  content: "\E008"
}


.af-icons-md-circle-video:before {
  content: "\E040"
}


.af-icons-md-circle-link:before {
  content: "\E041"
}


.af-icons-md-circle-document:before {
  content: "\E042"
}


.af-icons-md-circle-audio:before {
  content: "\E043"
}


.af-icons-md-circle-processing:before {
  content: "\E053"
}


.af-icons-md-circle-fail:before {
  content: "\E054"
}


.af-icons-md-falcon:before {
  content: "\E064"
}

