@import '~intl-tel-input/build/css/intlTelInput.min.css';

.iti {
  width: 100%;
}

.iti__country, .iti__flag-container {
  color : $dropdown-link-color;
}
.iti__country.iti__highlight:focus-visible {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: -2px;
  outline-color: transparent;
}

.iti input[type='text']::-webkit-input-placeholder { color: $colour-placeholder !important; }

.iti input[type='text']:-moz-placeholder { color: $colour-placeholder !important; }

.iti input[type='text']::-moz-placeholder { color: $colour-placeholder !important; }

.iti input[type='text']:-ms-input-placeholder { color: $colour-placeholder !important; }

.iti__country.iti__highlight {
  color: #FFFFFF;
  background-color: $dropdown-link-hover-bg;
}

.darkmode {
  .iti__country.iti__highlight {
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
  }
}
