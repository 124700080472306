// ###############################################################
// Layout

// ###############################################################
// Footers

// Themed Footer

footer {
  display: block;
  background: $brand-footer-background;

  .logo {
    height: 220px;
    display: block;

    a {
      line-height: 0; /* Used to hide logo anchor text */
      font-size: 0; /* Used to hide logo anchor text */
      color: transparent; /* Used to hide logo anchor text */
    }
  }
}

// Award Force Footer

.footer-bar {
  width: 100%;
  background: #000000;
  color: $font-secondary-colour;
  font-size: 80%;

  .footer-border {
    padding: 20px 30px;
  }

  a, button.show-cookies-button {
    color: $font-secondary-colour;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;

    &:hover, &:focus {
      text-decoration: solid underline #989898 2px;
    }

    &:focus, &:focus-visible {
      text-decoration: none;
      outline-offset: 4px !important;
      outline: #989898 solid 2px !important;
    }
  }

  .terms {
    padding-bottom: 15px;
    text-align: center;
    span, a {
      display: inline-block
    }
    span {
      &:last-child {
        margin-left: 2px;
      }
    }
    @media screen and (min-width: $screen-md) {
      padding-bottom: 0;
      padding-top: 15px;
      text-align: left;
    }
  }

  .logo-brand {
    margin: auto;

    a, div {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    span {
      display: block;
      text-align: center;
      padding-bottom: 15px;
    }

    img {
      display: block;
      width: 50px;
      height: 50px;
    }
  }
}

@media(min-width: $screen-md) {
  .footer-bar {

    .logo-brand {
      float: right;

      a, div {
        float: right;
        display: inline-flex;
        justify-content: flex-end;
        flex-direction: row;
      }

      span {
        text-align: right;
        padding-bottom: 0;
        white-space: nowrap;
      }

      img {
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
}

// ###############################################################
// Splash Page

body.splash {
  background: url('/img/af-archer-hero-bg.jpg') no-repeat center center fixed;
  background-size: cover;

  #main {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  #content {
    flex: 1 1 auto;

    .content {
      padding: 0 0 50px;
    }
  }
}

body.splash-no-bg {
  background: $colour-home-background no-repeat center center fixed;
  background-size: cover;
}
