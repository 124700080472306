//
// Modified from http://flatlogic.github.io/awesome-bootstrap-checkbox/
// --------------------------------------------------

$font-family-icon: 'af-icons';
$check-icon: '\e038';

//
// Checkboxes
// --------------------------------------------------

.checkbox.styled, .radio.styled {
  label{
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-base;

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      margin-top: 1px;
      border: 1px solid $input-border;
      border-radius: 2px;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 1px;
      top: 0;
      margin-top: -1px;
      padding-left: 1px;
      padding-top: 0;
      font-size: 14px;
      color: $input-color;
      line-height: 1.4;
    }
  }

  input[type='checkbox'],
  input[type=checkbox]:focus,
  input[type='radio'],
  input[type=radio]:focus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus + label::before{
      border-color: #000;
      outline: 2px solid;
    }

    &:checked + label::after {
      font-family: $font-family-icon;
      content: $check-icon;
    }
  }
}

td > .checkbox {
  text-align: center;
  margin-top: 0;
}

//
// Radios
// --------------------------------------------------

.radio.styled{
  padding-left: 20px;

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0px;
      top: 2px;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 50%;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      content: ' ';
      width: 10px;
      height: 10px;
      left: 4px;
      top: 8px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $colour-secondary;
      @include scale(0, 0);

      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type='radio']{
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:checked + label::after{
      @include scale(1, 1);
    }
  }
}

input[type='radio']{
  &:checked + label::after{
    content: '' !important;
  }
}
