.ckeditor-wrapper {
  .ck.ck-editor {
    max-width: 99%;
  }

  a {
    cursor: text;
  }

  .media {
    overflow: visible;
  }

  table td {
    vertical-align: inherit;
  }
}
