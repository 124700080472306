.menu-widget {
  background-color: $navigation-background;
  color: white;
  width: 100%;
  padding: 15px 0 15px 0;

  @media (max-width: $grid-float-breakpoint) {
    display: none;
    padding: 0;
  }
}

#nav-simple-menu {
  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

.bg-transparent-md {
  @media (min-width: $grid-float-breakpoint) {
    background-color: transparent;
  }
}

.simple-menu {
    > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;

      > li > a,
      > li > button {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        text-decoration: none;
        background-color: $navigation-background;
        color: $navigation-text;
        width: 100%;
        border: none;
        padding: 4px 0;
        text-align: left;
        line-height: 26px;

        &:hover,
        &:focus {
          background-color: $navigation-hover;
          color: $navigation-text-hover;
          outline: none;
        }

        &.active {
          background-color: $navigation-active;
          color: $navigation-text-active;

          &:focus-visible {
            outline: 2px solid white;
            outline-offset: -4px;
          }
        }

        .primary-menu-button {
          &:focus,
          &.focus {
            border: none;
          }
        }

        .icon {
          display: inline-block;
          width: $main-menu-collapsed-width + 1;
          text-align: center;
          font-size: 24px;
          flex-shrink: 0;
        }

        .text {
          display: inline-block;
          padding-right: 15px;
          text-align: left;
        }
      }
    }

  .secondary-menu {
    position: relative;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    overflow-x: hidden;
    background-color: #fff;

    /* Closed initially */
    width: 0;
    transition: width 0.2s ease-in;
  }
}

.is-drop-nav-menu .simple-menu {
  > ul {
    > li {
      > a,
      > button {
        padding: 20px 0;
      }
    }
  }
}

.islet {
  margin-bottom: 0;
}

.simple-widget {
  .content-block-container.edit a.af-icons-edit,
  .content-block-container.new a.af-icons-edit {
    left: -15px;
  }
}

.form-card.widget {
  position: relative;
  min-height: 135px;
  padding: 0;
  margin-bottom: 30px;
  z-index: 2;
  overflow: hidden;
  box-shadow: none;
  border: none;
  border-top: 5px solid $colour-header-background;

  .widget-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .widget-image {
    flex: 1;
    text-align: center;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .widget-info {
    flex: 2;
    padding: 20px;
  }
}

.content-block-container.new {
    height: 50px;
}
