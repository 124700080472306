.jp-gui {
  border: 1px solid white;
  position: relative;

  .jp-controls,
  .jp-progress {
    float: left;
  }

  .jp-play {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: $colour-tertiary;
    color: white;

    @include transition( background $transition-speed );

    &:hover {
      background-color: darken($colour-tertiary, 10%);
    }

    .af-icons:before {
      font-size: 120%;
      line-height: 1.4;
    }
  }

  .jp-state-playing .jp-play {
    background-color: red;
  }

  .jp-state-playing .jp-play:focus {
    background-color: red;
  }

  .jp-progress {
    width: 196px;
    height: 40px;
    overflow: hidden;
    background-color: #ddd;
  }

  .jp-audio .jp-progress {
    position: absolute;
    left: 40px;
    top: 40px;
    height: 40px;
  }

  .jp-seek-bar {
    background: #ddd;
    width: 0px;
    height: 100%;
    cursor: pointer;
  }

  .jp-play-bar {
    background: $colour-secondary;
    width: 0px;
    height: 100%;
  }

  .jp-timer {
    font-size: 80%;
    position: absolute;
    right: 5px;
    bottom: 0;
    cursor: pointer;
  }
}

// Pause button state

.jp-state-playing {
  .jp-play {
    .af-icons-audio-play:before {
      content: '\E00B';
    }
  }
}

// Clickable audio previews

.preview-container.audio {
  cursor: pointer;
}
