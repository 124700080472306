.scoring-criterion {
  .scoring-control {
    margin-bottom: 10px;

    .btn-scoring-select {
      cursor: default;
      padding: 7px 10px;
      white-space: nowrap;
      gap: 5px;

      .maximum {
        position: relative;
        top: 2px;
      }
    }

    .score-select {
      display: inline;
      width: auto;
    }

    input.score-select {
      max-width: 55px;
    }
  }

  .scoring-hint-text {
    @include text-content;
  }
}

@media (min-width: $screen-sm-min) {
  .scoring-criterion {
    display: table;

    .scoring-control {
      display: table-cell;
      vertical-align: top;
    }

    .scoring-hint-text {
      display: table-cell;
      vertical-align: top;
      padding-left: 20px;
    }
  }
}
