label {
  p {
    display: inline-block;
    margin: 0;
  }
}

.form-group {
  label {
    display: inline-block;
    vertical-align: middle;
  }

  .af-icons {
    cursor: pointer;
  }
}

// Help text that appears under a field.
.help-text {
  color: #666;
  display: inline-block;
  font-size: 0.923em;
  margin-top: 5px;
}


/* Actions styles */
.form-actions {
  margin-top: 50px;

  button, input, a, span.btn {
    margin-bottom: 10px;
  }
}

.form-actions-sm {
  margin-top: 30px;
}


// For client-side validation errors.
div.error{
  color: $colour-error;

  a {
    color: $colour-error;

    &:focus, &:hover, &:active {
      color: $colour-error;
    }
  }

  input{
    border-color: $colour-input-border-error;

    &:focus {
      border-color: $colour-input-border-error-focus;
    }
  }
}