.loader{
  .spinner{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 550;
  }
}

.basic{
  &.loader {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1001;

    .spinner {
      left: 45%;
    }
  }
}

.fullpage{
  &.loader {
    background: #fff;
    bottom: 0;
    display: none;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1001;

    .spinner {
      margin: 100px 50%;
      left: -25px;
    }
  }
}

@media (min-width: $screen-sm) {
  .loader .spinner {
    position: fixed;
    left: 110px;
  }
}

.cube1 {
  background: $colour-primary;
}

.cube2 {
  background: $colour-secondary;
}

.cube1,
.cube2 {
  border-radius: $default-radius;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: cubemove 1.5s infinite ease-in-out;
  animation: cubemove 1.5s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

@-webkit-keyframes cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }

  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }

  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }

  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }

  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }

  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
