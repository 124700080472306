#uploader {
  .upload-file-limit {
    color: #ccc;
  }
}

.upload-multi {
  hr {
    border: 0;
    border-bottom: 1px solid $colour-border;
    margin-top: 20px;
  }
}

.upload-single {
  hr {
    display: none;
  }
}

.file-upload-container {
  position: relative;

  .file-upload-visual {
    @include position( absolute , 2px 0 0 0px );
    @include size( 100% 34 );
    z-index: 1;

    // Hide this for IE.
    @include ie {
      display: none;
    }

    .visual-field {
      @include position( relative , -3px 0 0 0 );
      background: white;
      border-radius: $default-radius;
      border: 1px solid #d5d5d5;
      color: #aaa;
      display: inline-block;
      margin-right: 10px;
      padding: 8px;
      width: 65%;
    }

    .button {
      padding-top: 7px;
    }

    @include lt-ie9 {
      .visual-field {
        top: -2px;
        padding: 6px 8px;
      }
    }
  }

  .upload-field {
    @include opacity(0);
    @include position( absolute , 0px 0px 0 0 );
    @include transform( translate(225px, -25px) scale(6) );
    height: 36px;
    position: relative;
    z-index: 3;

    @include ie {
      @include opacity(1);
      @include transform( translate(0, 0) scale(1) );
      position: static;
      width: 300px;
    }
  }

  .upload-details-container {
    overflow: hidden;
    //margin: 5px 0;
  }

  .upload-details {
    float: right;

    .sort-order {
      vertical-align: middle;
    }

    .move {
      font-size: 1.2em;
    }
  }

  .upload-abort-button {
    float: left;
  }
}

.upload-progress-container {
  @include size( 100% 10 );
  margin-bottom: 30px;

  .upload-progress {
    background: $colour-secondary;
    height: 10px;
    width: 0;
  }

  .upload-cancel {
    text-align: right;
    padding: 5px 10px;
  }
}

.document-container,
.upload-complete {
  margin-top: 5px;

  .filename,
  .filesize,
  .file-edit {
    margin-right: 20px;
  }

  .filename {
    display: inline-block;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#ie-uploader {
  @include size( 100% 350 );
  margin-bottom: 15px;
}

.upload-label {
  padding: 10px 0;

  h3 {
    display: inline;
    vertical-align: middle;
  }

  .af-icons {
    top: 5px;
  }
}

.uploader-addon {
  width:100%;
  border-bottom: 1px solid #ddd !important;

  .card-header {
    border: none;
  }
}

.dnd {
  text-align: center;
  font-size: 20px;
  line-height: 140%;
  padding: 10px;

  .dnd-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

.dnd-container {
  position: relative;
}

.dnd-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
}

.upload-button {
  z-index: 9;
}

.caption-uploader {
  padding: 10px 10px 15px 10px;
  border: none;
}

.drag-enter {
  background-color: transparentize($colour-secondary, 0.9);
}
