.onoffswitch {
  position: relative;
  width: 87px;
  @include user-select(none);
}

.onoffswitch-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 18px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;

  &:before, &:after {
    display: block;
    float: left;
    width: 50%;
    height: 18px;
    padding: 0;
    line-height: 18px;
    font-size: 10px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    text-transform: uppercase;
  }

  &:before {
    content: attr(data-on-label);
    padding-left: 10px;
    background-color: $colour-secondary;
  }

  &:after {
    content: attr(data-off-label);
    padding-right: 10px;
    background-color: $colour-tertiary;
    text-align: right;
  }

  &.disabled {
    opacity: 1;

    &:before {
      background-color: $colour-disabled !important;
    }

    &:after {
      background-color: $colour-disabled !important;
    }
  }
}

.onoffswitch-switch {
  display: block;
  width: 14px;
  height: 14px;
  margin: 0;
  background: #ffffff;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 71px;
  border-radius: 18px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner,
.onoffswitch-checkbox.checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch,
.onoffswitch-checkbox.checked + .onoffswitch-label .onoffswitch-switch {
  right: 2px;
}
