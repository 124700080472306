$theme-var-app-header-background:	#253342;
$theme-var-app-header-background-text:	#ffffff;
$theme-var-text:	#000000;
$theme-var-link:	#3b4be3;
$theme-var-link-hover:	#253342;
$theme-var-link-active:	#253342;
$theme-var-info-box-background:	#ffffff;
$theme-var-info-box-heading:	#3b4be3;
$theme-var-info-box-icon:	#3b4be3;
$theme-var-info-box-text:	#253342;
$theme-var-alert-box-success:	#06d6a0;
$theme-var-alert-box-warning:	#ffbe0b;
$theme-var-alert-box-info:	#5a6fcd;
$theme-var-alert-box-error:	#e11b46;
$theme-var-alert-box-text:	#ffffff;
$theme-var-form-box-background:	#ffffff;
$theme-var-form-box-text:	#333333;
$theme-var-focus-box-background:	#9ee493;
$theme-var-focus-box-text:	#000000;
$theme-var-brand-header-background:	#ffffff;
$theme-var-brand-footer-background:	#ffffff;
$theme-var-brand-header-background-mobile:	#ffffff;
$theme-var-brand-footer-background-mobile:	#ffffff;
$theme-var-home-background:	#fafafa;
$theme-var-primary-button:	#5f29c7;
$theme-var-primary-button-text:	#ffffff;
$theme-var-primary-button-hover:	#4b209d;
$theme-var-primary-button-hover-text:	#ffffff;
$theme-var-secondary-button:	#008945;
$theme-var-secondary-button-text:	#ffffff;
$theme-var-secondary-button-hover:	#00562b;
$theme-var-secondary-button-hover-text:	#ffffff;
$theme-var-tertiary-button:	#253342;
$theme-var-tertiary-button-text:	#ffffff;
$theme-var-tertiary-button-hover:	#131a21;
$theme-var-tertiary-button-hover-text:	#ffffff;
$theme-var-tab:	#66788f;
$theme-var-tab-text:	#ffffff;
$theme-var-tab-active:	#253342;
$theme-var-tab-active-text:	#ffffff;
$theme-var-tab-hover:	#5f29c7;
$theme-var-tab-hover-text:	#ffffff;
$theme-var-menu-background:	#e6ebef;
$theme-var-menu-text:	#000000;
$theme-var-menu-hover-background:	#253342;
$theme-var-menu-hover-text:	#ffffff;
$theme-var-menu-active-background:	#adbbc9;
$theme-var-menu-active-text:	#000000;
$theme-var-tray-background:	#ffffff;
$theme-var-tray-text:	#000000;
$theme-var-tray-link:	#3b4be3;
$theme-var-tray-link-hover:	#253342;
$theme-var-tray-link-active:	#253342;
