table.field-mapping {
  thead > tr > th {
    padding: 8px;
  }

  tbody > tr > td {
    padding: 8px;

    .form-control {
      height: 27px;
      padding: 0 8px;
    }

    .form-group {
      margin: 0;
    }
  }
}
