#modal-container {
  @include position( fixed , 0px 0px 0px 0px );
  background: rgba(white , 0.85);
  z-index: 500;
}

#modal-content {
  background: white;
  height: 200px;
  margin: 100px auto;
  max-height: 500px;
  min-height: 200px;
  min-width: 300px;
  padding: 20px;
  position: relative;
  width: $modal-width;
}

.modal-content {
  border: none;
}

#modal-scrollable-content {
  @include position( absolute , 40px 0px 0px 20px );
  overflow-y: auto;
  padding: 0 20px 20px 0;
}

.modal-dialog {
  margin-top: 60px;

  button#close,
  button#simpleModal-close,
  button#maintenance-modal-close,
  button#page-expired-modal-close {
    float: right;
    position: absolute;
    top: -32px;
    right: -10px;
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 24px;

    &:focus {
      outline: none;
    }
  }

  .modal-content {
    @include box-shadow(none);
    border-radius: 0;
    background: #fafafa;
  }

  .modal-footer {
    border-top: none;

    button {
      float: none;
    }
  }

  .comment-container {
    h4 {
      margin-top: 20px;
      margin-bottom: -5px;
    }

    .comment {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
