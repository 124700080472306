$reveal-button-width: 60px;
.password-field {
  position: relative;

  .form-control {
    padding-right: $reveal-button-width;
  }

  &__reveal-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: 0;
    background-color: transparent;
    color: $text-color;
    border-color: inherit;
    outline-color: inherit;
    font-size: 14px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
    width: $reveal-button-width;
    margin-right: 0;

    &:focus-visible {
      outline-offset: -4.5px;
    }

    .af-icons-preview {
      top: -1px;
    }
  }

  // hiding edge/ie password reveal button
  ::-ms-reveal {
    display: none;
  }
}
