.users-name-column {
  width: 200px;
}

// We want the width, but we also want it centered!
.column-third.account-details {
  float: none;
  margin: 0 auto;

  // This is to overload the default styles, as the account details page
  // is rather unique in its layout and presentation.
  .column-half {
    width: 100%;
  }
}
