.markdown-render{
  table {
    clear: both;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    tbody > tr > td,
    tfoot > tr > th,
    tfoot > tr > td {
      padding: 15px;
      line-height: 1.42857143;
      vertical-align: top;
      border-top: 1px solid #ddd;
    }

    thead > tr > th {
      padding: 15px;
      line-height: 1.42857143;
      vertical-align: bottom;
      border-bottom: 2px solid #ddd;
    }
  }
}
