@font-face {
  font-family: 'af-icons-lg';
  src: url('../fonts/af-icons-lg.eot');
  src: url('../fonts/af-icons-lg.eot?#iefix') format('eot'),
    url('../fonts/af-icons-lg.woff') format('woff'),
    url('../fonts/af-icons-lg.ttf') format('truetype'),
    url('../fonts/af-icons-lg.svg#af-icons-lg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin af-icons-lg() {
  display: inline-block;
  font-smooth: never;
  font-family: 'af-icons-lg';
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.af-icons-lg {
  @include af-icons-lg();
}

.af-icons-lg-cookie:before {
  content: "\E0A0"
}

