.receipt-address p {
  margin: 0;
}

#order-notes {
  #markdown {
    margin: 10px 0;
  }

  .buttons {
    margin-top: 10px;
  }
}

#order-list {
  .order-list-title {
    flex: 1;
    flex-shrink: 0;
  }
}
